import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { restrictedResourcesForUser } from './data';
import { useNavigate } from 'react-router-dom';
import useApp from '../../hooks/useApp';

const Protected = ({ children }) => {
  const location = useLocation();
  const { isPaymentActive, handleNavigateToHome, handleRestrictAccessModuleDuePayment } = useApp();
  const isAdmin = useSelector((state) => state.user?.value?.isAdmin);
  const token = useSelector((state) => state.user.value.AccessToken);
  const redirectToAskNamePage = useSelector((state) => state.user.redirectToAskNamePage);
  const user = useSelector((state) => state.user);
  const isOnboardingComplete = user.isOnboardingComplete;

  const navigate = useNavigate();

  useEffect(() => {
    if(!isPaymentActive && ['product/home/setting/subscription', 'product/home/subscription/pay'].includes(location.pathname)){
      handleRestrictAccessModuleDuePayment();
      handleNavigateToHome();
    }
    if(redirectToAskNamePage){
      navigate('/product/new/username');
    }else if(isOnboardingComplete==false && user.value.isAdmin==false && user.value.id){
      navigate('/product');
    }
    if (token && token?.length) {
      if (!isAdmin) {
        if(restrictedResourcesForUser?.includes(location.pathname)){
          navigate('/product/home');
        }
      }
    } else {
      navigate('/product');
    }
  }, [token, isAdmin, location.pathname, navigate]);

  return children;
};

export default Protected;
