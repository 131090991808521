export const generateUrlEndPoint = (config, root, endpoint, incomingType) => { //apiType - request | response
    try {
        let apiType;
        if(incomingType === undefined) apiType="request";
        const Root = root.trim();
        const Endpoint = endpoint.trim();
        if (Root.length == 0 || Endpoint.length == 0) {
            return null;
        }
        let data = { url: config[Root].path, method: '' };
        const endPointFormation = (subConfig, data) => {
            if (subConfig.method !== undefined) {
                data = { ...data, method: subConfig.method };
            }
            data = { ...data, url: data.url + subConfig.path };
            if (subConfig.subpaths === undefined) {
                return apiType === "request" ? {...data, url: data.url.split('//').join('/')} : { subConfig }
            } else {
                return endPointFormation(subConfig.subpaths, data);
            }
        }
        return endPointFormation(config[Root].subpaths[Endpoint], data);
    } catch (err) {
        return null;
    }
};