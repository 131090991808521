import React, { useState, useEffect } from 'react'
import { Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton';
import SecondaryButton from '../../DesignSystem/Buttons/SecondaryButton';
import HeadingFour from '../../DesignSystem/TypographyHeadings/HeadingFour';
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge';
import appLocals from "../../localization/localization_en_website.json"
import ClientInformationForm from '../../components/reuseablecomponent/Form/ClientInformationForm';
import { D_ToggleWarningPopup, D_Toggle_Website_form, API_Post_WebsiteForm, D_Reset_WebsiteForm_Status } from '../../features/appSlice';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ResponsiveHeadingFourInter from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingFourInter';
import PGButtonComponent from '../../DesignSystem/Buttons/PGButtonComponent';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { validator } from '../../utils/validator';

const FORM_LOCALS = {
    "contact_us": appLocals.website.collect_user_details.contact_us,
    "contact_sales": appLocals.website.collect_user_details.contact_sales,
    "request_demo": appLocals.website.collect_user_details.request_demo,
    "join_community": appLocals.website.collect_user_details.join_community,
    "get_early_access": appLocals.website.collect_user_details.get_early_access,
    "upgrade_plan": appLocals.website.collect_user_details.upgrade_plan
}

const INITIAL_STATE = {
    "contact_us": { name: null, company_name: null, email_address: null, phone_number: null, designation: null, country: null, message: null },
    "contact_sales": { name: null, company_name: null, email_address: null, phone_number: null, designation: null, country: null, message: null },
    "request_demo": { name: null, company_name: null, email_address: null, phone_number: null, message: null },
    "join_community": { name: null, founder_or_co_founder: null, startup_name: null, whatsapp_number: null, linkedin_id: null },
    "get_early_access": { name: null, company_name: null, email_address: null, phone_number: null, message: null },
    "upgrade_plan": { name: null, company_name: null, email_address: null, phone_number: null, message: null }
}

const UserInformationForm = ({ children }) => {

    const dispatch = useDispatch();
    const [isShowWarning, setIsShowWarning] = useState(false);
    const api_status = useSelector((state) => state.app.website_form.api_status)
    const [Form, setForm] = useState(INITIAL_STATE);
    const isPopupOpen = useSelector((state) => state.app.website_form.is_open)
    const successApiPopup = useSelector((state) => state.app.website_form.api_success_popup_open)
    const targetForm = useSelector((state) => state.app.website_form.form_name)
    const LOCALS = FORM_LOCALS[targetForm]

    useEffect(() => {
        if (api_status?.status === "failed") {
            message.open({ type: "error", "content": api_status?.message });
        }
    }, [api_status?.status]);

    const handleTogglePopup = ({ form_name }) => dispatch(D_Toggle_Website_form({ "is_open": !isPopupOpen, form_name }))
    const storeFormValue = ({ name, value }) => setForm((prev) => ({ ...prev, [targetForm]: { ...prev[targetForm], [name]: value } }));

    const handleSubmitForm = () => {
        let isDataFilled = true;
        const data = Form[targetForm];
        LOCALS.form.map((f) => {
            if (f.is_required && validator({ validator: f.validator, value: data[f.name] }) === false) isDataFilled = false;
        })
        if (!isDataFilled) {
            setIsShowWarning(true);
        } else {
            window.dataLayer.push({
                event: `form_submit_${targetForm}`,
            });
            dispatch(API_Post_WebsiteForm({ "formType": targetForm, "data": Form[targetForm] }));
        }
    }

    const handleResetForm = () => {
        setForm(INITIAL_STATE);
        setIsShowWarning(false);
    }

    return (
        <>
            <Modal
                open={isPopupOpen}
                centered
                zIndex={1000000}
                className='remove_padding_modal user_information_form_responsive sm:min-w-[645px] w-[95%] my-[1rem]'
            >
                <div className='bg-p9 sm:p-[24px] p-[12px] rounded-lg'>
                    <ResponsiveHeadingFourInter text={LOCALS.title} color={"black"} />
                    <span
                        className={`sm:text-[16px] text-[14px] tracking-wide font-InterRegular text-[#757575]`}
                    >{LOCALS.description}</span>
                </div>
                <div className='sm:p-[24px] p-[12px] flex flex-col gap-y-[24px]'>
                    <ClientInformationForm
                        isShowWarning={isShowWarning}
                        FormValues={Form[targetForm]}
                        storeFormValue={storeFormValue}
                        form={LOCALS.form}
                        warningFields={LOCALS.incomplete_form_warning_msg}
                    />
                    <div className='flex justify-between'>
                        <SecondaryButton onClick={() => {
                            dispatch(D_Toggle_Website_form({ "is_open": false }))
                            handleResetForm();
                            dispatch(D_Reset_WebsiteForm_Status());
                        }} text={LOCALS.cancel} />
                        <PrimaryButton isLoading={api_status?.status === "loading"} isDisabled={api_status?.status === "loading"} onClick={handleSubmitForm} text={LOCALS.submit} />
                    </div>
                </div>
                <button onClick={() => {
                    dispatch(D_Toggle_Website_form({ "is_open": false, "api_success_popup_open": false }))
                    handleResetForm();
                    dispatch(D_Reset_WebsiteForm_Status());
                }} className='absolute top-[1rem] right-[1rem]'>
                    <CloseOutlinedIcon />
                </button>
            </Modal>
            <Modal
                open={successApiPopup}
                centered
                zIndex={1000000}
                className='remove_padding_modal'
            >
                <div className='flex flex-col gap-y-[12px] justify-center items-center p-[24px]'>
                    <CheckCircleOutlineOutlinedIcon fontSize='large' className='text-[#4ADE80]' />
                    <HeadingFour headingCSSStyles={{ marginBottom: 0 }} text={LOCALS.completed_modal_form.title} />
                    <TextLarge fontWeight={400} className={"text-center text-[#757575]"} text={LOCALS.completed_modal_form.description} />
                    <div className='w-full rounded-lg overflow-hidden'>
                        <PGButtonComponent
                            className={"w-full"}
                            onClick={() => dispatch(D_Toggle_Website_form({ "is_open": false, "api_success_popup_open": false }))}
                        >
                            {LOCALS.completed_modal_form.done}
                        </PGButtonComponent>
                    </div>
                </div>
                <button onClick={() => {
                    dispatch(D_Toggle_Website_form({ "is_open": false, "api_success_popup_open": false }))
                    dispatch(D_Reset_WebsiteForm_Status());
                }} className='absolute top-[1rem] right-[1rem]'>
                    <CloseOutlinedIcon />
                </button>
            </Modal>
            {typeof children === "function" ? children({ handleTogglePopup }) : null}
        </>
    )
}

export default UserInformationForm