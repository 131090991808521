import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import ListOfCompetitor from './ListOfCompetitor';
import SecondaryButton from '../../DesignSystem/Buttons/SecondaryButton';
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton';
import ListOfTagWithRadioBtn from './components/ListOfTagWithRadioBtn';
import { extractKeywords } from '../../utils/ExtractKeyWords';
import { handleExplorePSMTags, handleSelectTag } from "./promptSlice";
import { fetchPromptAnswer } from "./promptSlice"
import aiLocals from "../../localization/localization_en_ai.json"
import { updateLoading } from '../UserActivity/userActivitySlice';
import FloatingScrollIcon from './components/FloatingScrollIcon';
import Download from "./assets/Download.svg"
import { Button } from "antd";

const text = aiLocals.ai.captions.psm_text;

const ProblemSolutionMatrixPromptOutput = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [listOfTags, setlistOfTags] = useState(null);
    const psmPromptData = useSelector((state) => state.prompt.problemSolutionMatrix);
    const ProblemSelected = useSelector((state) => state.prompt.promptInputs.ProblemSelected);
    const prompt = useSelector((state) => state.prompt);
    const loadingStates = useSelector((state) => state.prompt.problemSolutionMatrix);

    useEffect(() => {
        dispatch(updateLoading());
        if (psmPromptData.problem !== null) {
            setlistOfTags(extractKeywords(psmPromptData.problem, "fetchProblems"));
        }
    }, [])

    const handleExtractKeywords = () => {
        setlistOfTags(extractKeywords(psmPromptData.problem, "fetchProblems"));
        dispatch(handleExplorePSMTags({ key: "selectproblemCTA", value: true }));
        dispatch(handleExplorePSMTags({ key: "problemLoading", value: true }));
    }

    const handleSolution = () => {
        dispatch(handleExplorePSMTags({ key: "solutionLoading", value: true }));
        dispatch(fetchPromptAnswer({ type: "solution", promptIds: [44] }));
    }

    const handleEvaluateFeasibility = () =>{
        const Solutions = extractKeywords(psmPromptData.solution,"fetchSolutions");
        dispatch(handleSelectTag({key:"Solutions",value:Solutions}));
        dispatch(handleExplorePSMTags({ key: "fesabilityLoading", value: true }));
        dispatch(fetchPromptAnswer({ type: "evaluateFeasability", promptIds: [45] }))
    }


    return (
        <div className={`${psmPromptData.problem === null ? 'hidden' : 'block'}`}>
            {/* <Button style={{ border: "none" }} className='h-auto'><img src={Download} alt="" /></Button> */}
            <ListOfCompetitor title={text.list_text} data={psmPromptData.problem} />
            <div className={`flex justify-between mb-[32px]`}>
                {<SecondaryButton isDisabled={psmPromptData.selectproblemCTA} text={aiLocals.ai.button_status[2]} onClick={() => navigate(-1)} />}
                <PrimaryButton isDisabled={psmPromptData.selectproblemCTA} text={text.select_problem_text} onClick={handleExtractKeywords} />
            </div>
            {psmPromptData.selectproblemCTA && <ListOfTagWithRadioBtn isDisabled={loadingStates.solutionLoading || psmPromptData.solution!==null} psmPromptData={psmPromptData} listOfTags={listOfTags} />}
            {psmPromptData.selectproblemCTA && <div className={`flex my-[3rem] justify-between`}>
                {<SecondaryButton isDisabled={psmPromptData.solution!==null} text={aiLocals.ai.button_status[2]} onClick={() => navigate(-1)} />}
                <PrimaryButton onClick={handleSolution} isLoading={loadingStates.solutionLoading} isDisabled={loadingStates.solutionLoading || psmPromptData.solution!==null} text={text.view_solution} />
            </div>}
            {psmPromptData.solution!==null && <ListOfCompetitor title={text.feasible_solution_text_1} data={psmPromptData.solution} />}
            {psmPromptData.evaluateFesability!==null && <ListOfCompetitor title={text.feasible_solution_text_2} data={psmPromptData.evaluateFesability} /> }
            {psmPromptData.solution!==null && <div className={`flex mt-[1rem] ${psmPromptData.solution!==null ? 'justify-between' : 'justify-end'}`}>
                {<SecondaryButton text={aiLocals.ai.button_status[2]} onClick={() => navigate(-1)} />}
                <PrimaryButton onClick={handleEvaluateFeasibility} isLoading={loadingStates.fesabilityLoading} isDisabled={loadingStates.fesabilityLoading || psmPromptData.evaluateFesability!==null} text={text.feasibility_btn_text} />
            </div>}
        </div>
    )
}

export default ProblemSolutionMatrixPromptOutput