import React, { useEffect, useState } from 'react'
import { Button, Form, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import locals from "../../localization/localization_en_business_planning.json"
import editIconWhite from '../../assets/common/editIconWhite.svg'
import editIconPrimary from '../../assets/common/editIconPrimary.svg'
import downloadPrimary from '../../assets/common/downloadPrimary.svg'
import HeadingFour from '../../DesignSystem/TypographyHeadings/HeadingFour'
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge'
import TextMedium from '../../DesignSystem/TypographyDescriptions/TextMedium'
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton'
import AutoSizeTextArea from '../../DesignSystem/Forms/InputVarients/AutoSizeTextArea'
import DonutChartAntD from '../../DesignSystem/GraphAndCharts/Piechart/DonutChartAntD'
import TextSmall from '../../DesignSystem/TypographyDescriptions/TextSmall'
import CALL from '../../assets/common/call.svg'
import WWW from '../../assets/common/www.svg'
import MAIL from '../../assets/common/mailPrimary.svg'
import TextInput from '../../DesignSystem/Forms/InputVarients/TextInput'
import { COLOR_PLATE } from '../../DesignSystem/GraphAndCharts/color-palette'
import useBusinessPlanning from './useBusinessPlanning'
import { D_Bpd_data, bpdExecutiveSummary, bpdProductOverview, bpdInvestmentOpportunity, D_Bpd_Overview_data, API_Post_BPD_FooterInfo } from '../../features/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import SelectProblemModal from './SelectProblemModal'
import PageLoader from '../reuseablecomponent/PageLoader'
import BaseModal from '../../DesignSystem/Modals/BaseModal'
import HeadingSix from '../../DesignSystem/TypographyHeadings/HeadingSix'
import SecondaryButton from '../../DesignSystem/Buttons/SecondaryButton'
import { convertAmountInK } from '../../utils/convertAmountInK'
import CloseIcon from '@mui/icons-material/Close';
import NumericInput from '../../DesignSystem/Forms/InputVarients/NumericInput'
import { validator } from '../../utils/validator'
import useDownloadLPDF from '../../hooks/useDownloadLPDF'
import { generateCurrentDate } from '../../utils/generateCurrentDate'
import OopsPopUp from '../reuseablecomponent/PopUpVarients/OopsPopUp'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import WarningFreeTrialAccessResource from '../reuseablecomponent/PopUpVarients/WarningFreeTrialAccessResource'
import useApp from '../../hooks/useApp'
import BlurContainer from '../../DesignSystem/Container/BlurContainer'
import { paywall_locals } from '../../localization'
import HeadingThree from '../../DesignSystem/TypographyHeadings/HeadingThree'
import WarningLimitedResourceOopsPopup from '../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup'

const PAYWALL_LOCALS = paywall_locals.business_planning_dashboard

const CONTACT_ASSETS = { CALL, WWW, MAIL }

const LOCALS = locals.business_planning.my_dashboard;
const PSM_LOCALS = LOCALS.module.problem_solution_matrix;
const TMA_LOCALS = LOCALS.module.target_market_analysis;
const COA_LOCALS = LOCALS.module.competitor_analysis;
const ICP_LOCALS = LOCALS.module.idea_customer_profile;
const BOX_LOCALS = LOCALS.module.nine_box_canvas;
const GTM_LOCALS = LOCALS.module.go_to_marketing
const MEDIA_LOCALS = LOCALS.support_media;
const MEDIA_MODAL_LOCALS = LOCALS.support_media_modal;

const OverView = ({ summary_data_exist, bpd_id, handleEditTargetOverviewMetric, handleToggleOverviewModal, isHideDownload }) => {
    const dispatch = useDispatch();
    const permission = useSelector((state) => state.user.permission);
    const { overview_data_disabled, data } = useSelector((state) => state.app.business_planning_dashboard)
    const { upgradeRenewPaywallPopup } = useApp();
    return (
        <ul className='grid grid-cols-3 gap-x-[12px]'>
            {
                LOCALS.overview.map((detail, index) => {
                    return (
                        <li key={index} className={`min-h-[153px] p-[16px] rounded-lg ${index % 2 === 0 ? 'bg-p3 text-white' : 'bg-white border border-primary'}`}>
                            <div className='flex justify-between border-b pb-[4px]'>
                                <TextLarge text={detail.title} fontWeight={500} color={index % 2 === 0 ? "#FFFFFF" : "#372EE5"} />
                                <WarningLimitedResourceOopsPopup
                                    description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].edit.description1}
                                    description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].edit.description2}
                                >
                                    {
                                        ({ isFreeSubscription, handleTogglePopup }) => (
                                            <button onClick={() => {
                                                if(isFreeSubscription){
                                                    handleTogglePopup();
                                                    return;
                                                }
                                                handleEditTargetOverviewMetric({ "heading": detail.title, "data": data?.businessDashboardPlanning?.[detail?.name], "targetMetric": detail.name, "placeHolder": detail.placeHolder })
                                                handleToggleOverviewModal();
                                            }}><img src={index % 2 === 0 ? editIconWhite : editIconPrimary} alt="" /></button>
                                        )
                                    }
                                </WarningLimitedResourceOopsPopup>
                            </div>
                            <div className='py-[8px]'>
                                {/* <TextMedium text={detail.description} fontWeight={500} color={} /> */}
                                {
                                    isHideDownload
                                        ? (
                                            <>
                                                <TextSmall
                                                    text={data?.businessDashboardPlanning?.[detail?.name]}
                                                />
                                            </>
                                        ) : (
                                            <AutoSizeTextArea
                                                minRows={6}
                                                maxRows={6}
                                                styles={{
                                                    "backgroundColor": "transparent",
                                                    "color": index % 2 === 0 ? "#FFFFFF" : "#424242",
                                                    "paddingLeft": "0px",
                                                    "borderColor": "transparent"
                                                }}
                                                maxLength={250}
                                                onChangeEvent={(e) => {
                                                    dispatch(D_Bpd_Overview_data({ "name": detail.name, "value": e.target.value }))
                                                }}
                                                onBlurEvent={(e) => {
                                                    if (bpd_id) {
                                                        if (detail.name === "executiveSummary") {
                                                            dispatch(bpdExecutiveSummary({ "businessPlanningDashboardId": bpd_id, "executiveSummary": e.target.value }));
                                                        } else if (detail.name === "productOverview") {
                                                            dispatch(bpdProductOverview({ "businessPlanningDashboardId": bpd_id, "productOverview": e.target.value }));
                                                        } else if (detail.name === "investmentOpportunity") {
                                                            dispatch(bpdInvestmentOpportunity({ "businessPlanningDashboardId": bpd_id, "investmentOpportunity": e.target.value }));
                                                        }
                                                    }
                                                }}
                                                isDisabled={!summary_data_exist || overview_data_disabled[detail.name] || !permission?.edit}
                                                value={summary_data_exist ? data?.businessDashboardPlanning?.[detail?.name] : detail.description}
                                                placeHolder={detail.placeHolder}
                                            />
                                        )
                                }
                            </div>
                        </li>
                    )
                })
            }
        </ul>
    )
}

const BusinessPlanningMyDashboard = () => {

    const {
        bpd_id,
        overview,
        selected_problem,
        selected_solution,
        isProblemModalOpen,
        handleToggleModal,
        handleSelectedProblem,
        problems,
        tma,
        competitor,
        idealCustomerProfile,
        buildBusinessCanvas,
        gtmPlan,
        totalMarketSize,
        tmaSegments
    } = useBusinessPlanning();

    const summary_data_exist = true; // summary_data_exist

    const { handleDownloadPDF, pdfref } = useDownloadLPDF();

    const date = generateCurrentDate();
    const { isFreeSubscription, handleNavigateToHome, upgradeRenewPaywallPopup } = useApp();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isBPDModalOpen, setIsBPDModalOpen] = useState(false);
    const [isFooterShowWarning, setIsFooterShowWarning] = useState(false);
    const { bpd_footer, api_status, data } = useSelector((state) => state.app.business_planning_dashboard)
    const [bpdFooterInfo, setBPDFooterInfo] = useState({ "email": "", "phone": null, "website": "" });
    const [editOverViewData, setEditOverviewData] = useState({ "heading": "", "data": "", "targetMetric": "", "placeHolder": "" });
    const companyName = useSelector((state) => state.onboarding.value.Company);
    const [isHideDownload, setIsDownloadHide] = useState(false);
    const permission = useSelector((state) => state.user.permission);

    const handleEditTargetOverviewMetric = (details) => setEditOverviewData((prev) => ({ ...prev, ...details }))
    const handleToggleOverviewModal = () => setIsModalOpen((prev) => !prev);

    const handleEditBpdFooter = ({ name, value }) => setBPDFooterInfo((prev) => ({ ...prev, [name]: value }))
    const handleToggleBpdModal = () => setIsBPDModalOpen((prev) => !prev);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (api_status.status === "succeeded") {
            setIsModalOpen(false);
        }
        if (api_status.status === "failed") {
            message.open({ "type": "error", "content": api_status.message });
        }
    }, [api_status.status])

    const handleCloseBpdModal = () => {
        setIsBPDModalOpen(false)
        setBPDFooterInfo({ "email": "", "phone": "", "website": "" })
        setIsFooterShowWarning(false);
    }

    useEffect(() => {
        if (bpd_footer.status === "success") {
            handleCloseBpdModal()
        }
        if (bpd_footer.status === "failed") {
            message.open({ "type": "error", "content": bpd_footer.message });
        }
    }, [bpd_footer.status])

    return (
        <>
            {api_status.status === "loading" ? <PageLoader /> : null}
            {/* <OopsPopUp
                isOpen={isFreeSubscription}
                description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].access.description1}
                description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].access.description2}
                onClick={() => handleNavigateToHome()}
            /> */}
            <BaseModal
                isOpen={isModalOpen}
                handleCloseModal={() => setIsModalOpen(false)}
            >
                <button
                    onClick={() => setIsModalOpen(false)}
                    className='absolute top-[1rem] right-[1rem]'
                >
                    <CloseIcon />
                </button>
                <HeadingSix text={`Edit ${editOverViewData.heading}`} />
                <AutoSizeTextArea
                    minRows={6}
                    maxRows={6}
                    onChangeEvent={(e) => {
                        setEditOverviewData((prev) => ({ ...prev, "data": e.target.value }))
                    }}
                    maxLength={250}
                    value={editOverViewData.data}
                    placeHolder={editOverViewData.placeHolder}
                    styles={{
                        padding: "0.5rem"
                    }}
                />
                <div className='flex justify-between mt-[20px]'>
                    <SecondaryButton text={"Cancel"} onClick={() => {
                        setEditOverviewData({ "heading": "", "data": "", "targetMetric": "", "placeHolder": "" })
                        setIsModalOpen(false);
                    }} />
                    <PrimaryButton onClick={() => {
                        if (bpd_id) {
                            if (editOverViewData.targetMetric === "executiveSummary") {
                                dispatch(bpdExecutiveSummary({ "businessPlanningDashboardId": bpd_id, "executiveSummary": editOverViewData.data }));
                            } else if (editOverViewData.targetMetric === "productOverview") {
                                dispatch(bpdProductOverview({ "businessPlanningDashboardId": bpd_id, "productOverview": editOverViewData.data }));
                            } else if (editOverViewData.targetMetric === "investmentOpportunity") {
                                dispatch(bpdInvestmentOpportunity({ "businessPlanningDashboardId": bpd_id, "investmentOpportunity": editOverViewData.data }));
                            }
                        }
                    }} text={"Done"} />
                </div>
            </BaseModal>
            <SelectProblemModal
                isModalOpen={isProblemModalOpen}
                heading={PSM_LOCALS.modal.title}
                leftBtnName={PSM_LOCALS.modal.cancel}
                rightButtonName={PSM_LOCALS.modal.done}
                listOfData={problems}
                no_data={PSM_LOCALS.problem.problem_with_no_optimal_solution}
                handleCloseModal={handleToggleModal}
                handleSelectProblem={handleSelectedProblem}
                isPrimaryButtonDisabled={problems?.length === 0}
            />
            <BaseModal
                isOpen={isBPDModalOpen}
            >
                <button
                    onClick={handleCloseBpdModal}
                    className='absolute top-[1rem] right-[1rem]'
                >
                    <CloseIcon />
                </button>
                <HeadingSix text={MEDIA_MODAL_LOCALS.title} />
                <ul className='flex flex-col gap-y-[0.5rem]'>
                    {
                        MEDIA_MODAL_LOCALS.list_of_data.map((formItem) => {
                            return (
                                <div className='flex flex-col gap-y-[0.2rem]'>
                                    <label htmlFor={formItem.name}>{formItem.label}</label>
                                    {
                                        formItem.component_type === "input_text"
                                            ? (
                                                <TextInput
                                                    name={formItem.name}
                                                    value={bpdFooterInfo[formItem.name]}
                                                    onChangeEvent={(e) => {
                                                        if (String(e.target.value).length < formItem.char_limit) {
                                                            setBPDFooterInfo((prev) => ({ ...prev, [formItem.name]: e.target.value }))
                                                        }
                                                    }}
                                                    placeholder={formItem.placeholder}
                                                    className='border-[#ADAFAD]'
                                                />
                                            ) : null
                                    }
                                    {
                                        formItem.component_type === "input_number"
                                            ? (
                                                <div className='border rounded-md pl-[0.3rem]'>
                                                    <NumericInput
                                                        size='large'
                                                        name={formItem.name}
                                                        value={bpdFooterInfo[formItem.name]}
                                                        onChangeEvent={(value) => {
                                                            if (String(value).length < formItem.char_limit) {
                                                                setBPDFooterInfo((prev) => ({ ...prev, [formItem.name]: value }))
                                                            }
                                                        }}
                                                        placeholder={formItem.placeholder}
                                                        styles={{ fontSize: '1.5rem' }}
                                                    />
                                                </div>
                                            ) : null
                                    }
                                    {
                                        isFooterShowWarning && validator({ validator: formItem.validator, value: bpdFooterInfo[formItem.name] }) === false
                                            ? <TextSmall color='red' text={MEDIA_MODAL_LOCALS.warning_form_not_filled} fontWeight={500} />
                                            : null
                                    }
                                </div>
                            )
                        })
                    }
                </ul>
                <div className='flex justify-between mt-[20px]'>
                    <SecondaryButton text={MEDIA_MODAL_LOCALS.cancel} onClick={handleCloseBpdModal} />
                    <PrimaryButton
                        isLoading={bpd_footer.status === "loading"}
                        isDisabled={bpd_footer.status === "loading"} onClick={() => {
                            let isFormFilled = true;
                            MEDIA_MODAL_LOCALS.list_of_data.map((formItem) => {
                                if (validator({ validator: formItem.validator, value: bpdFooterInfo[formItem.name] }) == false) {
                                    isFormFilled = false;
                                }
                            })
                            if (isFormFilled === false) {
                                setIsFooterShowWarning(true);
                            } else {
                                dispatch(API_Post_BPD_FooterInfo({
                                    "businessPlanningDashboardId": bpd_id,
                                    ...bpdFooterInfo
                                }))
                            }
                        }} text={MEDIA_MODAL_LOCALS.done} />
                </div>
            </BaseModal>
            <
                
            >
                <div ref={pdfref} className='mt-[4rem] py-[10px] relative'>
                    <div className='flex justify-between'>
                        <HeadingFour text={LOCALS.title.replace('Your Company', companyName)} />
                        <WarningLimitedResourceOopsPopup
                            description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].download.description1}
                            description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].download.description2}
                        >
                            {
                                ({ handleTogglePopup, isFreeSubscription }) => (
                                    <Button
                                        className={isHideDownload ? 'invisible' : 'visible'}
                                        disabled={!permission?.download}
                                        onClick={() => {
                                            if (isFreeSubscription) {
                                                handleTogglePopup()
                                                return;
                                            }
                                            setTimeout(() => {
                                                setIsDownloadHide(true);
                                                handleDownloadPDF({ "fileName": `my_business_plan_${date}` })
                                                setIsDownloadHide(false);
                                            }, 100);
                                        }}
                                        type='link'
                                        icon={<DownloadOutlinedIcon className='text-primary' />}
                                    >
                                        <TextLarge text={LOCALS.download} fontWeight={500} color={"#372EE5"} />
                                    </Button>
                                )
                            }
                        </WarningLimitedResourceOopsPopup>
                    </div>
                    <OverView
                        data={overview}
                        isHideDownload={isHideDownload}
                        summary_data_exist={summary_data_exist}
                        bpd_id={bpd_id}
                        handleEditTargetOverviewMetric={handleEditTargetOverviewMetric}
                        handleToggleOverviewModal={handleToggleOverviewModal}
                    />
                    <div className='grid grid-cols-12 gap-[12px]'>
                        {/* problem card */}
                        <div className={`flex flex-col p-[16px] rounded-lg xl:col-span-3 col-span-6  min-h-[200px] bg-white border border-primary`}>
                            <div className='border-b pb-[4px] border-baseGray500 flex justify-between'>
                                <TextLarge text={PSM_LOCALS.problem.title} fontWeight={500} color={false ? "#FFFFFF" : "#372EE5"} />
                                
                                <WarningLimitedResourceOopsPopup
                                    description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].edit.description1}
                                    description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].edit.description2}
                                >
                                    {
                                        ({ isFreeSubscription, handleTogglePopup }) => (
                                            <button onClick={() => {
                                                if(isFreeSubscription){
                                                    handleTogglePopup();
                                                    return;
                                                }
                                                problems?.length == 0 ? handleTogglePopup() : handleToggleModal()
                                            }}><img src={false ? editIconWhite : editIconPrimary} alt="" /></button>
                                        )
                                    }
                                </WarningLimitedResourceOopsPopup>
                            </div>
                            <div className={classNames(
                                'w-full flex flex-col h-full',
                                { 'justify-center items-center': !selected_problem },
                                { 'justify-start items-start pt-[12px]': selected_problem },
                            )}>
                                {
                                    selected_problem
                                        ? <TextMedium className={"text-start"} text={selected_problem} fontWeight={500} />
                                        : (
                                            <OopsPopUp
                                                description1={PSM_LOCALS.problem.problem_with_no_optimal_solution}
                                            >
                                                {
                                                    ({ handleTogglePopup }) => (
                                                        <>
                                                            <PrimaryButton isDisabled={problems?.length == 0 || !permission?.edit} styles={{ paddingBottom: isHideDownload ? "1rem" : '' }} size='small' onClick={() => problems?.length == 0 ? handleTogglePopup() : handleToggleModal()} text={PSM_LOCALS.problem.select_problem} />
                                                            {
                                                                problems?.length == 0
                                                                    ? <TextMedium fontWeight={500} color='#9E9E9E' className={"text-center mt-[1rem]"} text={PSM_LOCALS?.problem?.no_problem_optim_soln} />
                                                                    : null
                                                            }
                                                        </>
                                                    )
                                                }
                                            </OopsPopUp>
                                        )
                                }
                            </div>
                        </div>
                        {/* solution card */}
                        <div className={`flex flex-col p-[16px] rounded-lg xl:col-span-3 col-span-6 min-h-[200px] bg-white border border-primary`}>
                            <div className='border-b pb-[4px] border-baseGray500 flex justify-between'>
                                <TextLarge text={PSM_LOCALS.solution.title} fontWeight={500} color={false ? "#FFFFFF" : "#372EE5"} />
                                <WarningLimitedResourceOopsPopup
                                    description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].edit.description1}
                                    description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].edit.description2}
                                >
                                    {
                                        ({ isFreeSubscription, handleTogglePopup }) => (
                                            <button onClick={() => {
                                                if(isFreeSubscription){
                                                    handleTogglePopup();
                                                    return;
                                                }
                                                navigate(PSM_LOCALS.module_path)
                                            }}><img src={false ? editIconWhite : editIconPrimary} alt="" /></button>
                                        )
                                    }
                                </WarningLimitedResourceOopsPopup>
                            </div>
                            <div className={classNames('w-full h-full pt-[12px]', { 'flex items-center justify-center': !selected_solution })}>
                                <TextMedium className={"text-center"} color={selected_solution ? "#000000" : "#9E9E9E"} text={selected_problem ? selected_solution ?? PSM_LOCALS.solution.no_data : PSM_LOCALS.solution.no_problem_selected} fontWeight={500} />
                            </div>
                        </div>
                        {/* market anlysis card */}
                        <div className="flex flex-col p-[16px] rounded-lg xl:col-span-6 col-span-6 min-h-[200px] bg-white border border-primary">
                            <div className='border-b pb-[4px] border-baseGray500 flex justify-between'>
                                <TextLarge text={TMA_LOCALS.title} fontWeight={500} color={false ? "#FFFFFF" : "#372EE5"} />
                                <WarningLimitedResourceOopsPopup
                                    description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].edit.description1}
                                    description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].edit.description2}
                                >
                                    {
                                        ({ isFreeSubscription, handleTogglePopup }) => (
                                            <button onClick={() => {
                                                if(isFreeSubscription){
                                                    handleTogglePopup();
                                                    return;
                                                }
                                                navigate(TMA_LOCALS.module_path)
                                            }}><img src={false ? editIconWhite : editIconPrimary} alt="" /></button>
                                        )
                                    }
                                </WarningLimitedResourceOopsPopup>
                            </div>
                            <div className={classNames('flex h-full', { 'items-center': tma?.length === 0 && summary_data_exist })}>
                                {
                                    (tmaSegments?.length === 0 && summary_data_exist)
                                        ? (
                                            <div className='flex items-center justify-center h-full'>
                                                <TextMedium color='#9E9E9E' className={"text-center"} text={TMA_LOCALS.no_data} fontWeight={500} />
                                            </div>
                                        ) : (
                                            <>
                                                <div className="graph w-[160px] h-[160px]">
                                                    <DonutChartAntD
                                                        centerFontSize={10}
                                                        isRespPadding={true}
                                                        data={tmaSegments?.length === 0 ? TMA_LOCALS.data : tmaSegments}
                                                        isLabelVisible={false}
                                                        legendVisible={false}
                                                        donutCenterValuePreUnit={"$"}
                                                        customCenterValue={convertAmountInK(totalMarketSize)}
                                                    />
                                                </div>
                                                <div className="tooltip_input flex flex-col gap-y-[0.5rem] w-full mt-[1rem]">
                                                    {
                                                        (tmaSegments?.length === 0 ? TMA_LOCALS.data : tmaSegments).map((subData, index) => {
                                                            return (
                                                                <div className='flex justify-between border-b border-baseGray400'>
                                                                    <div className='flex items-center gap-x-[0.5rem]'>
                                                                        <div className='w-[1rem] aspect-square rounded-md bg-red-500' style={{ backgroundColor: COLOR_PLATE[index] }}></div>
                                                                        <TextMedium text={subData.type} fontWeight={500} />
                                                                    </div>
                                                                    <TextMedium text={subData.value + '%'} fontWeight={500} />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </>
                                        )
                                }
                            </div>
                        </div>
                        {/* competitor name and icp file names */}
                        {/* isFreeSubscription */}
                        {
                            (isFreeSubscription) && (
                                <div className='absolute top-[65%] z-10 flex flex-col items-center'>
                                    <HeadingThree text={PAYWALL_LOCALS[upgradeRenewPaywallPopup].restrict_other_part} className={' text-center px-[1rem] text-white'} />
                                    <PrimaryButton text={upgradeRenewPaywallPopup === "upgrade" ? "Upgrade" : "Renew"} />
                                </div>
                            )
                        }
                        <div className={`flex py-[16px] rounded-lg xl:col-span-6 col-span-6 min-h-[242px] bg-p3 border-primary ${isFreeSubscription ? 'blur-[12px] bg-black/50 relative select-none' : ''}`}>
                            <div className='w-[37%] border-r border-dashed px-[1rem]'>
                                <div className='border-b pb-[4px] border-white flex justify-between'>
                                    <TextLarge text={COA_LOCALS.title} fontWeight={500} color={'#FFFFFF'} />
                                    <button onClick={() => navigate(COA_LOCALS.module_path)}><img src={true ? editIconWhite : editIconPrimary} alt="" /></button>
                                </div>
                                {
                                    competitor?.length == 0 && summary_data_exist
                                        ? (
                                            <div className='text-center pt-[1rem] h-[80%] flex items-center'>
                                                <TextMedium className={"text-center"} color='#9E9E9E' text={COA_LOCALS.no_data} fontWeight={500} />
                                            </div>
                                        ) : (
                                            <ul className='list-disc pl-[1rem] flex flex-col pt-[12px] gap-y-[12px]'>
                                                {
                                                    (summary_data_exist ? competitor : COA_LOCALS.competitor_names)?.map((comp) => (
                                                        <li className='text-white'><TextMedium text={comp} color='#FFFFFF' fontWeight={500} /></li>
                                                    ))
                                                }
                                            </ul>
                                        )
                                }
                            </div>
                            <div className='w-[calc(100%-37%)] px-[1rem] '>
                                <div className='border-b pb-[4px] border-white flex justify-between'>
                                    <TextLarge text={ICP_LOCALS.title} fontWeight={500} color={"#FFFFFF"} />
                                    <button onClick={() => navigate(ICP_LOCALS.module_path)}><img src={true ? editIconWhite : editIconPrimary} alt="" /></button>
                                </div>
                                <div className={classNames('flex gap-x-[18px]', { 'items-center justify-center h-[80%]': idealCustomerProfile?.length === 0 })}>
                                    {
                                        idealCustomerProfile?.length === 0 && summary_data_exist
                                            ? (
                                                <div className='text-center flex items-center justify-center pt-[1rem] text-white h-full'>
                                                    <TextMedium className={"text-center"} color='#9E9E9E' text={ICP_LOCALS.no_data} fontWeight={500} />
                                                </div>
                                            ) : (
                                                <>
                                                    <ul className='list-disc pl-[1rem] flex flex-col pt-[12px] gap-y-[12px]'>
                                                        {
                                                            (summary_data_exist ? idealCustomerProfile : ICP_LOCALS.custom_template_names)?.slice(0, 5)?.map((competitor) => (
                                                                <li className='text-white'><TextMedium text={competitor} color='#FFFFFF' fontWeight={500} /></li>
                                                            ))
                                                        }
                                                    </ul>
                                                    <ul className='list-disc pl-[1rem] flex flex-col pt-[12px] gap-y-[12px]'>
                                                        {
                                                            (summary_data_exist ? idealCustomerProfile : ICP_LOCALS.custom_template_names)?.slice(5)?.map((competitor) => (
                                                                <li className='text-white'><TextMedium text={competitor} color='#FFFFFF' fontWeight={500} /></li>
                                                            ))
                                                        }
                                                    </ul>
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`border p-[16px] min-h-[10rem] xl:col-span-6 col-span-12 row-span-3 rounded-lg border-primary ${isFreeSubscription ? 'blur-[12px] bg-black/50 select-none' : ''}`}>
                            {
                                Array.from({ length: Object.keys(summary_data_exist ? buildBusinessCanvas : BOX_LOCALS.data).length })?.map((_, index) => {
                                    const keyReference = Object.keys(summary_data_exist ? buildBusinessCanvas : BOX_LOCALS.data);
                                    const TITLE = keyReference[index]
                                    const list = summary_data_exist ? buildBusinessCanvas : BOX_LOCALS.data
                                    return (
                                        <div className=''>
                                            <div className='py-[0.5rem] flex justify-between'>
                                                <TextLarge text={TITLE} color={"#372EE5"} fontWeight={500} />
                                                {index === 0 ? <button onClick={() => navigate(BOX_LOCALS.module_path)}><img src={false ? editIconWhite : editIconPrimary} alt="" /></button> : null}
                                            </div>
                                            <div className='flex justify-start gap-x-[12px]'>
                                                {
                                                    (summary_data_exist ? buildBusinessCanvas[TITLE] : BOX_LOCALS.data[TITLE])?.length === 0 && summary_data_exist
                                                        ? (
                                                            <div className='text-center flex items-center justify-center pt-[1rem] text-black h-full'>
                                                                <TextMedium className={"text-center"} color='#9E9E9E' text={BOX_LOCALS.no_data[TITLE]} fontWeight={500} />
                                                            </div>
                                                        ) : (
                                                            list[TITLE]?.map((value) => (
                                                                <div style={{ backgroundColor: BOX_LOCALS.color_code[TITLE] }} className='w-[125px] h-[125px] p-[10px] rounded-lg border'>
                                                                    <TextSmall text={value} fontWeight={500} />
                                                                </div>
                                                            ))
                                                        )
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={`border min-h-[127px] xl:col-span-6 col-span-12 rounded-lg border-primary p-[16px] ${isFreeSubscription ? 'blur-[12px] bg-black/50 select-none' : ''}`}>
                            <div className='border-b pb-[4px] border-baseGray500 flex justify-between'>
                                <TextLarge text={GTM_LOCALS.title} fontWeight={500} color={false ? "#FFFFFF" : "#372EE5"} />
                                <button onClick={() => navigate(GTM_LOCALS.module_path)}><img src={false ? editIconWhite : editIconPrimary} alt="" /></button>
                            </div>
                            <div className='flex justify-between items-center h-full'>
                                {
                                    gtmPlan?.length == 0 && summary_data_exist
                                        ? (
                                            <div className='text-center flex items-center justify-center pt-[1rem] text-white h-full'>
                                                <TextMedium className={"text-center"} color='#9E9E9E' text={GTM_LOCALS.no_data} fontWeight={500} />
                                            </div>
                                        ) : (
                                            (summary_data_exist ? gtmPlan : GTM_LOCALS.metrics)?.map((metric, index) => {
                                                return (
                                                    <React.Fragment>
                                                        <div className='w-[90px]'>
                                                            <TextMedium key={index} text={metric} fontWeight={500} />
                                                        </div>
                                                        <div style={{ display: index === (summary_data_exist ? gtmPlan : GTM_LOCALS.metrics)?.length - 1 ? 'none' : 'block' }} className='min-h-[45px] border border-primary'></div>
                                                    </React.Fragment>
                                                )
                                            })
                                        )
                                }
                            </div>
                        </div>
                        <div className={`border border-p7 min-h-[60px] xl:col-span-6 col-span-12 bg-p8 rounded-lg grid grid-cols-3 px-[24px] relative ${isFreeSubscription ? 'blur-[12px] bg-black/50 select-none' : ''}`}>
                            <button disabled={!permission?.edit} onClick={() => {
                                setIsBPDModalOpen(true)
                                setBPDFooterInfo({ email: data?.businessDashboardPlanning?.email, phone: Number(data?.businessDashboardPlanning?.phone), website: data?.businessDashboardPlanning?.website })
                            }} className='absolute top-[0.5rem] right-[0.5rem]'><img src={false ? editIconWhite : editIconPrimary} alt="" /></button>
                            {
                                MEDIA_LOCALS?.map((media) => {
                                    return (
                                        <div className='flex items-center'>
                                            <img src={CONTACT_ASSETS[media.media.assetName]} alt="" />
                                            <TextInput value={data?.businessDashboardPlanning?.[media.media.name]} isDisabled size='small' fontSize={14} placeholder={media.media.placeholder} backgroundColor='transparent' />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default BusinessPlanningMyDashboard