import React from 'react'
import TextareaAutosize from 'react-textarea-autosize';

const ListOfCompetitor = ({ data, title="Here is the list of competitors to your company" }) => {
    return (
        <div>
            <p className='text-[#111827] font-InterRegular text-[16px] font-semibold tracking-wide'>{title}</p>
            <div className='w-full  bg-white mb-[48px] rounded-[8px]'>
                <TextareaAutosize
                    disabled={true}
                    value={data}
                    style={{ resize: 'none', outline: 'none' }}
                    className="text-[14px] scrollbar-hide w-full rounded-[8px] p-[8px] border-[#BECEFF] border" />
            </div>
        </div>
    )
}

export default ListOfCompetitor