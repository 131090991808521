export const FormatGTMDataCompatableBE = (data) => {
    return {
        "planId": data?.planId ?? null,
        "channel": data.channel ?? null,
        "currency": data.currency ?? null,
        "budget_allocation_advertisement_campaings_promotion": data.budget.budget_allocation_advertisement_campaings_promotion ?? null,
        "budget_total_expenditure_salaries_wages_benefit_team": data.budget.budget_total_expenditure_salaries_wages_benefit_team ?? null,
        "budget_total_amount_miscellaneous_expenses": data.budget.budget_total_amount_miscellaneous_expenses ?? null,
        "priority": data.priority ?? "",
        "timeline_start_date": data?.timeline?.[0] ?? null,
        "timeline_end_date": data?.timeline?.[1] ?? null,
        "tags": data.tags,
        "chat_count": data?.chat_count ?? 0
    }
}

export const FormatGTMDataCompatableFE = (data) => {
    return data.map((d) => {
        return (
            {
                "planId": d?._id ?? null,
                "channel": d.channel ?? null,
                "currency": d.currency ?? null,
                "budget": {
                    "budget_allocation_advertisement_campaings_promotion": d.budget_allocation_advertisement_campaings_promotion ?? null,
                    "budget_total_expenditure_salaries_wages_benefit_team": d.budget_total_expenditure_salaries_wages_benefit_team ?? null,
                    "budget_total_amount_miscellaneous_expenses": d.budget_total_amount_miscellaneous_expenses ?? null,
                },
                "priority": typeof d?.priority === "string" ? (d.priority.length>0 ? d.priority : null) : null,
                "timeline": (d.timeline_start_date && d.timeline_end_date) ? [d.timeline_start_date, d.timeline_end_date] : null,
                "tags": d.tags,
                "chat_count": data?.chat_count ?? 0
            }
        )
    })
}

export const FormatMarketingFunnelDataCompatableBE = (data) => {
    return {
        "marketingFunnelId": data?.marketingFunnelId ?? null,
        "stage": data.stage ?? null,
        "currency": data.currency ?? null,
        "funnel_stage": data.funnel_stage ?? null,
        "content_assets": data.content_assets ?? [],
        "sales_activities": data.sales_activities ?? [],
        "timeline_start_date": data?.timeline?.[0] ?? null,
        "timeline_end_date": data?.timeline?.[1] ?? null,
        "budget": data.budget ?? null,
        "tags": data.tags,
        "chat_count": data?.chat_count ?? 0
    }
}

export const FormatMarketingFunnelDataCompatableFE = (data) => {
    const formattedData = data.map((d) => {
        return (
            {
                "marketingFunnelId": d?._id ?? null,
                "stage": typeof d?.stage === "string" ? (d.stage.length>0 ? d.stage : null) : null,
                "currency": d.currency,
                "funnel_stage": typeof d?.funnel_stage === "string" ? (d.funnel_stage.length>0 ? d.funnel_stage : null) : null,
                "content_assets": d.content_assets ?? [],
                "sales_activities": d.sales_activities ?? [],
                "timeline": (d.timeline_start_date && d.timeline_end_date) ? [d.timeline_start_date, d.timeline_end_date] : null,
                "budget": d.budget ?? null,
                "tags": d.tags,
                "chat_count": d?.chat_count ?? 0
            }
        )
    })
    return formattedData
}