import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../services/api/api'
import routesConfig from "../services/config/routesConfig.json"
import {generateUrlEndPoint} from "../utils/endPointsGeneration";

export const API_REFRESH_TOKEN = createAsyncThunk(
    "app/API_REFRESH_TOKEN",
    async (_, { getState }) => {
        try {
            const state = getState();
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'refresh-token', 'get-refresh-token');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": { "user": state.user.value.id } }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const bpdFullSummaryApi = createAsyncThunk(
    'bpd/bpdFullSummaryApi',
    async (bingoData, { getState, dispatch }) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'business-planning-dashboard', 'full-summary');
            if (!!urlGenerator) {
                const urlConfig = { data: bingoData, ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const bpdExecutiveSummary = createAsyncThunk(
    'bpd/bpdExecutiveSummary',
    async (data, { getState, dispatch }) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'business-planning-dashboard', 'executive-summary');
            if (!!urlGenerator) {
                const urlConfig = { data, ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const bpdProductOverview = createAsyncThunk(
    'bpd/bpdProductOverview',
    async (data, { getState, dispatch }) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'business-planning-dashboard', 'product-overview');
            if (!!urlGenerator) {
                const urlConfig = { data, ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const bpdInvestmentOpportunity = createAsyncThunk(
    'bpd/bpdInvestmentOpportunity',
    async (data, { getState, dispatch }) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'business-planning-dashboard', 'investment-opportunity');
            if (!!urlGenerator) {
                const urlConfig = { data, ...urlGenerator }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.errMsg);
        }
    }
);

export const API_Post_Website_Userinformation = createAsyncThunk(
    "website/API_Post_Website_Userinformation",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'web', 'form');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Post_BPD_FooterInfo = createAsyncThunk(
    "bpd/API_Post_BPD_FooterInfo",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'business-planning-dashboard', 'footer-info');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Post_WebsiteForm = createAsyncThunk(
    "website/API_Post_WebsiteForm",
    async ({ data, formType }, { getState }) => {
        try {
            let targetUrl;
            if(formType === "subscribe-new-blog"){
                targetUrl = 'subscribe-new-blog'
            }else if(formType === "contact_us"){
                targetUrl = 'contact-us'
            }else if(formType === "join_community"){
                targetUrl = 'join-community'
            }else if(formType === "get_early_access"){
                targetUrl = 'get-early-access'
            }else if(formType === "request_demo"){
                targetUrl = 'request-demo'
            }else if(formType === "upgrade_plan"){
                targetUrl = 'upgrade-plan'
            }else if(formType === "contact_sales"){
                targetUrl = 'contact-sales'
            }
            const utm_params = getState().app.ad_utm_params;
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'website', targetUrl);
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, "data": { ...data, ...utm_params }, "headers": { "x-api-key": import.meta.env.VITE_REACT_X_API_KEY } }
                const response = await api.request(urlConfig);
                return {...response?.data, formType};
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_HelpGuideData = createAsyncThunk(
    "app/API_Fetch_HelpGuideData",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'help-guide', 'help-guide');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_HelpGuideTopicData = createAsyncThunk(
    "app/API_Fetch_HelpGuideTopicData",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'help-guide', 'topic-category-qna-preview-count');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_HelpGuideCatgQaData = createAsyncThunk(
    "app/API_Fetch_HelpGuideCatgQaData",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'help-guide', 'url-data');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return {...response?.data, ...data};
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_HelpGuideSearchQNAData = createAsyncThunk(
    "app/API_Fetch_HelpGuideSearchQNAData",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'help-guide', 'search-category-or-qna');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_ResourceGuideData = createAsyncThunk(
    "app/API_Fetch_ResourceGuideData",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'resource-guide', 'resource-guide');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_ResourceGuideTopicData = createAsyncThunk(
    "app/API_Fetch_ResourceGuideTopicData",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'resource-guide', 'topic-category-qna-preview-count');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_ResourceGuideCatgQaData = createAsyncThunk(
    "app/API_Fetch_ResourceGuideCatgQaData",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'resource-guide', 'url-data');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return {...response?.data, ...data};
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Fetch_ResourceGuideSearchQNAData = createAsyncThunk(
    "app/API_Fetch_ResourceGuideSearchQNAData",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'resource-guide', 'search-category-or-qna');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_Add_UTM_Params = createAsyncThunk(
    "website/API_Add_UTM_Params",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'website', 'ad-utm-params');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data, "headers": { "x-api-key": import.meta.env.VITE_REACT_X_API_KEY } }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_FETCH_PRICING_INFO = createAsyncThunk(
    "payment/API_FETCH_PRICING_INFO",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'payment-gateway', 'pricing-info');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_FETCH_PAYMENT_GATEWAY = createAsyncThunk(
    "payment/API_FETCH_PAYMENT_GATEWAY",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'payment-gateway', 'payment-gateway-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_INITIATE_SUBSCRIPTION = createAsyncThunk(
    "payment/API_INITIATE_SUBSCRIPTION",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'payment-gateway', 'initiate-subscription');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

export const API_SUBSCRIPTION_LIST = createAsyncThunk(
    "payment/API_SUBSCRIPTION_LIST",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'payment-gateway', 'subscription-payment-list');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);


export const API_CANCEL_SUBSCRIPTION = createAsyncThunk(
    "payment/API_CANCEL_SUBSCRIPTION",
    async (data) => {
        try {
            const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'payment-gateway', 'cancel-subscription');
            if (!!urlGenerator) {
                const urlConfig = { ...urlGenerator, data }
                const response = await api.request(urlConfig);
                return response?.data;
            }
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    }
);

const initialBmSaveState = {
    "revenueScreen": [-1,-1,-1,-1,-1,-1],
    "costScreen": [-1,-1,-1,-1,-1,-1],
    "workingCapital": [-1],
    "debtWorking": [-1],
    "cashFlow": [-1],
    "balanceSheet": [-1],
    "pAndL": [-1]
}

const initialState = {
    "location_history": [],
    "ad_utm_params": {
        "utm_source": '',
        "utm_medium": '',
        "utm_campaign": '',
        "utm_content": '',
        "utm_term": '',
        "gclid": ''
    },
    isAppRefreshed: 0,
    activeRoute: null,
    lastVisitedRoute: null,
    stackLastSingletVisitedRoute: null,
    traceBadResponse: {},
    redirectUserToLogin: false,
    "checkPaymentStatus": { "isWarningPopupOpen": false, "isPlanActive": true }, // true - active/free plan ,false - expired
    networkStatus: navigator.onLine,
    "help_guide": {
        "api_status": "idle",
        "data": null,
        "error_message": null,
        "search_api_status": "idle",
        "search_api_results": []
    },
    "resource_guide": {
        "api_status": "idle",
        "data": null,
        "error_message": null,
        "search_api_status": "idle",
        "search_api_results": []
    },
    "edit_business_model_navigation_screen_id":{
        "revenue-screen": 0,
        "cost-screen": 0
    },
    "business_model_trace_save_hit_button": initialBmSaveState,
    "is_save_next_generate_error_popup_show": false,
    "warning_free_trial_access_resource": false,
    "business_planning_dashboard": {
        "summary_data_exist": false,
        "api_status": {
            "status": "idle",
            "message": null
        },
        "data": null,
        "overview_data_disabled": {
            "executiveSummary": false,
            "investmentOpportunity": false,
            "productOverview": false
        },
        "bpd_footer": {
            "status": "idle",
            "message": null
        }
    },
    "website_form": {
        "is_open": false,
        "form_name": "contact_us",
        "api_success_popup_open": false,
        "api_status": {
            "status": "",
            "message": null
        }
    },
    "pricing_info": {
        "api_status": "idle",
        "data": null,
        "message": null
    },
    "payment_gateway_list": {
        "api_status": "idle",
        "data": null,
        "message": null
    },
    "initiate_subscription": {
        "api_status": "idle",
        "data": null,
        "message": null
    },
    "subscription_list": {
        "api_status": "idle",
        "data": null,
        "message": null
    },
    "cancel_subscription": {
        "api_status": "idle",
        "message": null
    }
}

const appSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        D_Update_WindowLocationHistory(state, action){
            state.location_history = [ ...state.location_history, action.payload ]
        },
        D_Reset_AdUtmParams(state, action){
            state.ad_utm_params = action.payload;
        },
        D_Reset_WebsiteForm_Status(state){
            state.website_form = {
                "is_open": false,
                "form_name": "contact_us",
                "api_success_popup_open": false,
                "api_status": {
                    "status": "",
                    "message": null
                }
            }
        },
        D_Toggle_Website_form(state, action){
            state.website_form = {...state.website_form, ...action.payload};
        },
        D_Reset_bpd_status_message(state, action){
            state.business_planning_dashboard.api_status.message = null;
        },
        D_ToggleWarningPopup(state){
            state.warning_free_trial_access_resource = !state.warning_free_trial_access_resource;
        },
        D_Update_Onboarding_Screen_Id(state, action){
            console.log(action.payload);
            if(action.payload.type==="reset"){ //default type is undefined.
                state.edit_business_model_navigation_screen_id = { "revenue-screen": 0, "cost-screen": 0 }
                return;
            }
            state.edit_business_model_navigation_screen_id = {
                ...state.edit_business_model_navigation_screen_id,
                [action.payload.screenName]: action.payload.value
            }
        },
        D_Update_BM_SaveButton_Click(state){
            
        },
        D_Reset_BusinessModel_SaveButtonState(state){
            state.business_model_trace_save_hit_button = initialBmSaveState
        },
        updatedAppRefreshStatus(state, action){
            state.isAppRefreshed = action.payload;
        },
        updateLastVisitedRoute(state, action) {
            state.lastVisitedRoute = state.activeRoute;
            state.activeRoute = action.payload;
            state.stackLastSingletVisitedRoute = state.lastVisitedRoute === state.activeRoute ? state.stackLastSingletVisitedRoute : state.lastVisitedRoute
        },
        updateBadResponse(state, action) {
            const { requestUrl } = action.payload;
            if( requestUrl in state.traceBadResponse){ // api url already exists
                state.traceBadResponse = { ...state.traceBadResponse, [requestUrl]: state.traceBadResponse[requestUrl]+1 }
            }else{ // api url is not present, append new url
                state.traceBadResponse = { ...state.traceBadResponse, [requestUrl]: 1 }
            }
            if(state.traceBadResponse[requestUrl]===3){
                state.redirectUserToLogin = true;
            }
        },
        discardSuccessApiUrlOnTraceRequest(state, action) {
            const { requestUrl } = action.payload;
            let traceBadResponse = state.traceBadResponse;
            delete traceBadResponse[requestUrl];
            state.traceBadResponse = traceBadResponse;
        },
        forceUserToLogin(state) {
            console.log("force logout");
            state.redirectUserToLogin = true;
        },
        updatePaymentStatus(state, action) {
            state.checkPaymentStatus = { ...state.checkPaymentStatus, ...action.payload }; // false - trial / plan expired
        },
        DUpdateNetworkStatus(state, action){
            state.networkStatus = action.payload
        },
        D_App_Toggle_ShowErrorNextGeneratePopup(state){
            state.is_save_next_generate_error_popup_show = !state.is_save_next_generate_error_popup_show;
        },
        D_Bpd_data(state, action){
            if(action.payload?.name){
                state.business_planning_dashboard.data[action.payload?.name] = action.payload?.value
            }
        },
        D_Bpd_Overview_data(state, action){
            if(action.payload?.name){
                state.business_planning_dashboard.data.businessDashboardPlanning[action.payload?.name] = action.payload?.value
            }
        },
        D_Reset_HelpGuide(state, action){
            state.help_guide = {
                ...state.help_guide,
                ...action.payload
            };
        },
        D_Reset_HelpGuide_SearchApiStatus(state){
            state.help_guide.error_message = null;
            state.help_guide.search_api_status = "idle";
        },
        D_Reset_ResourceGuide(state, action){
            state.resource_guide = {
                ...state.resource_guide,
                ...action.payload
            };
        },
        D_Reset_ResourceGuide_SearchApiStatus(state){
            state.resource_guide.error_message = null;
            state.resource_guide.search_api_status = "idle";
        },
        D_Reset_Payment_Error_Message(state, action){
            const { target_state } = action.payload || {};
            state[target_state].message = null;
            state[target_state].api_status = "idle";
        },
        D_ResetCancelSubscriptionApiStatus(state,action){
            state.cancel_subscription.api_status = "idle";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(bpdFullSummaryApi.pending, (state) => {
                state.business_planning_dashboard.api_status.status = "loading";
                state.business_planning_dashboard.summary_data_exist = false;
            })
            .addCase(bpdFullSummaryApi.fulfilled, (state, action) => {
                state.business_planning_dashboard.api_status.status = "succeeded";
                const { executiveSummary, investmentOpportunity, productOverview } = action.payload?.businessDashboardPlanning || {}
                state.business_planning_dashboard.data = {...action.payload, "selected_problem": null, "selected_solution": null};
                state.business_planning_dashboard.summary_data_exist = true;
                state.business_planning_dashboard.overview_data_disabled = {
                    executiveSummary: executiveSummary?.length>0,
                    investmentOpportunity: investmentOpportunity?.length>0,
                    productOverview: productOverview?.length>0
                }
            })
            .addCase(bpdFullSummaryApi.rejected, (state, action) => {
                state.business_planning_dashboard.api_status = { "status": 'failed', "message": action.error.message }
            })

            .addCase(bpdExecutiveSummary.pending, (state) => {
                state.business_planning_dashboard.api_status.status = "loading";
                state.status = 'loading';
            })
            .addCase(bpdExecutiveSummary.fulfilled, (state, action) => {
                state.business_planning_dashboard.api_status.status = "succeeded";
                state.status = 'loading';
                state.business_planning_dashboard.overview_data_disabled.executiveSummary = action.payload?.executiveSummary?.length>0;
                state.business_planning_dashboard.data.businessDashboardPlanning.executiveSummary = action.payload?.executiveSummary;
            })
            .addCase(bpdExecutiveSummary.rejected, (state, action) => {
                state.business_planning_dashboard.api_status = { "status": 'failed', "message": action.error.message }
            })

            .addCase(bpdProductOverview.pending, (state) => {
                state.business_planning_dashboard.api_status.status = "loading";
                state.status = 'loading';
            })
            .addCase(bpdProductOverview.fulfilled, (state, action) => {
                state.business_planning_dashboard.api_status.status = "succeeded";
                state.status = 'loading';
                state.business_planning_dashboard.overview_data_disabled.productOverview = action.payload?.productOverview?.length>0;
                state.business_planning_dashboard.data.businessDashboardPlanning.productOverview = action.payload?.productOverview;
            })
            .addCase(bpdProductOverview.rejected, (state, action) => {
                state.business_planning_dashboard.api_status = { "status": 'failed', "message": action.error.message }
            })

            .addCase(bpdInvestmentOpportunity.pending, (state) => {
                state.business_planning_dashboard.api_status.status = "loading";
                state.status = 'loading';
            })
            .addCase(bpdInvestmentOpportunity.fulfilled, (state, action) => {
                state.business_planning_dashboard.api_status.status = "succeeded";
                state.status = 'loading';
                state.business_planning_dashboard.overview_data_disabled.investmentOpportunity = action.payload?.investmentOpportunity?.length>0;
                state.business_planning_dashboard.data.businessDashboardPlanning.investmentOpportunity = action.payload?.investmentOpportunity;
            })
            .addCase(bpdInvestmentOpportunity.rejected, (state, action) => {
                state.business_planning_dashboard.api_status = { "status": 'failed', "message": action.error.message }
            })

            .addCase(API_Post_BPD_FooterInfo.pending, (state) => {
                state.business_planning_dashboard.bpd_footer.status = "loading"
            })
            .addCase(API_Post_BPD_FooterInfo.fulfilled, (state, action) => {
                state.business_planning_dashboard.bpd_footer.status = "success"
                state.business_planning_dashboard.data.businessDashboardPlanning = {
                    ...state.business_planning_dashboard.data?.businessDashboardPlanning,
                    email:action.payload.email,
                    phone:Number(action.payload.phone),
                    website:action.payload.website
                }
            })
            .addCase(API_Post_BPD_FooterInfo.rejected, (state, action) => {
                state.business_planning_dashboard.bpd_footer = { "status": 'failed', "message": action.error.message }
            })


            .addCase(API_Post_WebsiteForm.pending, (state) => {
                state.website_form.api_status.status = "loading";
            })
            .addCase(API_Post_WebsiteForm.fulfilled, (state, action) => {
                state.website_form.api_status.status = "success";
                if(action.payload.formType === "subscribe-new-blog") return;
                state.website_form.is_open = false;
                state.website_form.api_success_popup_open = true;
                // state.ad_utm_params = {
                //     "utm_source": '',
                //     "utm_campaign": '',
                //     "utm_medium": '',
                //     "utm_term": '',
                //     "utm_content": '',
                //     "gclid": ''
                // };
            })
            .addCase(API_Post_WebsiteForm.rejected, (state, action) => {
                state.website_form.api_status = { "status": 'failed', "message": "Oops! Something went wrong. We couldn't submit your form. Please try again." }
            })
            .addCase(API_Fetch_HelpGuideData.pending, (state, action) => {
                state.help_guide.api_status = "loading";
            })
            .addCase(API_Fetch_HelpGuideData.fulfilled, (state, action) => {
                state.help_guide.api_status = "success"
                state.help_guide.data = action.payload;
            })
            .addCase(API_Fetch_HelpGuideData.rejected, (state, action) => {
                state.help_guide.api_status = "failed";
                state.help_guide.error_message = action.error.message
            })

            .addCase(API_Fetch_HelpGuideTopicData.pending, (state, action) => {
                state.help_guide.api_status = "loading";
            })
            .addCase(API_Fetch_HelpGuideTopicData.fulfilled, (state, action) => {
                state.help_guide.api_status = "success"
                state.help_guide.data = action.payload;
            })
            .addCase(API_Fetch_HelpGuideTopicData.rejected, (state, action) => {
                state.help_guide.api_status = "failed";
                state.help_guide.error_message = action.error.message
            })


            .addCase(API_Fetch_HelpGuideCatgQaData.pending, (state, action) => {
                state.help_guide.api_status = "loading";
            })
            .addCase(API_Fetch_HelpGuideCatgQaData.fulfilled, (state, action) => {
                state.help_guide.api_status = "success"
                state.help_guide.data = {
                    "topicList": action.payload?.topicList,  
                    "categoryList": action.payload?.category,
                    "qAList": action.payload?.qnaList,
                    ...action.meta.arg
                }
            })
            .addCase(API_Fetch_HelpGuideCatgQaData.rejected, (state, action) => {
                state.help_guide.api_status = "failed";
                state.help_guide.error_message = action.error.message
            })

            .addCase(API_Fetch_HelpGuideSearchQNAData.pending, (state, action) => {
                state.help_guide.search_api_status = "loading"
                // state.help_guide.search_api_results = [];
            })
            .addCase(API_Fetch_HelpGuideSearchQNAData.fulfilled, (state, action) => {
                state.help_guide.search_api_status = "success"
                state.help_guide.search_api_results = action.payload.searchResult
                
            })
            .addCase(API_Fetch_HelpGuideSearchQNAData.rejected, (state, action) => {
                state.help_guide.search_api_status = "failed";
                state.help_guide.error_message = action.error.message
            })

            .addCase(API_Fetch_ResourceGuideData.pending, (state, action) => {
                state.resource_guide.api_status = "loading";
            })
            .addCase(API_Fetch_ResourceGuideData.fulfilled, (state, action) => {
                state.resource_guide.api_status = "success"
                state.resource_guide.data = action.payload;
            })
            .addCase(API_Fetch_ResourceGuideData.rejected, (state, action) => {
                state.resource_guide.api_status = "failed";
                state.resource_guide.error_message = action.error.message
            })

            .addCase(API_Fetch_ResourceGuideTopicData.pending, (state, action) => {
                state.resource_guide.api_status = "loading";
            })
            .addCase(API_Fetch_ResourceGuideTopicData.fulfilled, (state, action) => {
                state.resource_guide.api_status = "success"
                state.resource_guide.data = action.payload;
            })
            .addCase(API_Fetch_ResourceGuideTopicData.rejected, (state, action) => {
                state.resource_guide.api_status = "failed";
                state.resource_guide.error_message = action.error.message
            })
            .addCase(API_Fetch_ResourceGuideCatgQaData.pending, (state, action) => {
                state.resource_guide.api_status = "loading";
            })
            .addCase(API_Fetch_ResourceGuideCatgQaData.fulfilled, (state, action) => {
                state.resource_guide.api_status = "success"
                state.resource_guide.data = {
                    "topic": action.payload?.topic,
                    "topicList": action.payload?.topicList,  
                    "categoryList": action.payload?.category,
                    "qAList": action.payload?.qnaList,
                    ...action.meta.arg
                }
            })
            .addCase(API_Fetch_ResourceGuideCatgQaData.rejected, (state, action) => {
                state.resource_guide.api_status = "failed";
                state.resource_guide.error_message = action.error.message
            })
            .addCase(API_Fetch_ResourceGuideSearchQNAData.pending, (state, action) => {
                state.resource_guide.search_api_status = "loading"
                // state.help_guide.search_api_results = [];
            })
            .addCase(API_Fetch_ResourceGuideSearchQNAData.fulfilled, (state, action) => {
                state.resource_guide.search_api_status = "success"
                state.resource_guide.search_api_results = action.payload.searchResult
                
            })
            .addCase(API_Fetch_ResourceGuideSearchQNAData.rejected, (state, action) => {
                state.resource_guide.search_api_status = "failed";
                state.resource_guide.error_message = action.error.message
            })


            .addCase(API_FETCH_PRICING_INFO.pending, (state, action) => {
                state.pricing_info.api_status = "loading";
            })
            .addCase(API_FETCH_PRICING_INFO.fulfilled, (state, action) => {
                state.pricing_info.api_status = "success";
                state.pricing_info.data = action.payload;
                
            })
            .addCase(API_FETCH_PRICING_INFO.rejected, (state, action) => {
                state.pricing_info.api_status = "failed";
                state.pricing_info.message = action.error.message
            })


            .addCase(API_FETCH_PAYMENT_GATEWAY.pending, (state, action) => {
                state.payment_gateway_list.api_status = "loading";
            })
            .addCase(API_FETCH_PAYMENT_GATEWAY.fulfilled, (state, action) => {
                state.payment_gateway_list.api_status = "success";
                state.payment_gateway_list.data = action.payload;
                
            })
            .addCase(API_FETCH_PAYMENT_GATEWAY.rejected, (state, action) => {
                state.payment_gateway_list.api_status = "failed";
                state.payment_gateway_list.message = action.error.message
            })


            .addCase(API_INITIATE_SUBSCRIPTION.pending, (state, action) => {
                state.initiate_subscription.api_status = "loading";
            })
            .addCase(API_INITIATE_SUBSCRIPTION.fulfilled, (state, action) => {
                state.initiate_subscription.api_status = "success";
                state.initiate_subscription.data = action.payload;
                
            })
            .addCase(API_INITIATE_SUBSCRIPTION.rejected, (state, action) => {
                console.log("bad", action.payload)
                state.initiate_subscription.api_status = "failed";
                state.initiate_subscription.message = action.error.message
            })


            .addCase(API_SUBSCRIPTION_LIST.pending, (state, action) => {
                state.subscription_list.api_status = "loading";
            })
            .addCase(API_SUBSCRIPTION_LIST.fulfilled, (state, action) => {
                state.subscription_list.api_status = "success";
                state.subscription_list.data = action.payload;
                
            })
            .addCase(API_SUBSCRIPTION_LIST.rejected, (state, action) => {
                console.log("bad", action.payload)
                state.subscription_list.api_status = "failed";
                state.subscription_list.message = action.error.message
            })

            .addCase(API_CANCEL_SUBSCRIPTION.pending, (state) => {
                state.cancel_subscription.api_status = "loading";
            })
            .addCase(API_CANCEL_SUBSCRIPTION.fulfilled, (state) => {
                state.cancel_subscription.api_status = "success";                
            })
            .addCase(API_CANCEL_SUBSCRIPTION.rejected, (state, action) => {
                state.cancel_subscription.api_status = "failed";
                state.cancel_subscription.message = action.error.message
            })
    }
})
export const { D_ResetCancelSubscriptionApiStatus, D_Reset_Payment_Error_Message, D_Update_WindowLocationHistory, D_Reset_AdUtmParams, D_Reset_ResourceGuide, D_Reset_ResourceGuide_SearchApiStatus, D_Reset_HelpGuide_SearchApiStatus, D_Reset_HelpGuide, D_Reset_WebsiteForm_Status, D_Bpd_Overview_data, D_Toggle_Website_form, D_Bpd_data, D_Reset_bpd_status_message, D_ToggleWarningPopup, D_App_Toggle_ShowErrorNextGeneratePopup, D_Update_Onboarding_Screen_Id, D_Update_BM_SaveButton_Click, D_Reset_BusinessModel_SaveButtonState, updatedAppRefreshStatus, updateLastVisitedRoute, updateBadResponse, forceUserToLogin, discardSuccessApiUrlOnTraceRequest, updatePaymentStatus, DUpdateNetworkStatus } = appSlice.actions
export default appSlice.reducer
