import React from 'react'
import { Button } from "antd";
import eyeIcon from "../../assets/common/eyeIcon.svg"
import TextLarge from '../TypographyDescriptions/TextLarge';

const PrimaryButton = ({ isLoading, onClick, text, isIcon=false, size="small", isDisabled=false, htmlType="button", icon, styles}) => {
  const padding = size === "small" ? "8px 24px" : size === "medium" ? "12px 40px" : "16px 48px";
  const fontSize = size === "small" ? "16px" : size === "medium" ? "16px" : "18px";
  return (
    <React.Fragment>
      <Button
        onClick={onClick}
        disabled={isDisabled}
        type='primary'
        style={{ padding,fontSize,letterSpacing: "0.2px", lineHeight: "140%", ...styles }}
        loading={isLoading}
        htmlType={htmlType}
        icon={isIcon && <img style={{margin:0}} src={icon ? icon : eyeIcon} />}
        className={`${(isDisabled ? 'bg-DisabledFilledBgColor text-DisabledFilledColor' : 'bg-mainPrimary text-p9')} h-auto text-[16px] font-semibold`}
      >
        <TextLarge fontWeight={500} text={text} />
      </Button>
    </React.Fragment>
  )
}

export default PrimaryButton