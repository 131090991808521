import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { calculateTMAPieChart } from '../targetmarketanalysis/calculateTMA';
import { bpdFullSummaryApi, D_Reset_bpd_status_message, D_Bpd_data } from '../../features/appSlice';
import { postNewFeatureProgressApi } from '../maindashboardstepper/stepperProgressSlice';

const useBusinessPlanning = () => {

    const dispatch = useDispatch();
    const [ isProblemModalOpen, setIsProblemModalOpen ] = useState(false);
    const { api_status, data, summary_data_exist, bpd_footer } = useSelector((state) => state.app.business_planning_dashboard)
    const { 
        executiveSummary, 
        productOverview, 
        investmentOpportunity, 
        targetMarketAnalysis, 
        selected_problem, 
        selected_solution,
        problems,
        solutions,
        competitor,
        idealCustomerProfile,
        buildBusinessCanvas,
        gtmPlan,
        channelListCustom,
        channelListDefault
    } = data || {};

    const bpd = useSelector((state) => state.app.business_planning_dashboard)
    useEffect(() => {
        dispatch(bpdFullSummaryApi());
    }, [])

    useEffect(() => {
        if (api_status.status === "failed") dispatch(D_Reset_bpd_status_message())
    }, [api_status.status])

    const handleToggleModal = () => setIsProblemModalOpen((prev) => !prev);
    const handleSelectedProblem = (value) => {
        let optimisedSolution = null;
        const problem = problems.find((p) => p._id === value)?.problemStatement;
        const pSolutions = solutions.filter((s) => s.pid === value);
        if(pSolutions.length>0){
            let largestSum = -1;
            pSolutions.map((sol) => {
                let currSum = 0;
                for(const [key, value] of Object.entries(sol.feasibilityRatings)){
                    currSum = currSum + value;
                }
                if(currSum > largestSum){
                    optimisedSolution = sol.solution;
                    largestSum = currSum;
                }
            })
        }

        dispatch(D_Bpd_data({ "name": "selected_problem", value: problem }))
        dispatch(D_Bpd_data({ "name": "selected_solution", value: optimisedSolution ?? null }))
    }

    const tmaData = calculateTMAPieChart(targetMarketAnalysis?.[0]?.segments, 5); // totalMarketSize, data

    let gtmListMap = {};

    ([...(channelListCustom ?? []), ...(channelListDefault ?? [])] || [])?.map((r) => {
        gtmListMap = {...gtmListMap, [r?._id]: r?.title};
    })

    const list_of_problems_with_optimal_solution = problems?.filter((p) => {
        let isSolutionExist = false;
        solutions?.map((s) => {
            if(s.pid === p._id){
                if(s.feasibilityRatings.Uniqueness>0) isSolutionExist = true;
            }
        })
        return isSolutionExist;
    })?.map((p) => ({ "problem": p.problemStatement, "_id": p._id }))

    useEffect(() => {
        if(
            bpd?.data?.businessDashboardPlanning?.executiveSummary &&
            bpd?.data?.businessDashboardPlanning?.investmentOpportunity && 
            bpd?.data?.businessDashboardPlanning?.productOverview && 
            selected_problem &&
            selected_solution &&
            targetMarketAnalysis?.length>0 &&
            competitor?.filter((c) => c.competitorName.trim().length>0)?.map((c) => (c.competitorName))?.length>0 &&
            idealCustomerProfile?.map((d) => d.workSpaceName)?.filter((d) => d?.trim()?.length>0)?.length>0 && 
            buildBusinessCanvas?.[0]?.Metrics?.["Revenue Streams"]?.length>0 &&
            buildBusinessCanvas?.[0]?.Metrics?.["Value Propositions"]?.length>0 &&
            buildBusinessCanvas?.[0]?.Metrics?.["Key Partnerships"]?.length>0 &&
            gtmPlan?.map((d) => gtmListMap[d.channel])?.filter((r) => r?.length>0)?.slice(0,4)?.length>0 &&
            bpd?.data?.businessDashboardPlanning?.email && 
            bpd?.data?.businessDashboardPlanning?.website && 
            bpd?.data?.businessDashboardPlanning?.phone
        ){
            dispatch(postNewFeatureProgressApi({"status":1,"featureName":"My Business Plan"}));
        }else{
            console.log("false")
        }
    }, [api_status.status, bpd_footer.status, selected_problem, selected_solution])

    return {
        "summary_data_exist": summary_data_exist,
        "api_status": api_status.status,
        "bpd_id": data?.businessDashboardPlanning?._id,
        "overview": { executiveSummary, productOverview, investmentOpportunity },
        "tma": targetMarketAnalysis,
        selected_problem, 
        selected_solution,
        isProblemModalOpen,
        handleToggleModal,
        handleSelectedProblem,
        problems: list_of_problems_with_optimal_solution,
        solutions,
        competitor: competitor?.filter((c) => c.competitorName.trim().length>0)?.map((c) => (c.competitorName)),
        idealCustomerProfile: idealCustomerProfile?.map((d) => d.workSpaceName)?.filter((d) => d?.trim()?.length>0),
        buildBusinessCanvas: { 
            "Revenue Streams": buildBusinessCanvas?.[0]?.Metrics?.["Revenue Streams"]?.slice(0,4) || [], 
            "Value Propositions": buildBusinessCanvas?.[0]?.Metrics?.["Value Propositions"]?.slice(0,4) || [], 
            "Key Partnerships": buildBusinessCanvas?.[0]?.Metrics?.["Key Partnerships"]?.slice(0,4) || [] 
        },
        "totalMarketSize": tmaData?.totalMarketSize,
        "tmaSegments": tmaData?.data,
        gtmPlan: gtmPlan?.map((d) => gtmListMap[d.channel])?.filter((r) => r?.length>0).slice(0,4)
    }

}

export default useBusinessPlanning