import React from 'react'
import { Radio } from 'antd';
import { InputNumber } from '../../../DesignSystem/Forms/FormComponentProvider'
import TextInput from '../../../DesignSystem/Forms/InputVarients/TextInput';
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge';
import AutoSizeTextArea from '../../../DesignSystem/Forms/InputVarients/AutoSizeTextArea';
import NumericInput from '../../../DesignSystem/Forms/InputVarients/NumericInput';
import TextSmall from '../../../DesignSystem/TypographyDescriptions/TextSmall';
import classNames from 'classnames';
import { validator } from '../../../utils/validator';

const ClientInformationForm = ({ form = [], warningFields, storeFormValue, FormValues, isShowWarning }) => {
    return (
        <div className='grid sm:grid-cols-2 grid-cols-1 sm:gap-[24px] gap-[12px]'>
            {
                form.map((formItem, index) => {
                    return (
                        <div
                            className={classNames(
                                { 'sm:col-span-1 col-span-1': formItem.col_span === "col-span-1" },
                                { 'sm:col-span-2 col-span-1': formItem.col_span === "col-span-2" }
                            )}
                            key={index}

                        >
                            <div className='mb-[8px]'>
                                <TextLarge fontWeight={400} text={formItem.label} className={"sm:font-medium font-InterRegular"} />
                                {formItem.is_required ? <sup className='text-infoAlertError1 text-[0.9rem]'>*</sup> : null}
                            </div>
                            {
                                formItem.component_type === "input_number"
                                    ? (
                                        <div className='border py-[0.2rem] rounded-lg pl-[0.5rem] border-[#e8e8e8]'>
                                            <NumericInput
                                                size=''
                                                name={formItem.name}
                                                value={FormValues[formItem.name]}
                                                onChangeEvent={(value) => {
                                                    if (String(value).length < formItem.char_limit) {
                                                        storeFormValue({ name: formItem.name, value: value })
                                                    }
                                                }}
                                                placeholder={formItem.placeholder}
                                                styles={{ fontSize: '1rem' }}
                                            />
                                        </div>
                                    ) : null
                            }
                            {
                                formItem.component_type === "input_text"
                                    ? (
                                        <TextInput
                                            name={formItem.name}
                                            value={FormValues[formItem.name]}
                                            onChangeEvent={(e) => {
                                                if (String(e.target.value).length < formItem.char_limit) {
                                                    storeFormValue({ name: formItem.name, value: e.target.value })
                                                }
                                            }}
                                            placeholder={formItem.placeholder}
                                            className='border-[#ADAFAD]'
                                        />
                                    ) : null
                            }
                            {
                                formItem.component_type === "text_area"
                                    ? (
                                        <AutoSizeTextArea
                                            name={formItem.name}
                                            minRows={5}
                                            defaultValue={FormValues[formItem.name]}
                                            isPaddingNone
                                            maxRows={5}
                                            styles={{ padding: '0.6rem', fontSize: '1rem' }}
                                            onBlurEvent={(e) => {
                                                if (String(e.target.value).length < formItem.char_limit) {
                                                    storeFormValue({ name: formItem.name, value: e.target.value })
                                                }
                                            }}
                                            placeHolder={formItem.placeholder}
                                            className="border border-[#ADAFAD]"
                                        />
                                    ) : null
                            }
                            {
                                formItem.component_type === "checkbox"
                                    ? (
                                        <div className='flex gap-x-[8px]'>
                                            {
                                                formItem.options.map((opt) => {
                                                    return (
                                                        <div
                                                            className={classNames(
                                                                'py-[8px] px-[16px] rounded-full',
                                                                { 'border border-primary bg-p9' : FormValues[formItem.name] === opt.value},
                                                                { 'border border-baseGray700' : FormValues[formItem.name] !== opt.value}
                                                            )}
                                                        >
                                                            <Radio
                                                                checked={FormValues[formItem.name] === opt.value}
                                                                onChange={() => storeFormValue({ name: formItem.name, value: opt.value })}
                                                            >
                                                                {opt.label}
                                                            </Radio>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : null
                            }
                            {
                                formItem.is_required && validator({ validator: formItem.validator, value: FormValues[formItem.name] })===false && isShowWarning
                                    ? <TextSmall color='red' text={warningFields} fontWeight={500} />
                                    : null
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ClientInformationForm
