import React from 'react'
import ListOfTags from './ListOfTags'
import responseLogo from "./assets/responseLogo.svg"
import TextareaAutosize from 'react-textarea-autosize';
import { useSelector } from "react-redux";
import ailocals from "../../localization/localization_en_ai.json"


const TagResponse = ({ index, respLength, loading, tags, data }) => {
    const productName = useSelector((state) => state.prompt.productName);
    let tagDescription;
    if(productName === "competitorAnalysis"){
        tagDescription = ailocals.ai.captions.tag_description.competitor_analysis
    }else if(productName === "idealCustomerProfile"){
        tagDescription = ailocals.ai.captions.tag_description.ideal_customer_profile
    } else if(productName === "buildBusinessCanvas"){
        tagDescription = ailocals.ai.captions.tag_description.build_business_canvas
    }else if(productName === "leanStartupCanvas"){
        tagDescription = ailocals.ai.captions.tag_description.lean_startup_canvas
    } else {
        tagDescription = ailocals.ai.captions.tag_description.default
    }
    return (
        <div key={index}>
            <div className='tags'>
                <p className='text-[#111827] font-InterRegular text-[16px] font-semibold tracking-wide'>{tagDescription}</p>
            </div>
            <ListOfTags loading={loading} tags={tags} respLength={respLength} index={index} />
            { data?.length>0 && <div className="response py-[29px] my-[48px] flex items-start px-[24px] bg-white rounded-[8px]">
                <div className='w-[3rem]'><img className='w-full h-full' src={responseLogo} alt="" /></div>
                <TextareaAutosize
                    disabled={true}
                    value={data}
                    style={{ resize: 'none', outline: 'none' }}
                    className="text-[14px] scrollbar-hide w-full rounded-[8px] p-[8px] border-[#BECEFF] border" />
            </div>}
        </div>
    )
}

export default TagResponse