import React, { useEffect, useState } from 'react'
import infoGuide from "./asset/infoGuide.svg";
import close from "./asset/updatedClose.svg";
import { Tooltip, Input, Button, message } from 'antd';
const { TextArea } = Input;
import { useSelector, useDispatch } from "react-redux";
import { updatelatestOnChangeMetric, updateMetric, updateLeanStartupCanvasApi, addNewMetricValue, deleteMetricValue, updateMetricValue, updateTextIndex, resetbibErr } from './leanstartupcanvasSlice';
import useCheckStatusStepperProgress from '../../utils/checkStatusStepperProgress';
import { postNewFeatureProgressApi } from '../maindashboardstepper/stepperProgressSlice';
import MessageNotification from '../reuseablecomponent/MessageNotification';
import OopsPopUp from '../reuseablecomponent/PopUpVarients/OopsPopUp';
import { postUserActivityDataApi } from '../UserActivity/userActivitySlice';
import locals from "../../localization/localization_en_lean_startup_canvas.json"
import notificationLocals from "../../localization/localization.en_notification_message.json"
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const lsc_locals = locals.lean_startup_canvas;

const DEFAULT_MAX_SEGMENT_WARNING = lsc_locals.warning_message.limit_add_feature;

const CanvasCard = ({ titles, colorPalette }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const permission = useSelector((state) => state.user.permission);
    const updateTextValueIndex = useSelector((state) => state.leanstartupcanvas.updateTextValueIndex)
    const metric = useSelector((state) => state.leanstartupcanvas.Metrics);
    const checkFeatureUsageStatus = useCheckStatusStepperProgress(lsc_locals.module_title);
    const status = useSelector((state) => state.leanstartupcanvas.poststatus);
    const error = useSelector((state) => state.leanstartupcanvas.error);
    const updatedIndexName = useSelector((state) => state.leanstartupcanvas.updatedIndexName)
    const [isPopUpTrue, setIsPopUpTrue]=useState(false);
    const [popupContent, setPopupContent] = useState(null);
    const dispatch = useDispatch();
    const [input, setInput] = useState({
        input0: "",
        input1: "",
        input2: ""
    })
    useEffect(()=>{
        const timer = setTimeout(()=>{
            dispatch(resetbibErr());
        },3000)
        return () => clearTimeout(timer);
    },[error])
    const addNewCanvaText = (metricName, newTextValue, inputState) => {
        console.log(metricName, "metricName", inputState);
        if(!checkFeatureUsageStatus){
            dispatch(postNewFeatureProgressApi({status:1,featureName:lsc_locals.module_title}));   
        }
        dispatch(addNewMetricValue({ key: metricName, value: newTextValue }));
        dispatch(updateLeanStartupCanvasApi(metricName));
        setInput((prev) => ({ ...prev, [inputState[0]]: "" }));
    }
    const togglePopUp = () => {
        setIsPopUpTrue((prev) => !prev)
    }
    return (
        <div className='w-full grid grid-cols-3 xl:gap-x-[1.5rem] gap-x-[0.5rem] xl:px-[1.5rem] px-[1rem] xl:py-[1.5rem] py-[1rem]'>
            {status=="failed" && <MessageNotification status={status} content={error} type={"error"} />}
            <OopsPopUp 
            isOpen={isPopUpTrue} 
            onClick={() => {
                togglePopUp();
                setPopupContent(null);
            }} 
            description1={popupContent ?? DEFAULT_MAX_SEGMENT_WARNING} 
            text={"OK"} textColor={"#FFF"} bgColor={"#372EE5"}  type={"primary"} />
            {
                titles?.map((title, i) => {
                    return (
                        <div className='w-full rounded-[16px]' style={{ height: 'auto' }}>
                            {contextHolder}
                            <div className='bg-white py-[5%] rounded-[16px] px-[5%]'>
                                <div style={{ marginBottom: '0px' }} className='flex items-center'>
                                    <p style={{ marginBottom: '0px' }} className='text-[#111827] font-InterRegular font-medium tracking-wide'>
                                        {title.title}
                                    </p>
                                    <Tooltip color='geekblue' placement={i===0 ? "topLeft" : "top"} title={title.tooltip} className='cursor-pointer'>
                                        <InfoOutlinedIcon className='ml-1' fontSize='' />
                                    </Tooltip>
                                </div>
                                <div className=''>
                                    {
                                        <div className="input_field mt-[16px]">
                                            <div className="input">
                                                <TextArea
                                                    disabled={metric[title.title]?.length>=10 || !permission.edit || status=="loading" || (["Problem", "Solution"]?.includes(title?.title) && metric[title.title]?.length>=3)}
                                                    maxLength={80}
                                                    style={{
                                                        fontSize: "12px",
                                                        borderRadius: "8px",
                                                        paddingLeft: "12px",
                                                        border: "0.5px solid #96ADFF",
                                                    }}
                                                    className='font-InterRegular font-medium tracking-wide'
                                                    placeholder={lsc_locals.form.placeholder}
                                                    value={input[`input${i}`]}
                                                    autoSize={{
                                                        minRows: 1.5,
                                                        maxRows: 4,
                                                    }}
                                                    onChange={(e) => {
                                                        setInput((prev) => ({ ...prev, [`input${i}`]: e.target.value }));
                                                    }}
                                                />
                                            </div>
                                            <Button type='primary' loading={status=="loading" && title.title===updatedIndexName} disabled={!permission.edit || status==="loading"} onClick={() => {
                                                if(["Problem", "Solution"]?.includes(title?.title) && metric[title.title]?.length>=3){
                                                    setIsPopUpTrue((prev) => !prev);  
                                                    if(title.title === "Problem") setPopupContent(lsc_locals.warning_message.limit_add_problem);
                                                    if(title.title === "Solution") setPopupContent(lsc_locals.warning_message.limit_add_solution);
                                                    return;
                                                }
                                                if(metric[title.title]?.length==10){
                                                    setIsPopUpTrue((prev) => !prev);
                                                    return;    
                                                }
                                                if(input[`input${i}`].trim().length>0){
                                                    addNewCanvaText(title.title, input[`input${i}`], [`input${i}`]);
                                                }
                                            }} className='bg-primary text-white mt-[8px]'>{lsc_locals.form.submit_button}</Button>
                                        </div>
                                    }
                                    <ul className="listOfQueries max-h-[35vh] overflow-y-auto" style={{ marginBottom: 0 }}>
                                        {
                                            Array.isArray(metric[title.title])
                                                ? metric[title.title]?.map((value, index) => {
                                                    return <li key={index} className={`bg-[#EBF1FF] rounded-[12px] mt-[16px] border flex justify-between p-[10px]`}>
                                                        {
                                                            updateTextValueIndex?.subIndex === index && updateTextValueIndex.metricName === title.title
                                                                ? <TextArea
                                                                    maxLength={80}
                                                                    disabled={!permission.edit}
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        borderRadius: "8px",
                                                                        paddingLeft: "12px",
                                                                        border: "0.5px solid #96ADFF"
                                                                    }}
                                                                    className='font-InterRegular font-medium tracking-wide text-black'
                                                                    placeholder={lsc_locals.form.placeholder}
                                                                    value={value}
                                                                    onBlur={() => {
                                                                        dispatch(updatelatestOnChangeMetric(title.title));
                                                                        dispatch(updateTextIndex({ metricName: null, subIndex: null }));
                                                                        dispatch(updateLeanStartupCanvasApi(title.title,index));
                                                                        dispatch(postUserActivityDataApi({...notificationLocals.notification_message.lean_startup_canvas.edit}))
                                                                    }}
                                                                    autoSize={{
                                                                        minRows: 1,
                                                                        maxRows: 4,
                                                                    }}
                                                                    onChange={(e) => {
                                                                        const copyMetric = { ...metric };
                                                                        const updatedText = copyMetric[title.title].map((text, index) => {
                                                                            if (index === updateTextValueIndex.subIndex) {
                                                                                return e.target.value;
                                                                            } else {
                                                                                return text;
                                                                            }
                                                                        });
                                                                        dispatch(updateMetricValue({ key: title.title, value: updatedText }));
                                                                    }}
                                                                />
                                                                : <button disabled={!permission.edit || status=="loading"} onClick={() => {
                                                                    dispatch(updateTextIndex({ metricName: title.title, subIndex: index }))
                                                                }} className='text-[12px] break-words w-full inline-block text-start font-InterRegular tracking-wide font-medium text-black' style={{ padding: '0px' }}>{value}</button>
                                                        }
                                                        <button disabled={!permission.delete} onClick={() => {
                                                            dispatch(deleteMetricValue({ index, key: title.title }));
                                                            dispatch(updateLeanStartupCanvasApi(title.title));
                                                            dispatch(postUserActivityDataApi({...notificationLocals.notification_message.lean_startup_canvas.delete}));
                                                        }}><CloseOutlinedIcon fontSize='' /></button>
                                                    </li>
                                                })
                                                : null
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default CanvasCard