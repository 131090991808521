import React, { useState } from 'react'
import OopsPopUp from './OopsPopUp'
import useApp from '../../../hooks/useApp';
import { paywall_locals } from '../../../localization';
import { useNavigate } from 'react-router-dom';

const checkIfValueIsEmptyValue = (value) => {
    if (typeof value === "boolean" && value === true) {
        return true;
    }
    if (typeof value === "number" && value) {
        return true;
    }
    if (typeof value === "string" && value.length > 0) {
        return true;
    }
    if (Array.isArray(value)){
        return value.length>0
    }
    return false;
}

const checkIfObjectHasEmptyData = (object = {}, nestedObjTargetAttrTBC = [], coreNestedObjTargetAttrTBC = []) => {
    for (const [key, value] of Object.entries(object)) {
        if (!["key", "_id", "planId", "currency", "chat_count", "marketingFunnelId"].includes(key)) {
            if (key === "ProductHeading") {
                if (typeof value !== 'object' || value === null) {
                    continue;
                }
            }
            if (typeof value === 'object' && value !== null) {
                for (const [nestedKey, nestedValue] of Object.entries(value)) {
                    if (nestedObjTargetAttrTBC.includes(nestedKey)) {
                        if (typeof nestedValue === "object" && nestedValue !== null) {
                            for (const [ckey, cvalue] of Object.entries(nestedValue)) {
                                if (coreNestedObjTargetAttrTBC.includes(ckey) && checkIfValueIsEmptyValue(cvalue)) {
                                    return true;
                                }
                            }
                        } else if (checkIfValueIsEmptyValue(nestedValue)) {
                            return true;
                        }
                    }
                }
            } else if (checkIfValueIsEmptyValue(value)) {
                return true;
            }
        }
    }
    return false;
}

const WarningLimitedResourceOopsPopup = ({ children, description1, description2, description3, handleOnClick, module_data }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const handleTogglePopup = () => setIsOpen((prev) => !prev);
    const { isFreeSubscription, upgradeRenewPaywallPopup } = useApp();

    let hasEmptyData = false;

    if (!isFreeSubscription) {
        const { module, data = [], hasExampleData=false } = module_data || {};
        if (data.length === 0) {
            hasEmptyData = true;
        } else {
            switch(module){
                case 'psm': {
                    const dataWithNoExample = hasExampleData ? data?.slice(1) : data;
                    if(dataWithNoExample.length==0){
                        hasEmptyData = true;
                    }else{
                        const status = dataWithNoExample.some((row) => {
                            return checkIfObjectHasEmptyData(row, Object.keys(dataWithNoExample[0]).filter((k) => k!=="key"));
                        })
                        hasEmptyData = !status;
                    }
                    break;
                }
                case 'product_ca': {
                    const status = data.some((row) => {
                        return checkIfObjectHasEmptyData(row, ["data"], ["data"])
                    })
                    hasEmptyData = !status;
                    break;
                }
                case 'other_ca': {
                    const status = data.some((row) => {
                        return checkIfObjectHasEmptyData(row, ["data"], ["data"])
                    })
                    hasEmptyData = !status;
                    break;
                }
                case 'bbc_lsc': {
                    hasEmptyData = true;
                    data.map((d) => {
                        for(const [key, value] of Object.entries(d)){
                            if(Array.isArray(value) && value.length>0){
                                hasEmptyData = false;
                            }
                        }
                    })
                }
                case 'gtm': {
                    const status = data.some((row) => {
                        return checkIfObjectHasEmptyData(row, Object?.keys(row), Object?.keys(row?.budget || {}))
                    })
                    hasEmptyData = !status;
                }
            }
        }
    }

    return (
        <>
            <OopsPopUp
                isOpen={isFreeSubscription && isOpen}
                onClick={() => {
                    handleOnClick ? handleOnClick() : handleTogglePopup()
                }}
                controlOkButtonTrigger={() => navigate('/product/home/setting/subscription')}
                description1={description1}
                description2={description2}
                description3={description3}
                buttonText={upgradeRenewPaywallPopup === "upgrade" ? paywall_locals.buttons.upgrade : paywall_locals.buttons.renew}
            />
            <OopsPopUp
                isOpen={!isFreeSubscription && isOpen && hasEmptyData}
                onClick={() => {
                    handleOnClick ? handleOnClick() : handleTogglePopup()
                }}
                controlOkButtonTrigger={() => handleTogglePopup()}
                description1={"Oops! Please fill in all inputs before downloading the data."}
                buttonText={"OK"}
            />
            {
                typeof children === "function"
                    ? children({ isFreeSubscription, upgradeRenewPaywallPopup, handleTogglePopup, "accessDownload": hasEmptyData })
                    : null
            }
        </>
    )
}

export default WarningLimitedResourceOopsPopup