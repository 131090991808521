import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import Header from './components/Header'
import { message } from "antd";
import { RESET_STATE } from "./store";
import { persistor } from './main';
import AntCreateAccount from './pagges/AntCreateAccount'
import AntdOnboarding from './pagges/AntdOnboarding'
import WelcomeScreen from './pagges/WelcomeScreen'
import NewSidebar from './components/Menu/NewSidebar'
import AntVerifyEmail from './pagges/AntVerifyEmail'
import ExploreCoreFeatures from './pagges/CoreFeatureCard'
import { useLocation, useNavigate } from "react-router-dom";
import Protected from './components/AuthProtected/Protected'
import AuthProtected from './components/AuthProtected/AuthProtected'
import ProblemSolutionMatrix from './components/problemSolutionMatrix/ProblemSolutionMatrix'
import { useSelector, useDispatch } from "react-redux";
import { fetchCurrentUserPermission } from './features/userSlice'
import NewLoginPage from './pagges/NewLoginPage'
import RateFeasbilityParameter from './pagges/RateFeasbilityParameter'
import PsmScoreCard from './components/psmScoreCard'
import BusinessIdeationBingo from './components/BusinessIdeationBingo'
import BingoScoreCard from './components/BingoScoreCard'
import TargetMarketAnalysis from './pagges/TargetMarketAnalysis'
import CompetitorAnalysis from './components/compitatoranalysis/CompetitorAnalysis'
import ResetPassword from './pagges/ResetPassword'
import ResetPasswordMainPage from './pagges/ResetPasswordMainPage'
import InviteTeamPage from './pagges/inviteTeamPage'
import RightStepper from './components/maindashboardstepper/RightStepper'
import BuildBusinessCanvas from './components/buildbusinesscanvas/BuildBusinessCanvas'
import StickyNotes from './components/buildbusinesscanvas/StickyNotes'
import ICPDashboard from './components/idealcustomerprofile/ICPDashboard'
import ICPViewFeature from './components/idealcustomerprofile/ICPViewFeature'
import ICPEditFeature from './components/idealcustomerprofile/ICPEditFeature'
import PromptInput from './components/AIService/PromptInput'
import PromptOutput from './components/AIService/PromptOutput'
import Setting from './components/userSetting/Setting'
import AccountSetting from './components/userSetting/AccountSetting'
import UserSetting from './components/userSetting/UserSetting'
import Permission from './components/userSetting/Permission'
import UpdateOnboarding from './components/userSetting/UpdateOnboarding'
import OnboardingCompleteMobile from './pagges/OnboardingCompleteMobile';
import Subscription from './components/userSetting/Subscription'
import StepperTracker from './components/maindashboardstepper/Stepper/StepperTracker'
import StandardDesignComponent from './DesignSystem/StandardDesignComponent'
import PageNotFound from './pagges/PageNotFound'
import LandingPage from './components/PitchDeck/components/LandingPage'
import PreviewSlideShow from './components/PitchDeck/components/PreviewSlideShow'
import SlideShow from './components/PitchDeck/components/SlideShow'
import EditSlideShow from './components/PitchDeck/components/EditSlideShow'
import Test from './Test'
import GlobalSetting from './components/BusinessModal/components/GlobalSetting'
import RevenueOnboarding from './components/BusinessModal/components/RevenueOnboarding'
import SubscriptionStatus from './components/userSetting/SubscriptionStatus'
import CostOnboarding from './components/BusinessModal/components/CostOnboarding'
import BusinessModal from './components/BusinessModal/BusinessModal'
import notificationLocals from './localization/localization.en_notification_message.json';
import FreeTrialExpiredModal from './components/userSetting/subcomponents/FreeTrialExpiredModal';
import { updateLastVisitedRoute, updatedAppRefreshStatus, DUpdateNetworkStatus, D_Reset_HelpGuide, D_Reset_AdUtmParams, D_Update_WindowLocationHistory } from "./features/appSlice";
import { D_Reset_Revenue_Onboarding_State } from './components/BusinessModal/revenueSlice';
import { D_Reset_Cost_Onboarding_State } from './components/BusinessModal/costSlice';
import { D_Reset_WorkingCapital_State } from './components/BusinessModal/workingCapitalSlice'
import { D_Reset_DebtWorking_State } from './components/BusinessModal/debtWorkingSlice'
import { D_Reset_CashFlow_State } from './components/BusinessModal/cashFlowSlice'
import { D_Reset_BalanceSheet_State } from './components/BusinessModal/balanceSheetSlice'
import WorkingCapital from './components/BusinessModal/components/WorkingCapital/WorkingCapital';
import DebtWorking from './components/BusinessModal/components/DebtWorking/DebtWorking';
import CashFlow from './components/BusinessModal/components/CashFlow/CashFlow';
import BalanceSheet from './components/BusinessModal/components/BalanceSheet/BalanceSheet';
import BusinessModelMyDashboard from './components/BusinessModal/BusinessModelMyDashboard';
import BusinessModelMyReport from './components/BusinessModal/BusinessModelMyReport';
import OopsPopUp from './components/reuseablecomponent/PopUpVarients/OopsPopUp';
import appLocals from './localization/localization_en_app.json'
import BusinessPlanningMyDashboard from './components/BusinessPlanning/BusinessPlanningMyDashboard';
import PAndL from './components/BusinessModal/components/P&L/PAndL';
import { D_Reset_P_AND_L_State } from './components/BusinessModal/pAndLSlice'
import GoToMarketPlan from './components/GTM/GoToMarketPlan';
import MarketingFunnel from './components/GTM/MarketingFunnel';
import { ErrorBoundary } from "react-error-boundary";
import CommentFeature from './components/Chat/CommentFeature';
import RouteErrorBoundary from './components/reuseablecomponent/ErrorBoundary/RouteErrorBoundary';
import bm_locals from './localization/localization_en_business_modal.json';
import { D_App_Toggle_ShowErrorNextGeneratePopup } from "./features/appSlice";

import locals from './localization/localization_en_website.json'
import Layout from './website/Layout';
import PGHeader from './website/components/PGHeader';
import PGFooter from './website/components/PGFooter';
import Home from './website/pages/Home';
import WebsiteDS from './website/WebsiteDS';
import Pricing from './website/pages/Pricing';
import MBusinessPlanning from './website/pages/modules/MBusinessPlanning';
import MBusinessModel from './website/pages/modules/MBusinessModel';
import MPitchDeck from './website/pages/modules/MPitchDeck';
import MDataRoom from './website/pages/modules/MDataRoom';
import WhyPredictGrowth from './website/pages/WhyPredictGrowth';
import AboutUs from './website/pages/AboutUs';
import PredictGrowthStories from './website/pages/PredictGrowthStories';
import HelpGuide from './website/help-guide/HelpGuide';
import Blog from './website/pages/Blog';
import IndividualBlog from './website/pages/IndividualBlog';
import useLocationWebOrProduct from './hooks/useLocationWebOrProduct';
import PageLoader from './components/reuseablecomponent/PageLoader';
import HelpGuideCatgListQA from './website/help-guide/HelpGuideCatgListQA';
import ContactUs from './website/pages/ContactUs';
import { API_Add_UTM_Params } from './features/appSlice';
import { paramsMap } from './utils/paramsMap';
import LeanStartupCanvas from './components/leanStartupCanvas/LeanStartupCanvas';
import LSCStickyNotes from './components/leanStartupCanvas/LSCStickyNotes';
import ICPViewFeatureVTwo from './components/idealcustomerprofile/ICPViewFeatureVTwo';
import NSRCELSignup from './pagges/NSRCELSignup';
import LearnConcept from './pagges/learnConcepts/LearnConcept';
import ResourceGuide from './website/resource-guide/ResourceGuide';
import ResourceGuideCatgListQA from './website/resource-guide/ResourceGuideCatgListQA';
import TermsAndCondition from './website/pages/TermsAndCondition';
import PrivacyPolicy from './website/pages/PrivacyPolicy';
import RefundPolicy from './website/pages/RefundPolicy';
import SignUpTemplate from './pagges/components/SignUpTemplate';
import create_account_locals from './localization/localization_en_create_account.json'
import SubscriptionProgressV2 from './components/userSetting/SubscriptionProgressV2';
import UnlockFullAccess from './components/userSetting/subcomponents/UnlockFullAccess';
import { updatePaymentStatus } from './features/appSlice';
import PrePaymentDetail from './components/userSetting/subcomponents/PrePaymentDetail';
import appsumo_pg from "./assets/common/appsumo_pg.jpeg"


const OFFLINE_NETWORK_WARNING_MESSAGE = appLocals.app.business_model.error_message.offline.message;
const SAVE_GENERATE_NEXT_ERROR_MESSAGE = bm_locals.business_modal.warning_tooltip.error_warning_save_generate_next;

const App = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [messageApi, contextHolder] = message.useMessage();
  const user = useSelector((state) => state.user)
  const { is_save_next_generate_error_popup_show, help_guide, location_history } = useSelector((state) => state.app)
  const showPSMCard = useSelector((state) => state.psm.showGenerateScoreCard);
  const collapsedBtnState = useSelector(state => state.link.collapsed);
  const tma = useSelector((state) => state.tma);
  const showChatList = useSelector((state) => state.psm.showConversationList)
  const controlWidth = !(location.pathname.includes('help-guide') || location.pathname.includes('resource-guide')) ? `${currentPath.includes('home') ? (collapsedBtnState ? 'ml-[62px] w-[calc(100%-62px)]' : 'md:ml-[276px] w-[100%] md:w-[calc(100%-276px)]') : 'nothing'}` : 'w-full';
  const planStatus = useSelector((state) => state.user.planStatus)
  const activeItem = useSelector((state) => state.link.activeItem)
  const { active_BusinessModal_Id } = useSelector((state) => state.businessModal)
  const [isShowDeviceRestriction, setIsShowDeviceRestriction] = useState(false);
  const redirectUserToLogin_BadRequest = useSelector((state) => state.app.redirectUserToLogin);
  const checkPaymentStatus = useSelector((state) => state.app.checkPaymentStatus)
  const { activeRoute, lastVisitedRoute, networkStatus } = useSelector((state) => state.app);
  const edit_business_model_navigation_screen_id = useSelector((state) => state.app.edit_business_model_navigation_screen_id);
  const isCommentSectionVisible = useSelector((state) => state.chat.isCommentSectionVisible);
  const [isPageRefreshed, setIsPageRefreshed] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const IS_INSIDE_HOME_PRODUCT = useLocationWebOrProduct();
  const windowParams = window.location.pathname;
  // React.useEffect(() => {
  //     if(help_guide.api_status === "success"){
  //       navigate('/product/home/help-guide')
  //       dispatch(D_Reset_HelpGuide({ "api_status": "idle" }))
  //     }
  //     if(help_guide.api_status === "failed"){
  //         message.error(help_guide.error_message);
  //         dispatch(D_Reset_HelpGuide({ "api_status": "idle", "data": null, "error_message": null }))
  //     }
  // }, [help_guide.api_status])
  React.useEffect(() => {
    const {
      url,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term,
      gclid
    } = paramsMap() || {};

    if(utm_source || utm_medium || utm_campaign || utm_content || utm_term || gclid){
      console.log("setting utm params");
      dispatch(D_Reset_AdUtmParams({ utm_source, utm_medium, utm_campaign, utm_content, utm_term, gclid }))
    }else{
      console.log("no utm params");
      if(!utm_source && !utm_medium && !utm_campaign && !utm_content && !utm_term && !gclid){
        if(location_history[location_history.length-1]?.includes(window.location.origin)){
          console.log("same website - ignore")
        }else{
          console.log("reset utm params")
          dispatch(D_Reset_AdUtmParams({ utm_source: '', utm_medium: '', utm_campaign: '', utm_content: '', utm_term:'', gclid: '' }))
        }
      }
    }

    if(window.location.href !== location_history[location_history.length-1]){
      dispatch(D_Update_WindowLocationHistory(window.location.href))
    }
  }, [location?.pathname])
  React.useEffect(() => {
    message.config({
      top: 180,
      duration: 1.5,
      maxCount: 3,
      ltr: true,
      // getContainer: () => document.body,
    });
  }, [])
  React.useEffect(() => {
    if (!redirectUserToLogin_BadRequest) return;
    messageApi.warning(notificationLocals.notification_message.bad_request, 3).then(async () => {
      dispatch({ type: RESET_STATE });
      await persistor.purge();
      navigate("/");
    });
  }, [redirectUserToLogin_BadRequest]);
  React.useEffect(() => {
    const handleResize = () => {
      setIsShowDeviceRestriction(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])
  React.useEffect(() => {
    Boolean(user.value?.id?.length) && dispatch(fetchCurrentUserPermission())
  }, [activeItem])
  React.useEffect(() => {
    // if(planStatus === false && user.value.id){
    //   navigate('/product/home/setting/subscription')
    // }
  })
  const handleOnlineStatusChange = () => dispatch(DUpdateNetworkStatus(navigator.onLine));
  React.useEffect(() => {
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, [dispatch])
  React.useEffect(() => {
    const previousRoute = activeRoute;
    const presentRoute = location.pathname;
    window.dataLayer.push({
      event: `page_view_url_${presentRoute}`
  });
    setIsPageRefreshed((prev) => prev + 1);
    dispatch(updatedAppRefreshStatus(isPageRefreshed));
    if (isPageRefreshed === 0) return;
    dispatch(updateLastVisitedRoute(location.pathname));
    const presentParamsUrl = windowParams?.trim();
    const list_Of_BM_Setting_Routes = ['/product/home/business-modal/setting', '/product/home/settings']
    const list_Of_BM_Feature_Routes = [
      '/product/home/business-modal/revenue-onboarding',
      `/product/home/business-modal/edit/${active_BusinessModal_Id}/revenue-screen`,
      '/product/home/business-modal/cost-onboarding',
      `/product/home/business-modal/edit/${active_BusinessModal_Id}/cost-screen`,
      '/product/home/business-modal/working-capital',
      `/product/home/business-modal/edit/${active_BusinessModal_Id}/working-capital`,
      '/product/home/business-modal/debt-working',
      `/product/home/business-modal/edit/${active_BusinessModal_Id}/debt-working`,
      '/product/home/business-modal/cash-flow',
      `/product/home/business-modal/edit/${active_BusinessModal_Id}/cash-flow`,
      '/product/home/business-modal/balance-sheet',
      `/product/home/business-modal/edit/${active_BusinessModal_Id}/balance-sheet`,
      '/product/home/business-modal/p&l',
      `/product/home/business-modal/edit/${active_BusinessModal_Id}/p&l`
    ];
    if (presentRoute === '/product/home/business-modal') { // user navigates to bm
      if (presentRoute === '/product/home/business-modal' && previousRoute === '/product/home/business-modal') {
        return;
      }
      // if([...list_Of_BM_Setting_Routes,...list_Of_BM_Feature_Routes].includes(previousRoute)===false) return ; // don'nt reset onboarding when previous routes is not part of bm.
      console.log("reset state");
      dispatch(D_Reset_Revenue_Onboarding_State("full"))
      dispatch(D_Reset_Cost_Onboarding_State())
      dispatch(D_Reset_WorkingCapital_State())
      dispatch(D_Reset_DebtWorking_State())
      dispatch(D_Reset_CashFlow_State())
      dispatch(D_Reset_BalanceSheet_State())
      dispatch(D_Reset_P_AND_L_State())
    } else if (list_Of_BM_Setting_Routes.includes(presentRoute)) { // don't reset state when user navigates to gs
      return;
    } else if (![...list_Of_BM_Setting_Routes, ...list_Of_BM_Feature_Routes].includes(presentRoute)) { // reset state when user navigates to routes, excluding bm
      dispatch(D_Reset_Revenue_Onboarding_State("full"))
      dispatch(D_Reset_Cost_Onboarding_State())
      dispatch(D_Reset_WorkingCapital_State())
      dispatch(D_Reset_DebtWorking_State())
      dispatch(D_Reset_CashFlow_State())
      dispatch(D_Reset_BalanceSheet_State())
      dispatch(D_Reset_P_AND_L_State())
    } else if (list_Of_BM_Feature_Routes.includes(presentRoute) && list_Of_BM_Feature_Routes.includes(previousRoute)) { // dont reset state when user navigates between onboarding in bm.
      if (
        (presentRoute === '/product/home/business-modal/cost-onboarding' && previousRoute === '/product/home/business-modal/revenue-onboarding') ||
        (presentRoute === `/product/home/business-modal/edit/${active_BusinessModal_Id}/cost-screen}` && previousRoute === `/product/home/business-modal/edit/${active_BusinessModal_Id}/revenue-screen}`)
      ) {
        dispatch(D_Reset_Revenue_Onboarding_State("half"))
      } else if (
        (presentRoute === '/product/home/business-modal/debt-working' && previousRoute === '/product/home/business-modal/cost-onboarding') ||
        (presentRoute === `/product/home/business-modal/edit/${active_BusinessModal_Id}/debt-working` && previousRoute === `/product/home/business-modal/edit/${active_BusinessModal_Id}/cost-screen}`)
      ) {
        dispatch(D_Reset_Cost_Onboarding_State())
      }
    }
  }, [location.pathname])
  if (isShowDeviceRestriction && (IS_INSIDE_HOME_PRODUCT || location.pathname === '/product/welcome')){
    return <OnboardingCompleteMobile
      type={IS_INSIDE_HOME_PRODUCT ? "productview" : "authview"}
    />
  }
  let renderTargetBusinessModelOnboarding;
  const link = windowParams.split('/').filter((subLink) => subLink);
  if (link.length === 5) {
    if (link[4] === 'revenue-screen') {
      renderTargetBusinessModelOnboarding = <RevenueOnboarding />
    } else if (link[4] === 'cost-screen') {
      renderTargetBusinessModelOnboarding = <CostOnboarding />
    } else if (link[4] === 'working-capital') {
      renderTargetBusinessModelOnboarding = <WorkingCapital />
    } else if (link[4] === 'debt-working') {
      renderTargetBusinessModelOnboarding = <DebtWorking />
    } else if (link[4] === 'cash-flow') {
      renderTargetBusinessModelOnboarding = <CashFlow />
    } else if (link[4] === 'balance-sheet') {
      renderTargetBusinessModelOnboarding = <BalanceSheet />
    } else if (link[4] === 'p&l') {
      renderTargetBusinessModelOnboarding = <PAndL />
    }
  }
  return (
    <div className={`${(currentPath.includes('home') || (currentPath.startsWith('/product/signup/') && !['/product/signup/', '/product/signup/appsumo'].includes(currentPath))) ? 'relative' : 'mx-auto'} ${(!['/product/home/subscription/pay', '/product/home/setting/subscription'].includes(currentPath)) ? '2xl:w-[1440px]' : ''} min-h-[100vh] flex flex-col`}>
      {contextHolder}
      {help_guide.api_status === "loading" ? <PageLoader/> : null}
      {currentPath.includes('home') | currentPath.includes('editslide') ? <Header /> : null}
      {showPSMCard && <PsmScoreCard />}
      { checkPaymentStatus?.isWarningPopupOpen ? <UnlockFullAccess onClick={() => dispatch(updatePaymentStatus({ "isWarningPopupOpen": false }))} /> : null }
      {/* {(checkPaymentStatus === false && currentPath !== "/product/home/setting/subscription" && currentPath !== '/') ? <FreeTrialExpiredModal /> : null} */}
      {networkStatus === false ? <OopsPopUp isOpen={networkStatus === false} description1={OFFLINE_NETWORK_WARNING_MESSAGE.description1} description2={OFFLINE_NETWORK_WARNING_MESSAGE.description2} /> : null}
      {is_save_next_generate_error_popup_show 
      ? <OopsPopUp 
          isOpen={is_save_next_generate_error_popup_show}
          onClick={() => dispatch(D_App_Toggle_ShowErrorNextGeneratePopup())} 
          description1={SAVE_GENERATE_NEXT_ERROR_MESSAGE.description1} 
          description2={SAVE_GENERATE_NEXT_ERROR_MESSAGE.description2} 
          description3={SAVE_GENERATE_NEXT_ERROR_MESSAGE.description3} 
        /> 
        : null
      }
      <div className='sidebar_content_layout'>
        <div className={(IS_INSIDE_HOME_PRODUCT && !location.pathname.includes('help-guide') && !location.pathname.includes('resource-guide')) ? 'block' : 'hidden'}>
          <NewSidebar />
        </div>
        <div className={`${currentPath.includes('home') ? 'flex px-[26px]' : null}`}>
          <div className={`${controlWidth} ${currentPath.includes('problemsolutionmatrix') || currentPath.includes('buildbusinesscanvas') || currentPath.includes('competitor-analysis') ? 'overflow-hidden' : null}`}>
            <Routes>
              <Route path='/' element={<Layout/>}>
                <Route index element={<Home/>} />
                <Route path='pricing' element={<Pricing/>} />
                <Route path='buy' element={<Pricing/>} />
                <Route path='module/business-planning' element={<MBusinessPlanning/>} />
                <Route path='module/financial-modelling' element={<MBusinessModel/>} />
                <Route path='module/pitch-deck' element={<MPitchDeck/>} />
                <Route path='module/data-room' element={<MDataRoom/>} />
                <Route path='why-predict-growth' element={<WhyPredictGrowth/>} />
                <Route path='stories' element={<PredictGrowthStories/>} />
                <Route path='blogs' element={<Blog/>} />
                <Route path='blogs/*' element={<IndividualBlog/>} />
                <Route path='about-us' element={<AboutUs/>} />
                <Route path='help-guide' element={<HelpGuide/>} />
                <Route path='contact-us' element={<ContactUs/>} />
                <Route path='*' element={<PageNotFound/>} />
              </Route>
              <Route path='/refund-policy' element={<RefundPolicy/>} />
              <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
              <Route path='/terms-and-conditions' element={<TermsAndCondition/>} />
              <Route path='/product' element={<AuthProtected component={"login"}><NewLoginPage /></AuthProtected>} />
              <Route path='/product/home/subscription/pay' element={<Protected><PrePaymentDetail/></Protected>} />
              <Route path='/product/signup' element={<AuthProtected component={"createAccount"}><AntCreateAccount /></AuthProtected>} />
              <Route path='/product/signup/teaser' element={<AuthProtected component={"createAccount"}><AntCreateAccount user_type="DEMO" /></AuthProtected>} />
              <Route path='/product/signup/nsrcel' element={<AuthProtected component={"createAccount"}><SignUpTemplate locals={create_account_locals.nsrcel_signup} /></AuthProtected>} />
              <Route path='/product/signup/appsumo' element={
                <AuthProtected component={"createAccount"}>
                  <SignUpTemplate 
                  locals={create_account_locals.appsumo_signup} 
                  isDefaultTemplate
                  className='md:flex-row block md:flex'
                  partnership_icon={appsumo_pg}
                  logowidth={320}
                  />
                </AuthProtected>}
              />
              <Route path='/product/signup/catalystaic' element={<AuthProtected component={"createAccount"}><SignUpTemplate locals={create_account_locals.catalystaic_signup} /></AuthProtected>} />
              <Route path='/product/verifyemail/*' element={<AuthProtected component="verifyemail"><AntVerifyEmail /></AuthProtected>} />
              <Route path='/product/resetpassword' element={<AuthProtected component="resetPassword"><ResetPasswordMainPage /></AuthProtected>} />
              <Route path='/product/resetpassword/*' element={<AuthProtected component="newresetpassword"><ResetPassword /></AuthProtected>} />
              <Route path='/product/new/username' element={<Protected><InviteTeamPage /></Protected>} />
              <Route path='/product/onboarding' element={<Protected component="onboarding"><AntdOnboarding /></Protected>} />
              <Route path='/product/onboarding/complete' element={<OnboardingCompleteMobile />} />
              <Route path='/product/welcome' element={<Protected><WelcomeScreen /></Protected>} />
              <Route path='/product/home' element={<Protected><ExploreCoreFeatures /></Protected>}></Route>
              <Route path='/product/home/businessideationbingo' element={<Protected><BusinessIdeationBingo /></Protected>} />
              <Route path='/product/home/businessideationbingo/scorecard' element={<Protected><BingoScoreCard /></Protected>} />
              <Route path='/product/home/problemsolutionmatrix' element={<Protected><ProblemSolutionMatrix /></Protected>} />
              <Route path='/product/home/problemsolutionmatrix/ratefeasbilityparameter' element={<Protected><RateFeasbilityParameter /></Protected>} />
              <Route path='/product/home/targetmarketanalysis' element={<Protected><TargetMarketAnalysis /></Protected>} />
              <Route path='/product/home/business-canvas/build-market-analysis/competitor-analysis' element={<Protected><CompetitorAnalysis /></Protected>}/>
              <Route path='/product/home/businesscanvas/buildbusinesscanvas' element={<Protected><BuildBusinessCanvas /></Protected>} />
              <Route path='/product/home/businesscanvas/buildbusinesscanvas/viewnotes' element={<Protected><StickyNotes /></Protected>} />
              <Route path='/product/home/businesscanvas/leanstartupcanvas' element={<Protected><LeanStartupCanvas /></Protected>} />
              <Route path='/product/home/businesscanvas/leanstartupcanvas/viewnotes' element={<Protected><LSCStickyNotes /></Protected>} />
              <Route path='/product/home/buildmarketanalysis/idealcustomerprofile' element={<Protected><ICPDashboard /></Protected>} />
              <Route path='/product/home/buildmarketanalysis/idealcustomerprofile/view' element={<Protected>{<ICPViewFeatureVTwo />}</Protected>} />
              <Route path='/product/home/buildmarketanalysis/idealcustomerprofile/edit' element={<Protected><ICPEditFeature /></Protected>} />
              <Route path='/product/home/ai/promptin/:productname' element={<Protected><PromptInput /></Protected>} />
              <Route path='/product/home/ai/promptout/:productname' element={<Protected><PromptOutput /></Protected>} />
              <Route path='/product/home/settings' element={<Protected><Setting /></Protected>}></Route>
              <Route path='/product/home/setting/subscription' element={<Protected component={"subscriptionExpiry"} ><SubscriptionProgressV2/></Protected>}></Route>
              {/* <Route path='/product/home/setting/subscription' element={<Protected component={"subscriptionExpiry"} ><Subscription/></Protected>}></Route> */}
              <Route path='/product/home/steppertracker' element={<Protected><StepperTracker /></Protected>} />
              <Route path='/product/design' element={<StandardDesignComponent/>} />
              <Route path='/product/home/pitchdeck' element={<Protected><LandingPage /></Protected>} />
              <Route path='/product/home/pitchdeck/preview' element={<PreviewSlideShow/>} />
              <Route path='/product/home/pitchdeck/slideshow' element={<SlideShow/>}/>
              <Route path='/product/pitchdeck/slideshow/editslide' element={<EditSlideShow/>}/>
              <Route path='/product/test' element={<Test/>}/>
              <Route path='/product/home/business-modal' element={<Protected><BusinessModal/></Protected>}/>
              <Route path='/product/home/business-modal/edit/:id/:onboarding_type' element={<Protected>{renderTargetBusinessModelOnboarding}</Protected>}/>
              {/* <Route path='/product/home/business-modal/edit/:id/:onboarding_type/:screen_number' element={<Protected>{renderTargetBusinessModelOnboarding}</Protected>}/> */}
              <Route path='/product/home/business-modal/my-dashboard' element={<Protected><BusinessModelMyDashboard/></Protected>}/>
              <Route path='/product/home/business-planning/my-dashboard' element={<Protected><BusinessPlanningMyDashboard/></Protected>}/>
              <Route path='/product/home/business-modal/my-report' element={<Protected><BusinessModelMyReport/></Protected>}/>
              <Route path='/product/home/business-modal/my-report/view' element={<Protected><BusinessModelMyReport/></Protected>}/>
              <Route path='/product/home/business-modal/my-report/download' element={<Protected><BusinessModelMyReport/></Protected>}/>
              <Route path='/product/home/business-modal/setting' element={<Protected><GlobalSetting/></Protected>}/>
              <Route path='/product/home/business-modal/revenue-onboarding' element={<Protected><RevenueOnboarding/></Protected>}/>
              <Route path='/product/home/business-modal/cost-onboarding' element={<Protected><CostOnboarding/></Protected>}/>
              <Route path='/product/home/business-modal/working-capital' element={<Protected><WorkingCapital/></Protected>}/>
              <Route path='/product/home/business-modal/debt-working' element={<Protected><DebtWorking/></Protected>}/>
              <Route path='/product/home/business-modal/cash-flow' element={<Protected><CashFlow/></Protected>}/>
              <Route path='/product/home/business-modal/balance-sheet' element={<Protected><BalanceSheet/></Protected>}/>
              <Route path='/product/home/business-modal/p&l' element={<Protected><PAndL/></Protected>} />
              <Route path='/product/home/go-to-market-strategy/gtm-plan' element={<Protected><GoToMarketPlan /></Protected>} />
              <Route path='/product/home/go-to-market-strategy/marketing-funnel' element={<Protected><MarketingFunnel /></Protected>} />
              <Route path='/product/home/help-guide' element={<Protected><HelpGuide/></Protected>}/>
              <Route path='/product/home/help-guide/:topicid/:catgid' element={<Protected><HelpGuideCatgListQA/></Protected>} />
              <Route path='/product/home/help-guide/:topicid/:catgid/:qaid' element={<Protected><HelpGuideCatgListQA/></Protected>} />
              <Route path='/product/home/resource-guide/:topicname' element={<Protected><ResourceGuide/></Protected>}/>
              <Route path='/product/home/resource-guide/:topicid/:catgid' element={<Protected><ResourceGuideCatgListQA/></Protected>} />
              <Route path='/product/home/resource-guide/:topicid/:catgid/:qaid' element={<Protected><ResourceGuideCatgListQA/></Protected>} />
              {/* <Route path='/test' element={<Protected><LearnConcept/></Protected>}/> */}
              <Route path='*' element={<PageNotFound/>} />
            </Routes>
          </div>
          {
            isCommentSectionVisible
              ? (
                <div className='fixed top-0 right-0 z-[100] h-screen bg-white'>
                  <CommentFeature />
                </div>
              ) : null
          }
          {/* {
            showChatList && (
              <div className={`${(currentPath.includes('home') && !currentPath.includes('settings')) ? 'h-screen' : 'hidden'}`}>
                <div className='overflow-y-auto scrollbar-hide'>
                  <Protected><RightStepper /></Protected>
                </div>
              </div>
            )
          } */}
        </div>
      </div>
    </div>
  )
}

export default App
