import React from 'react'
import { Button } from "antd";

const IconButton = ({ isLoading, isDisabled, onClick, shape="circle", btnBgColor = "#372EE5", icon, isAnimation, animationName, onMouseHover, onMouseLeave }) => {
    const styles = {
        backgroundColor: btnBgColor,
    }
    return (
        <React.Fragment>
            <Button
                onClick={onClick}
                onMouseEnter={onMouseHover}
                onMouseLeave={onMouseLeave}
                disabled={isDisabled}
                type='primary'
                shape={shape}
                style={styles}
                loading={isLoading}
                icon={<img className={`${isAnimation ? animationName : ''} w-[100%] mx-auto`} src={icon} />}
                className={`text-p9 flex justify-center shadow-lg items-center aspect-square py-[8px] cursor-pointer text-[16px] icon-button`}
            />
        </React.Fragment>
    )
}

export default IconButton