import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { handlePushAndRemoveTags } from './promptSlice';
import closeactive from "./assets/closeactive.svg"

const ListOfTags = ({ tags, loading, respLength, index }) => {
    const prompts = useSelector((state) => state.prompt.prompts);
    const dispatch = useDispatch();
    return (
        <div>
            <ul className='flex flex-wrap bg-white rounded-[8px] px-[24px] py-[29px]'>
                {
                    prompts.map((prompt, i) => {
                        return (
                            <li
                                key={i}
                                className={`flex ${tags.includes(prompt) ? 'border-primary' : 'border-[#616161] text-[#616161]'} border flex items-center rounded-[16px] mr-[8px] mb-[8px] text-[16px] font-InterRegular tracking-wide font-medium px-[16px] py-[5px]`}>
                                <button disabled={loading==="loading" || !(respLength===index)} onClick={() => {
                                    dispatch(handlePushAndRemoveTags({ type: "ADD", tag: prompt.subtitle }))
                                }} className={`${tags.includes(prompt.subtitle) && 'text-primary'}`}>{prompt.subtitle}</button>
                                {tags.includes(prompt.subtitle) ? <button disabled={loading==="loading"}><img className='ml-[8px]' src={closeactive} onClick={() => dispatch(handlePushAndRemoveTags({ type: "Remove", tag: prompt.subtitle }))} alt="" /></button> : null}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default ListOfTags