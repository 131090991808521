export const convertAmountInK = (amount, preUnit = "", postUnit = "") => {
    if (!!amount) {
        let amountInString = String(amount);
        let [integerPart, fractionalPart] = amountInString.split('.');

        if (String(integerPart).length > 4) {
            integerPart = String(integerPart).slice(0, 4);
        }
        const result = fractionalPart ? `${integerPart}.${fractionalPart}` : integerPart;

        return `${preUnit} ${result}${String(amountInString.split('.')[0]).length>4 ? 'k' : ''} ${postUnit}`;
    }
}