import React from 'react'
import TextMedium from '../../DesignSystem/TypographyDescriptions/TextMedium'
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge'

const ResponsiveTextLargeMedium = ({ text, color="#616161", fontWeight=400 }) => {
    return (
        <>
           <TextLarge  isDisplay={false} text={text} fontWeight={fontWeight} className={"sm:block hidden"} color={color} /> 
           <TextMedium isDisplay={false} text={text} fontWeight={fontWeight} className={"sm:hidden block"} color={color} /> 
        </>
    )
}

export const FreeTrialFAQ = ({ content }) => {
    return (
        <div className='flex flex-col gap-y-[1.5rem]'>
            <ResponsiveTextLargeMedium text={content.description1} />
            <ResponsiveTextLargeMedium text={content.description2} />
            <div className='flex flex-col'>
                <ResponsiveTextLargeMedium text={content.description3.title} />
                <ul className='list-disc pl-[1rem]'>
                    <li><ResponsiveTextLargeMedium text={content.description3.description1} /></li>
                    <li><ResponsiveTextLargeMedium text={content.description3.description2} /></li>
                    <li>
                        <ResponsiveTextLargeMedium text={content.description3.description3} />
                        <a href={content.href} className='underline cursor-pointer'><ResponsiveTextLargeMedium fontWeight={700} text={content.description3.description4} color='#372EE5' /></a>
                    </li>
                    <li><ResponsiveTextLargeMedium text={content.description3.description5} /></li>
                </ul>
            </div>
            <ResponsiveTextLargeMedium text={content.description4} />
        </div>
    )
}


export const PricingWorkFAQ = ({ content }) => {
    return (
        <div className='flex flex-col gap-y-[1.5rem]'>
            <ResponsiveTextLargeMedium text={content.description1} />
            <ResponsiveTextLargeMedium text={content.description2} />
        </div>
    )
}


export const SubscriptionLimitFAQ = ({ content }) => {
    return (
        <div className='flex flex-col gap-y-[1.5rem]'>
            <ResponsiveTextLargeMedium text={content.description1} />
            <ResponsiveTextLargeMedium text={content.description2} />
        </div>
    )
}


export const AccountDeactivation = ({ content }) => {
    return (
        <div className='flex flex-col gap-y-[1.5rem]'>
            <ResponsiveTextLargeMedium text={content.description1}/>
            <ul className='list-disc pl-[1.5rem]'>
                    <li><ResponsiveTextLargeMedium text={content.description2.description1} /></li>
                    <li><ResponsiveTextLargeMedium text={content.description2.description2} /></li>
                    <li><ResponsiveTextLargeMedium text={content.description2.description3} /></li>
                </ul>
        </div>
    )
}