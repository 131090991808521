import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { updateMenuItemSelection, updateCollapsedSideBar, updateItemSelection } from "../../features/manageLinkSlice";
import { filterLatestElements } from '../../utils/sidebarKeyFilter';
import Home from '../../assets/Home/HomeNew.svg';
import { exploreCoreFeature } from '../../data';
import { useNavigate } from 'react-router-dom';
import validateBusinessIdeaWhite from '../../assets/menu/validateBusIdeaIcon.svg'
import buildBusinessCanvasWhite from '../../assets/menu/bbc.svg'
import GTMPlannerWhite from '../../assets/menu/gtmIcon.svg'
import buildMarketAnalysisWhite from '../../assets/menu/bmaIcon.svg'
import businesscanvas from "../../assets/common/businessPlanning.svg"
import businessmodel from "../../assets/menu/businessModal.svg"
import pitchDeck from "../../assets/menu/pitchDeckIcon.svg"
import dataRoom from "../../assets/menu/dataRoomIcon.svg"
import '../../index.css'
import { userInfo } from '../../features/userSlice';
import menuLocation from "../../assets/common/menuLocation.svg";
import whiteMenu from "../../assets/common/whiteMenu.svg"
import PGLogoPrimary from "../../assets/common/PGLogoPrimary.svg";
import PGLogoSecondary from "../../assets/common/PGLogoSecondary.svg";
import userSkeleton from '../../assets/Home/userSkeleton.svg';
import locationWithFilled from "../../assets/common/locationWithFilled.svg"
import HomeNotFilled from "../../assets/menu/HomeIcon.svg"
import psm from "../../assets/menu/PSMIcon.svg"
import tma from "../../assets/menu/tmaIcon.svg"
import icp from "../../assets/menu/icpIcon.svg"
import bib from "../../assets/menu/bibIcon.svg"
import ca from "../../assets/menu/caIcon.svg"
import OldMenu from './OldMenu';
import report from "../../assets/common/report.svg"
import application from "../../assets/common/application.svg"
import myBusinessModel from "../../assets/common/myBusinessModel.svg"
import menuLocals from "../../localization/localization_en_menu.json"
import LocationPointerAnimation from "../../DesignSystem/animations/location/LocationPointerAnimation"
import subscriptionCard from "../../assets/Header/subscriptionCardWeb.png"
import assignment from "../../assets/menu/assignment.svg"
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import useLockedFeature from '../../hooks/useLockedFeature';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import useApp from '../../hooks/useApp';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { paywall_locals } from '../../localization';

function getItem(label, key, icon, children, type) {
  return { key, icon, children, label, type };
}

const NewSidebar = () => {
  const { isAdmin, isPaymentMadeOnce, isFreeSubscription } = useApp();
  const linkInfo = useSelector(state => state.link);
  const avatar = useSelector((state) => state.user.value.avatar);
  const [test, setTest] = useState(false);
  const user = useSelector(userInfo);
  const { isPaymentActive, handleRestrictAccessModuleDuePayment } = useApp();
  const { planStatus: ps } = useSelector((state) => state.user.subscriptionDetails) || {};
  const planStatus = ps === "expired" ? 
                    isPaymentMadeOnce ? "Renew Now" : "Upgrade Plan"
                    :ps === "invited" ? "Invited" : "Startup Plan"
  const checkPaymentStatus = useSelector((state) => state.app.checkPaymentStatus)

  // const testMenuItems = [
  //   getItem('Test Menu Items', 'test', null, [
  //     getItem('edit', 'edit', null),
  //     getItem('insert', 'insert', null),
  //     getItem('create', 'create', null),
  //     getItem('update', 'update', null),
  //   ])
  // ] //dynamic menu item for pitch deck
  const localization = menuLocals.menu;
  const navigateFreshHomeCards = () => {
    dispatch(updateMenuItemSelection({ openKeys: ["0"], selectedKeys: ["0"] }));
    dispatch(updateItemSelection({ activeItem: "0" }));
    navigate('/product/home');
  }

  let ICON  = null;

  if(isFreeSubscription){
    ICON = RocketLaunchIcon
  }else{
    ICON = CreditCardIcon
  }


  const subMenuItems = [
    getItem('', 'predictgrowth', linkInfo.collapsed ? <a onClick={navigateFreshHomeCards}><img src={PGLogoSecondary} className="" alt="PredictGrowth" /></a> : <a onClick={navigateFreshHomeCards}><img src={PGLogoPrimary} className="" alt="PredictGrowth" /></a>),
    getItem(<div>{user.username === "" ? "User" : user?.username?.slice(0, 1).toUpperCase() + user?.username?.slice(1)}</div>, 'profile', <img className='w-[24px] h-[24px]' src={avatar?.length == 0 ? userSkeleton : avatar} alt="" />),
    getItem(<div>{planStatus}</div>, 'profile-plan', <ICON style={{ fontSize: "1.5rem" }} />),
    getItem(<div>{<div className="name_email"><p style={{ marginBottom: "0px" }} className='text-white text-[14px] tracking-wide font-InterRegular'>{localization.location}</p></div>}</div>, 'location', <div className='w-[1.5rem]'><LocationPointerAnimation color="#fff" /></div>),
    getItem(localization.menu, 'menu', <img src={whiteMenu} />),
  ]

  const businessCanvasData = [
    getItem(localization['1.1'], '1.1', <img className='w-[24px]' src={validateBusinessIdeaWhite} />, [
      getItem(localization['1.11'], '1.11', <img className='w-[24px]' src={psm} />),
      getItem(localization['1.12'], '1.12', <img className='w-[24px]' src={bib} />),
    ]),
    getItem(localization['1.2'], '1.2', <img className='w-[24px]' src={buildMarketAnalysisWhite} />, [
      getItem(localization['1.21'], '1.21', <img className='w-[24px]' src={tma} />),
      getItem(localization['1.22'], '1.22', <img className='w-[24px]' src={ca} />),
      getItem(localization['1.23'], '1.23', <img className='w-[24px]' src={icp} />),
    ]),
    getItem(localization['1.3'], '1.3', <img className='w-[24px]' src={buildBusinessCanvasWhite} />, [
      getItem(localization['1.31'], '1.31', <WidgetsIcon style={{ fontSize: "1.5rem" }} />),
      getItem(localization['1.32'], '1.32', <ViewQuiltIcon style={{ fontSize: "1.5rem" }} />),
    ]),
    getItem(localization['1.4'], '1.4', <img className='w-[24px]' src={GTMPlannerWhite} />, [
      getItem(localization['1.41'], '1.41', <img className='w-[24px]' src={psm} />),
      getItem(localization['1.42'], '1.42', <img className='w-[24px]' src={bib} />),
    ]),
    getItem(localization['1.5'], '1.5', <img src={assignment} alt="" />,)
  ]
  const items = [
    ...subMenuItems,
    getItem(localization[0], '0', <img className='w-[24px]' src={HomeNotFilled} />, [
      getItem(localization[1], '1', <img className='w-[24px]' src={businesscanvas} />, businessCanvasData),
      getItem(localization[2], '2', <img className='w-[24px]' src={businessmodel} />, [
        getItem(localization['2.1'], '2.1', <img className='w-[24px]' src={myBusinessModel} />),
        getItem(localization['2.2'], '2.2', <img className='w-[24px]' src={report} />),
        getItem(localization['2.3'], '2.3', <img className='w-[24px]' src={application} />),
      ]),
      getItem(localization[3], '3', <img className='w-[24px]' src={pitchDeck} />),
      getItem(localization[4], '4', <img className='w-[24px]' src={dataRoom} />),
    ]),
    // (linkInfo.openKeys.includes('1.2')) ? testMenuItems[0] : null dynamic menu item for pitch deck
  ];
  const collapsedMenuItems = [
    ...subMenuItems,
    getItem(localization[0], '0', <img className='w-[24px]' src={HomeNotFilled} />, businessCanvasData),
    getItem(localization[1], '1', <img className='w-[24px]' src={businesscanvas} />, businessCanvasData),
    getItem(localization[2], '2', <img className='w-[24px]' src={businessmodel} />, businessCanvasData),
    getItem(localization[3], '3', <img className='w-[24px]' src={pitchDeck} />, businessCanvasData),
    getItem(localization[4], '4', <img className='w-[24px]' src={dataRoom} />, businessCanvasData),
  ]
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [keys, setKeys] = useState({ dupOpenKeys: linkInfo.openKeys, dupSelectedKeys: linkInfo.selectedKeys });
  const { LOCKED_MODULES } = useLockedFeature();


  useEffect(() => {
    if (linkInfo.collapsed === false) {
      setKeys({ dupOpenKeys: [''], dupSelectedKeys: [''] });
      const timer = setTimeout(() => {
        setKeys({ dupOpenKeys: linkInfo.openKeys, dupSelectedKeys: linkInfo.selectedKeys });
      }, 50);
      return () => clearTimeout(timer);
    } else {
      setKeys({ dupOpenKeys: [''], dupSelectedKeys: [''] });
    }
  }, [linkInfo.collapsed])

  useEffect(() => {
    setKeys({ dupOpenKeys: linkInfo.openKeys, dupSelectedKeys: linkInfo.selectedKeys });
  }, [linkInfo.openKeys, linkInfo.selectedKeys])

  const handleKeyChange = (key) => {
    // console.log(key, "handleKeyChange --- component")
    if(!isPaymentActive && !['menu', 'profile-plan'].includes(key)){
      handleRestrictAccessModuleDuePayment();
      return;
    }
    const componentName = exploreCoreFeature.find((feat) => feat.key === key)?.module_name;
    if (LOCKED_MODULES?.includes(componentName)) return;
    if (key === '1.2') {
      setTest(true);
    }
    if (key == 'menu') {
      dispatch(updateCollapsedSideBar());
      // return navigate('/product/home')
    } else if (key == 'location') {
      return navigate('/product/home/steppertracker');
    } else if (key == 'predictgrowth') {
      dispatch(updateMenuItemSelection({ openKeys: ["0"], selectedKeys: ["0"] }));
      return navigate('/product/home', { replace: true });
    } else if (key == 'profile') {
      return;
    } else if (key == 'profile-plan') {
      isAdmin && navigate('/product/home/setting/subscription');
      return;
    }
    const componentOrCard = exploreCoreFeature?.find((feature) => feature.key === key);
    if (componentOrCard?.type === 'card') {
      navigate('/product/home')
    } else {
      navigate(componentOrCard?.link);
    }
    dispatch(updateItemSelection({ activeItem: key }));
  };
  const onOpenChange = (keys) => {
    if(!isPaymentActive){
      handleRestrictAccessModuleDuePayment();
      return;
    }
    const latestOpenKey = keys.find((key) => linkInfo.openKeys.indexOf(key) === -1);
    const componentName = exploreCoreFeature?.find((feat) => feat.key === latestOpenKey)?.module_name;
    if (LOCKED_MODULES?.includes(componentName)) return;
    const filteredKeys = filterLatestElements(keys);
    if (filteredKeys.length === 1 && linkInfo.collapsed) {
      dispatch(updateCollapsedSideBar())
      return;
    }
    if (latestOpenKey !== undefined) {
      // if(latestOpenKey=='test'){
      //   dispatch(updateMenuItemSelection({ openKeys: [...filteredKeys,'test'], selectedKeys: [linkInfo.selectedKeys[0]] }));
      //   return;
      // }
      if (latestOpenKey === undefined) {
        handleKeyChange(keys.length > 0 ? filteredKeys[filteredKeys.length - 1] : '0');
        dispatch(updateMenuItemSelection({ openKeys: filteredKeys, selectedKeys: [linkInfo.selectedKeys[0]] }));
      } else {
        handleKeyChange(latestOpenKey);
        dispatch(updateMenuItemSelection({ openKeys: filteredKeys, selectedKeys: [latestOpenKey.length === 1 ? latestOpenKey : linkInfo.selectedKeys[0]] }));
      }
    } else {
      if (latestOpenKey === undefined && keys.length > 0 && !linkInfo.collapsed) {
        handleKeyChange(keys.length > 0 ? filteredKeys[filteredKeys.length - 1] : '0');
        dispatch(updateMenuItemSelection({ openKeys: filteredKeys, selectedKeys: filteredKeys.length >= 2 ? linkInfo.selectedKeys[0] : ['0'] }));
      } else {
        if (linkInfo.collapsed) {
          return;
          console.log(linkInfo.collapsed);
        }
      }
    }
    return navigate('/product/home')
  };
  const handleMenuOnClick = (value) => {
    handleKeyChange(value.key);
  }
  return (
    <div className={`${(linkInfo.collapsed) ? 'w-[62px]' : 'w-[252px]'} bg-primary z-[10000] relative`}>
      <div className="sidebar border-[#beceff] h-[100vh] fixed z-[100]">
        <div className={`h-full bg-[#21208F] scrollbar-hide overflow-y-auto relative`}>
          <OldMenu activeItem={linkInfo.activeItem} collapsedMenuItems={collapsedMenuItems} items={items} keys={keys} linkInfo={linkInfo} onOpenChange={onOpenChange} onClick={handleMenuOnClick} />
        </div>
      </div>
      <div className='2xl:w-[1440px] mx-auto fixed z-[10000]'>
      <div className={`w-[42px] h-[100vh] z-[10000] absolute flex items-center ${linkInfo.collapsed ? 'left-[44px]' : 'left-[256px]'} transition-[left] duration-200`}>
        <button onClick={() => dispatch(updateCollapsedSideBar())} className='w-[42px] aspect-square rounded-full bg-p8 flex items-center justify-center'>
          <div className='w-[37px] aspect-square rounded-full bg-primary flex items-center justify-center'>
            {
              !linkInfo.collapsed
              ? <ChevronLeftOutlinedIcon className='text-white' fontSize='large' />
              : <ChevronRightOutlinedIcon className='text-white' fontSize='large' />
            }
          </div>
        </button>
      </div>
      </div>
      {/* <div className='absolute top-1/2 -translate-y-1/2 -right-[1rem] border border-red-500'>
            <div className='w-[42px] aspect-square rounded-full bg-white'></div>
          </div> */}
    </div>
  );
};

export default NewSidebar;
