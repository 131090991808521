import React from 'react'
import { Checkbox } from 'antd'
import TextLarge from '../TypographyDescriptions/TextLarge'

const CheckboxInput = ({text, isChecked, isDisabled, onChange, name}) => {
    return (
        <React.Fragment>
            <div>
                <Checkbox disabled={isDisabled} onChange={onChange} name={name} checked={isChecked} className='designSys' />
                <span className='pl-[0.5rem]'>{text}</span>
            </div>
        </React.Fragment>
    )
}

export default CheckboxInput