import React from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import ListOfCompetitor from './ListOfCompetitor';
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton';
import ailocals from '../../localization/localization_en_ai.json'
import { extractKeywords } from '../../utils/ExtractKeyWords';
import { fetchPromptAnswer, handleTMATags, updateTagTMA } from './promptSlice';
import ClientTag from './components/ClientTag';
import SecondaryButton from '../../DesignSystem/Buttons/SecondaryButton';

const tmaOutputLabel = ailocals.ai.captions.tma_text;

const { list_text, select_segment, done, back, generate, tag_list, next, question_heading, list_of_questions } = tmaOutputLabel;

const TMAOutput = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tmaDetail = useSelector((state) => state.prompt.tma);
    const { list, list_of_tags, selectedSegmentTag, isShowRecursiveParentComponent, recursive_data, isFinalProcessLoading, final_output_text } = tmaDetail;
    const handleExtractKeywords = (data) => {
        dispatch(handleTMATags({ "key": "list_of_tags", "value": extractKeywords(data).map((option) => option.value) }))
    }
    const handleOnClick = ({ selectedtag, tag, tagType, key }) => {
        if(tagType === "multi"){
            const value = selectedtag.includes(tag) ? selectedtag.filter((t) => t!==tag) : [...selectedtag, tag]
            dispatch(updateTagTMA({ key, "value": value, tagType }))
            return;
        }
        dispatch(updateTagTMA({ key, value: tag, tagType }))
    }
    if (!list) return null;
    return (
        <div className='bg-[#EEEEEE] p-[16px]'>
            {
                !!list && (
                    <div className='mx-auto'>
                        <div>
                            <ListOfCompetitor title={list_text} data={list} />
                            <div className='flex justify-between mb-[48px]'>
                                <SecondaryButton isDisabled={list_of_tags?.length > 0} text={back} onClick={() => navigate(-1)} />
                                <PrimaryButton isDisabled={list_of_tags?.length > 0} text={select_segment} onClick={() => handleExtractKeywords(list)} />
                            </div>
                        </div>
                    </div>
                )
            }
            {
                list_of_tags?.length > 0
                    ? (
                        <div>
                            <p>{tag_list}</p>
                            <ClientTag isDisabled={isShowRecursiveParentComponent} selectedTag={selectedSegmentTag} data={list_of_tags} handleOnClick={(tag) => handleOnClick({ "propType": "other",selectedtag: selectedSegmentTag, tag, tagType: "multi", key: "selectedSegmentTag" })} />
                            <div className='flex justify-between my-[48px]'>
                                <SecondaryButton isDisabled={isShowRecursiveParentComponent} text={back} onClick={() => {navigate('/product/home/targetmarketanalysis');}}/>
                                <PrimaryButton
                                    isDisabled={isShowRecursiveParentComponent || selectedSegmentTag?.length==0}
                                    isLoading={false}
                                    text={next}
                                    onClick={() => {
                                        dispatch(handleTMATags({ "key": "isShowRecursiveParentComponent", "value": true }))
                                    }}
                                />
                            </div>
                        </div>
                    ) : null
            }
            {
                isShowRecursiveParentComponent
                    ? (
                        recursive_data.map((aiResp, index) => {
                            const { selectedtag, output } = aiResp;
                            const promptId = list_of_questions.find((q) => q.label === selectedtag)?.value || null;
                            return (
                                <>
                                    {/* list of tags */}
                                    <div>
                                        <p>{question_heading}</p>
                                        <ClientTag isDisabled={index !== recursive_data.length - 1} selectedTag={selectedtag} data={list_of_questions.map((q) => q.label)} handleOnClick={(tag) => handleOnClick({ selectedtag: selectedtag, tag, tagType: "single" })} />
                                        <div className='flex justify-between my-[48px]'>
                                            <SecondaryButton isDisabled={output || isFinalProcessLoading} text={back} onClick={() => {
                                                navigate('/product/home/targetmarketanalysis');
                                            }}
                                            />
                                            <PrimaryButton
                                                isLoading={isFinalProcessLoading && recursive_data.length - 1 === index}
                                                isDisabled={(selectedtag?.length === 0 || output) || selectedtag===null}
                                                text={generate}
                                                onClick={() => {
                                                    dispatch(fetchPromptAnswer({ "type": "tmaAnalysis", "promptIds": [promptId] }))
                                                    dispatch(handleTMATags({ "propType": "other", "key": "isFinalProcessLoading", "value": true }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* output details */}
                                    {
                                        !!output
                                            ? (
                                                <div>
                                                    <ListOfCompetitor title={tmaOutputLabel.final_output_text} data={output} />
                                                </div>
                                            ) : null
                                    }
                                </>
                            )
                        })
                    ) : null
            }
        </div>
    )
}

export default TMAOutput