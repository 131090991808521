import React, { forwardRef, useEffect, useRef, useState } from "react";
import editIcon from "../../assets/competitoranalysis/Edit.svg";
import deleteIcon from "../../assets/competitoranalysis/Delete.svg";
import checkMarkIcon from "../../assets/competitoranalysis/checkMark.svg";
import dotsThreeIcon from "../../assets/competitoranalysis/DotsThreeVertical.svg";
import EditHeader from "../../assets/competitoranalysis/EditHeader.svg";
import DeleteHeader from "../../assets/competitoranalysis/DeleteHeader.svg";
import PlusCompetitor from "../../assets/competitoranalysis/PlusCompetitor.svg";
import { Table, Input, Button, message, InputNumber, Tooltip } from "antd";
import { feature } from "../compitatoranalysis/data";
import PaintBrush from "../../assets/competitoranalysis/PaintBrushh.svg";
import Edit from "../../assets/common/Edit.svg";
import Delete from "../../assets/common/Delete.svg";
import convertJSONToPDF from "../../utils/ConvertJSONToPDF";
import CSVDownloadButton from "./JsonToCsv";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Download from "../../assets/PSM/Download.svg"
import paint from "../../assets/PSM/paint.svg"
import csv from "../../assets/common/csvlogo.svg"
import pdf from "../../assets/common/pdflogo.svg"
import Plus from "../../assets/common/Plus.svg"
import PlusSecondary from "../../assets/common/PlusSecondary.svg"
import { postUserActivityDataApi } from "../UserActivity/userActivitySlice";
import useCheckStatusStepperProgress from "../../utils/checkStatusStepperProgress";
import Paint from "../reuseablecomponent/Paint";
import EditGray from "../../assets/common/EditGray.svg";
import {
  getCompetitorProductDataApi,
  addCompetitorProductDataApi,
  updateFeatureCompetitor,
  addFeatureDataApi,
  postCompetitorDataApi,
  postFeatureDataApi,
  deleteCompetitorApi,
  featureDeleteApi,
  updateFeatureTextApi,
  updateCompetitorTextApi,
  toggleFeatureApi,
  postProductTextApi,
  patchFeaturePaletteApi,
  resetCompetitorStatusErr,
  patchPricingPaletteApi,
  resetCompetitorAdded
} from "./CompetitorSlice";
import { updateProductFeature } from "../AIService/promptSlice";
import { postNewFeatureProgressApi } from "../maindashboardstepper/stepperProgressSlice";
import { updateActivePalette } from "../reuseablecomponent/PaintSlice";
import PageSpinner from "../reuseablecomponent/PageSpinner";
import MessageNotification from "../reuseablecomponent/MessageNotification";
import OopsPopUp from "../reuseablecomponent/PopUpVarients/OopsPopUp";
import useOutsideClick from "../../hooks/useOutsideClick";
import caLocalization from "../../localization/localization_en_competitor_analysis.json"
import notificationLocals from "../../localization/localization.en_notification_message.json"
import { PDFDownloadLink } from '@react-pdf/renderer';
import CustomColumnPDF from "../reuseablecomponent/PDF_Varients/CustomColumnPDF";
import { fileNameSuffix } from "../../utils/fileNameSuffix";
import { controlledUpdateCollapsedSideBar } from "../../features/manageLinkSlice";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import WarningFreeTrialAccessResource from "../reuseablecomponent/PopUpVarients/WarningFreeTrialAccessResource";
import WarningLimitedResourceOopsPopup from "../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup";
import { paywall_locals } from "../../localization";
import useApp from "../../hooks/useApp";

const PAYWALL_LOCALS = paywall_locals.competitor_analysis;


const { TextArea } = Input;

const CompetitorTable = forwardRef(() => {
  const competitor = useSelector((state) => state.competitor);
  const caLocals = caLocalization.competitor_analysis;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const checkFeatureUsageStatus = useCheckStatusStepperProgress(caLocals.module_title);
  const [operationFeature, setOperationFeature] = useState(-1);
  const features = competitor.feature || [];
  const products = competitor.product || [];
  const [enableTitleEditing, setEnableTitleEditing] = useState(-1);
  const [competitorNameKeyValue, setCompetitorName] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const permission = useSelector((state) => state.user.permission);
  const CompanyName = useSelector((state) => state.onboarding.value.Company);
  const navigate = useNavigate();
  const [isPaintOpen, setIsPaintOpen] = useState(false);
  const { selectedPalette, isPaintPaletteOpen } = useSelector((state) => state.paint);
  const [enableFeatureTitleEditing, setEnableFeatureTitleEditing] = useState(-1);
  const status = useSelector((state) => state.competitor.status);
  const productstatus = useSelector((state) => state.competitor.productstatus);
  const error = useSelector((state) => state.competitor.error);
  const collapsed = useSelector((state) => state.link.collapsed)
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const [contentForToolTip, setContentForToolTip] = useState(null);
  const downloadRef = useRef()
  const paintRef = useRef()
  const fileName = fileNameSuffix();
  const isCompetitorAdded = useSelector((state) => state.competitor.isCompetitorAdded);
  const { upgradeRenewPaywallPopup } = useApp();

  const staticRows = caLocals.table.product.rowHeading;

  useOutsideClick(downloadRef, () => {
    setIsDownloadOpen(false);
  })
  useOutsideClick(paintRef, () => {
    setIsPaintOpen(false)
  })
  useEffect(() => {
    dispatch(getCompetitorProductDataApi());
  }, []);
  useEffect(() => {
    if(isCompetitorAdded){
      messageApi.success(caLocals.message_notification.add, 2);
      dispatch(controlledUpdateCollapsedSideBar(true));
      dispatch(resetCompetitorAdded(false));
    }
    if (status === "failed") {
      const timer = setTimeout(() => {
        dispatch(resetCompetitorStatusErr());
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [status, error])
  useEffect(() => {
    const featureRows = features.map((feature, index) => {
      let rowWiseColumnName = {};
      let takeCompetitorName = {};
      for (let i = 0; i < products.length; i++) {
        if (index === 0) {
          takeCompetitorName = {
            ...takeCompetitorName,
            [products[i]._id]: products[i]?.competitorName,
          };
        }
        let exist = false;
        const extractFreatures = products[i]?.Features?.map((feat) => feat.data);
        let getColor = products[i]?.Features?.find((feat) => feat.data === feature._id);
        // for(let i=0;i<products[i].Features.length;i++){
        //   console.log(feature._id==products[i].Features[i].data,"test");
        //   if(feature._id === products[i].Features[i].data){
        //     exist = true;
        //     break;
        //   }
        // }
        rowWiseColumnName = {
          ...rowWiseColumnName,
          [products[i]?._id]: {
            data: extractFreatures?.includes(feature?._id),
            _id: products[i]?._id,
            bgColor: getColor === undefined ? "" : getColor?.bgColor
          },
        };
      }


      if (index === 0) {
        setCompetitorName(takeCompetitorName);
      }
      return {
        key: index,
        ProductHeading: {
          data: feature?.featureName,
          _id: feature?._id
        },
        ...rowWiseColumnName,
      };
    });

    console.log(products, "products");


    const productRows = staticRows?.map((productHeading, index) => {
      const modifiedHeadingName =
        index === 1
          ? "UniqueSellingProposition"
          : productHeading?.split(" ")?.join("");
      let rowWiseDataFilter = { ProductHeading: staticRows[index] };
      for (let i = 0; i < products.length; i++) {
        rowWiseDataFilter = {
          ...rowWiseDataFilter,
          [products[i]?._id]: {
            data: products[i][modifiedHeadingName],
            _id: products[i]?._id,
            bgColor: products?.[i]?.[modifiedHeadingName]?.["bgColor"] ?? null,
            label: modifiedHeadingName
          },
        };
      }
      return { key: index + features?.length + 1, ...rowWiseDataFilter };
    });

    const featurePlusProductRows = [...featureRows, ...productRows];
    setData(featurePlusProductRows);
  }, [products, features]);

  function generateUniqueId() {
    const array = new Uint32Array(4);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => dec?.toString(16))?.join("");
  }

  const handleDeleteCompetitor = (deleteCompetitorId) => {
    let deleteCompetitorClone = {};
    const filteredCompetitors = products?.filter((competitor, index) => {
      if (competitor?._id !== deleteCompetitorId) {
        return competitor;
      } else {
        if (products?.length === 5) {
          deleteCompetitorClone = {
            ...competitor,
            _id: competitor?._id,
            competitorName: "",
            Features: [],
            CustomerSupportTraining: "",
            UniqueSellingProposition: "",
            PricingRange: { mini: '', maxi: '' },
            TechnologyStack: "",
            VoiceOfCustomer: "",
          };
        }
      }
    });
    dispatch(deleteCompetitorApi({ count: filteredCompetitors?.length, id: deleteCompetitorId }));
    dispatch(
      updateFeatureCompetitor({
        type: "product", data:
          filteredCompetitors?.length <= 3
            ? [...filteredCompetitors, deleteCompetitorClone]
            : [...filteredCompetitors],
      })
    );
    dispatch(postUserActivityDataApi({ ...notificationLocals.notification_message.competitor_analysis.delete }))
  };

  const handleDeleteFeature = (deleteFeatureIndex) => {
    const deletedFeatureId = features[deleteFeatureIndex]?._id;
    let deleteFeatureClone;

    let updatedFeatures = features?.filter((feat, i) => {
      if (i === deleteFeatureIndex) {
        deleteFeatureClone = {
          ...feat,
          _id: feat?._id,
          featureName: "",
        };
      } else {
        return feat;
      }
    });
    if (updatedFeatures?.length < 3) {
      dispatch(featureDeleteApi({ type: "reset", featureid: deletedFeatureId }))
    } else {
      dispatch(featureDeleteApi({ type: "deletefeature", featureid: deletedFeatureId }))
    }
    dispatch(//removed feature id 
      updateFeatureCompetitor({
        type: "feature",
        data:
          updatedFeatures?.length < 1
            ? [...updatedFeatures, deleteFeatureClone]
            : updatedFeatures,
      })
    );
    const updatedProducts = products?.map((competitor) => ({//fine
      ...competitor,
      Features: competitor?.Features?.filter(
        (featId) => featId?.data !== deletedFeatureId
      ),
    }));
    console.log(updatedProducts);
    dispatch(//removed feature id in products
      updateFeatureCompetitor({ type: "product", data: updatedProducts })
    );
    dispatch(postUserActivityDataApi({ ...notificationLocals.notification_message.competitor_analysis.delete }))
  };

  const handleTickMark = (text, index) => {
    const featureId = features[index]._id;
    let addFeature = "add";
    const toggleFeature = products.map((competitor) => {
      if (competitor?._id === text?._id) {
        let exist = false;
        for (let i = 0; i < competitor?.Features?.length; i++) {// check existance of feature in array
          if (competitor?.Features[i]?.data === featureId) {
            exist = true;
            break;
          }
        }
        if (exist) {//feature is present
          addFeature = "remove";
          const updatedFeatures = competitor?.Features?.filter((feature) => feature?.data !== featureId);
          return { ...competitor, Features: updatedFeatures }
        } else {//feature not present
          const updatedFeatures = [...competitor.Features, { data: featureId, bgColor: "" }];
          return { ...competitor, Features: updatedFeatures };
        }
      } else {
        return competitor;
      }
    })
    dispatch(updateFeatureCompetitor({ type: "product", data: toggleFeature }));
    dispatch(toggleFeatureApi({ type: addFeature, id: text?._id, feature: { data: featureId, bgColor: "" } }))
    // dispatch(postCompetitorDataApi());
  };

  const applyColor = (text, index) => {
    let patchColorPayload;
    const featureId = features[index]?._id;
    const updatedColor = products?.map((competitor) => {
      if (competitor?._id === text?._id) {
        const updatedColorFeatures = competitor?.Features?.map((feat) => {
          if (feat?.data === featureId) {
            patchColorPayload = { id: competitor?._id, featureid: featureId, bgColor: selectedPalette };
            return { data: feat?.data, bgColor: selectedPalette }
          } else {
            return feat;
          }
        })
        return { ...competitor, Features: updatedColorFeatures };
      } else {
        return competitor;
      }
    })
    dispatch(updateFeatureCompetitor({ type: "product", data: updatedColor }));
    dispatch(patchFeaturePaletteApi(patchColorPayload));
    dispatch(updateActivePalette(null));
    setIsPaintOpen(false);
  }

  const applyColorText = (text, index) => {
    const updatedColor = products?.map((competitor) => {
      if (competitor?._id === text?._id) {
        return { ...competitor, [text?.label]: { data: competitor[text?.label].data, bgColor: selectedPalette } };
      } else {
        return competitor;
      }
    })
    dispatch(updateFeatureCompetitor({ type: "product", data: updatedColor }));
    dispatch(updateActivePalette(null));
  }

  const handlePricingNumberChange = (text) => {
    let latestText;
    products?.find((competitor) => {
      if (competitor._id === text._id) {
        // console.log(competitor?.PricingRange);
        latestText = { key: "PricingRange", value: competitor?.PricingRange, id: competitor?.productId }
        return competitor;
      }
    });
    dispatch(postProductTextApi(latestText));
    // dispatch();
  }

  const resetMisMatchPricingData = (text, type) => {
    let latestText;
    products?.find((competitor) => {
      if (competitor._id === text._id) {
        console.log(competitor?.PricingRange);
        const resetData = type === "maxi" ? { maxi: 0 } : { mini: 0 }
        const data = { ...competitor?.PricingRange.data, ...resetData }
        latestText = { key: "PricingRange", value: { ...competitor?.PricingRange, data: data }, id: competitor?.productId }
        return competitor;
      }
    });
    dispatch(postProductTextApi(latestText));
  }

  const resetPricingNumber = (text, type) => {
    const updatedPriceProducts = products?.map((competitor) => {
      if (competitor?._id === text?._id) {
        let copyCompetitor = { ...competitor };
        let resetValue = (type === "maxi") ? { maxi: "" } : { mini: "" }
        copyCompetitor.PricingRange = { ...copyCompetitor?.PricingRange, data: { ...copyCompetitor?.PricingRange?.data, ...resetValue } }
        return copyCompetitor;
      } else {
        return competitor;
      }
    });
    dispatch(
      updateFeatureCompetitor({
        type: "product",
        data: updatedPriceProducts,
      })
    );
  }
  

  const checkMaximumPrice = (e, text) => {
    if (text?.data?.data?.mini?.length > 0) {
      if (e.target.value <= Number(text?.data?.data?.mini)) {
        message.open({
          type: "warning",
          content: caLocals.warning_tooltip_message.pricing_maxi
        })
        //reset logic
        resetPricingNumber(text, "maxi")
        resetMisMatchPricingData(text, "maxi")
      } else {
        console.log("maxi")
        handlePricingNumberChange(text);
        //reset api logic

      }
    } else {
      handlePricingNumberChange(text);
    }
  }

  const checkMinimumValue = (e, text) => {
    if (text?.data?.data?.maxi?.length > 0) {
      if (e.target.value >= Number(text?.data?.data?.maxi)) {
        message.open({
          type: "warning",
          content: caLocals.warning_tooltip_message.pricing_mini
        })
        resetPricingNumber(text, "mini")
        resetMisMatchPricingData(text, "mini")
      } else {
        console.log("mini")
        handlePricingNumberChange(text);
      }
    } else {
      handlePricingNumberChange(text);
    }
  }

  const extractCompetitorHeading = products?.map((productCompetitor, index) => {
    return {
      title: (
        <div className="flex items-center h-[70px] border-r bg-white">
          <div className="table_other_col_heading bg-white">
            <TextArea
              maxLength={32}
              onClick={() => index!=0 && setEnableTitleEditing(index)}
              style={{ backgroundColor: "transparent" }}
              disabled={(index == 0) || !permission.edit}
              className={`${index % 2 !== 0 ? "bg-white" : "makeTransparentTable"
                } border-none product_table_header scrollbar-hide`}
              value={index === 0 ? CompanyName : products[index]?.competitorName?.trim()?.length == 0 && enableTitleEditing !== index ? `${caLocals?.table?.product?.header?.competitor_placeholder} ${index}` : products[index]?.competitorName}
              placeholder={index === 0 ? caLocals.table.product.header.you_placeholder : `${caLocals.table.product.header.competitor_placeholder} ${index}`}
              onChange={(e) => {
                const updatedProducts = [...products]; // Create a copy of the products array
                updatedProducts[index] = {
                  ...updatedProducts[index], // Create a shallow copy of the target object
                  competitorName: e.target.value, // Update the competitorName property
                };
                if (e.target.value.length == 32) {
                  message.open({
                    type: "warning",
                    content: caLocals.warning_tooltip_message.character_limit
                  })
                }
                dispatch(
                  updateFeatureCompetitor({
                    type: "product",
                    data: updatedProducts,
                  })
                );
              }}
              onBlur={() => {
                if (products[index].competitorName.length === 0) {
                  const updatedProducts = [...products]; // Create a copy of the products array
                  updatedProducts[index] = {
                    ...updatedProducts[index], // Create a shallow copy of the target object
                    competitorName: ``, // Update the competitorName property
                  };
                  dispatch(
                    updateFeatureCompetitor({
                      type: "product",
                      data: updatedProducts,
                    })
                  );
                }
                if (!checkFeatureUsageStatus) {
                  dispatch(postNewFeatureProgressApi({ status: 1, featureName: caLocals.module_title }))
                }
                setEnableTitleEditing(-1);
                // alert(products[index]._id);
                dispatch(updateCompetitorTextApi({ id: products[index]._id, text: products[index].competitorName }));
              }}
              ref={(element) => {
                if (index === enableTitleEditing && element) {
                  element.focus(); // Focus the input
                }
              }}
              autoSize={{ minRows: 1, maxRows: 2 }}
            />
          </div>
          <div
            className={` ${index % 2 !== 0 ? "bg-white" : "makeTransparentTable"
              } buttons h-[68px] flex justify-end cursor-pointer`}
          >
            {
              index !== 0 && (
                <button
                  disabled={!permission.edit}
                  onClick={() => {
                    setEnableTitleEditing(index);
                  }}
                  className="edit mx-1"
                >
                  <ModeEditOutlinedIcon className={'text-primary'} fontSize='small' />
                  {/* <img width={18} height={18} src={Edit} alt="" /> */}
                </button>
              )
            }
            {index !== 0 && (
              <button
                disabled={!permission.delete}
                onClick={() => handleDeleteCompetitor(productCompetitor?._id)}
                className="delete mr-1"
              >
                <DeleteOutlinedIcon className='text-infoAlertError1' fontSize='small' />
                {/* <img width={20} height={20} src={Delete} alt="" /> */}
              </button>
            )}
          </div>
        </div>
      ),
      dataIndex: productCompetitor?._id, //productCompetitor._id
      width: 220,
      key: JSON.stringify(index + 1),
      render: (text, record, index) => {
        // console.log(text)
        return (
          <div className={`flex items-center justify-center border-r border-[#f3f3f3]`}>
            {typeof text?.data?.data === "object" ? (
              <div
                className={`flex items-center justify-center h-[92px] w-full`}
                style={{ backgroundColor: text?.bgColor, borderTop: "1px solid #e4e7eb" }}
                onClick={() => {
                  console.log(text?.data?.data?.mini, text?.data?.data?.maxi);
                  if (selectedPalette !== null) {
                    if (!!text?.data?.data?.mini && !!text?.data?.data?.maxi) {
                      applyColorText(text, index);//update color locally
                      for (let i = 0; i < products?.length; i++) {
                        if (products[i]?._id == text?._id) {
                          dispatch(patchPricingPaletteApi({ productId: products[i].productId, colorPalette: selectedPalette }));
                          break;
                        }
                      }
                    } else {
                      setContentForToolTip(caLocals.warning_tooltip_message.color_code)
                      setIsToolTipOpen((prev) => !prev);
                      dispatch(updateActivePalette(null));
                    }
                  }
                }}

              >
                <input
                  disabled={!permission.edit}
                  value={text?.data?.data?.mini || ""}
                  placeholder={caLocals.table.product.input_cell.min.placeholder}
                  onBlur={(e) => {
                    if (parseFloat(e.target.value) > 0) {
                      checkMinimumValue(e, text)
                    } else {
                      checkMinimumValue(e, text)
                      const updatedPriceProducts = products?.map((competitor) => {
                        if (competitor?._id === text?._id) {
                          let copyCompetitor = { ...competitor }; // Create a shallow copy of the competitor object
                          copyCompetitor.PricingRange = { ...copyCompetitor?.PricingRange, data: { ...copyCompetitor?.PricingRange?.data, mini: "" } }
                          return copyCompetitor;
                        } else {
                          return competitor;
                        }
                      });
                      dispatch(
                        updateFeatureCompetitor({
                          type: "product",
                          data: updatedPriceProducts,
                        })
                      );
                    }
                  }}
                  onChange={(e) => {
                    const updatedPriceProducts = products?.map((competitor) => {
                      if (competitor?._id === text?._id) {
                        let copyCompetitor = { ...competitor }; // Create a shallow copy of the competitor object
                        copyCompetitor.PricingRange = { ...copyCompetitor?.PricingRange, data: { ...copyCompetitor?.PricingRange?.data, mini: e.target.value } }
                        return copyCompetitor;
                      } else {
                        return competitor;
                      }
                    });
                    dispatch(
                      updateFeatureCompetitor({
                        type: "product",
                        data: updatedPriceProducts,
                      })
                    );
                    // dispatch(postCompetitorDataApi());
                  }}
                  className="w-[94px] h-[38px] border border-[#BECEFF] text-center font-InterRegular tracking-wide"
                  name="max"
                  id="min"
                />
                <div className="mx-1 text-[#9E9E9E]">-</div>
                <input
                  type="number"
                  min="1"
                  value={text?.data?.data?.maxi || ""}
                  disabled={!permission.edit}
                  placeholder={caLocals.table.product.input_cell.max.placeholder}
                  onChange={(e) => {
                    const updatedPriceProducts = products?.map((competitor) => {
                      if (competitor?._id === text?._id) {
                        let copyCompetitor = { ...competitor };
                        copyCompetitor.PricingRange = { ...copyCompetitor?.PricingRange, data: { ...copyCompetitor?.PricingRange?.data, maxi: e.target.value } }
                        return copyCompetitor;
                      } else {
                        return competitor;
                      }
                    });
                    dispatch(
                      updateFeatureCompetitor({
                        type: "product",
                        data: updatedPriceProducts,
                      })
                    );
                    // dispatch(postCompetitorDataApi());
                  }}
                  onBlur={(e) => {
                    if (parseFloat(e.target.value) > 0) {
                      checkMaximumPrice(e, text);
                    } else {
                      checkMaximumPrice(e, text);
                      const updatedPriceProducts = products?.map((competitor) => {
                        if (competitor?._id === text?._id) {
                          let copyCompetitor = { ...competitor }; // Create a shallow copy of the competitor object
                          copyCompetitor.PricingRange = { ...copyCompetitor?.PricingRange, data: { ...copyCompetitor?.PricingRange?.data, maxi: "" } }
                          return copyCompetitor;
                        } else {
                          return competitor;
                        }
                      });
                      dispatch(
                        updateFeatureCompetitor({
                          type: "product",
                          data: updatedPriceProducts,
                        })
                      );
                    }
                  }}
                  className="w-[94px] h-[38px] border border-[#BECEFF] font-InterRegular tracking-wide text-center"
                  name="max"
                  id="max"
                />
              </div>
            ) : typeof text?.data === "boolean" ? (
              <div style={{ backgroundColor: text?.bgColor }} className={`h-[92px] w-full`} onClick={(e) => {
                if (!text?.data && selectedPalette !== null) {
                  setContentForToolTip(caLocals.warning_tooltip_message.color_code)
                  setIsToolTipOpen((prev) => !prev);
                  dispatch(updateActivePalette(null));
                  e.stopPropagation();
                  return;
                }
                if (selectedPalette !== null) {
                  applyColor(text, index);
                }
              }
              }>
                <button
                  disabled={!permission.edit}
                  onClick={(e) => {
                    if (!text?.data && selectedPalette !== null) {
                      setContentForToolTip(caLocals.warning_tooltip_message.color_code)
                      setIsToolTipOpen((prev) => !prev);
                      dispatch(updateActivePalette(null));
                      e.stopPropagation();
                      return;
                    }
                    if (selectedPalette === null) {
                      handleTickMark(text, index);
                    }
                    e.stopPropagation();
                  }}
                  className="border-[1px] border-[#BDBDBD] relative top-4 left-1/2 -translate-x-1/2 w-[32px] h-[32px] btn_check_mark"
                ></button>
                {text?.data && (
                  <button onClick={() => handleTickMark(text, index)} disabled={!permission.edit}>
                    <img
                      src={checkMarkIcon}
                      className="absolute top-3 cursor-pointer left-1/2 -translate-x-1/2"
                    />
                  </button>
                )}
              </div>
            ) : (
              <TextArea
                style={{ backgroundColor: text?.bgColor, borderTop: "1px solid #e4e7eb" }}
                onClick={() => {
                  if (selectedPalette !== null) {
                    if (text?.data?.data) {
                      applyColorText(text, index)
                    } else {
                      setContentForToolTip(caLocals.warning_tooltip_message.color_code)
                      setIsToolTipOpen((prev) => !prev);
                      dispatch(updateActivePalette(null));
                      e.stopPropagation();
                      return;
                    }
                  }
                }}
                disabled={!permission.edit}
                maxLength={200}
                value={text?.data?.data}
                placeholder={caLocals.table.product.text_cell.placeholder}
                className="main_text_input_area border"
                onChange={(e) => {
                  const updatedText = products?.map((competitor) => {
                    if (competitor?._id === text?._id) {
                      // console.log(staticRows[index - features.length],e.target.value,text);
                      let targetElement = staticRows[index - features?.length]
                        ?.split(" ")
                        ?.join("")
                        ?.replace(/\([^)]*\)/g, "");
                      return { ...competitor, [targetElement]: { ...text?.data, data: e.target.value } };
                    } else {
                      return competitor;
                    }
                  });
                  dispatch(
                    updateFeatureCompetitor({
                      type: "product",
                      data: updatedText,
                    })
                  );
                }}
                onBlur={(e) => {
                  let latestText;
                  const fetchLatestText = products?.find((competitor) => {
                    if (competitor?._id === text?._id) {
                      let targetElement = staticRows[index - features.length]
                        ?.split(" ")
                        ?.join("")
                        ?.replace(/\([^)]*\)/g, "");
                      latestText = { key: targetElement, value: competitor[targetElement], id: competitor?.productId }
                      return { [targetElement]: { ...text?.data, data: e.target.value } };
                    }
                  });
                  // alert(JSON.stringify(latestText))
                  dispatch(postProductTextApi(latestText));
                }}
                autoSize={{ minRows: 3.5, maxRows: 2 }}
              />
            )}
          </div>
        );
      },
    };
  });
  const columns = [
    {
      title: (
        <div className="pl-[22px] h-[70px] ------header----- bg-[#372EE5] text-white flex items-center text-[16px] font-InterRegular tracking-wide">
          {caLocals.table.product.title}
        </div>
      ),
      dataIndex: "ProductHeading",
      width: 220,
      key: "0",
      fixed: "left",
      render: (text, record, index) => (
        <div
          className={`${typeof text === "object"
            ? "h-[92px]"
            : "h-[92px] border-t border-[#cecdcd]"
            } flex relative justify-between text-[#372EE5] bg-[#EBF1FF]`}
        >
          {typeof text === "object" ? (
            <TextArea
              maxLength={40}
              className="text-[#372EE5] font-InterRegular tracking-wide feature_textarea_color_change"
              disabled={!permission.edit}
              value={text?.data}
              autoSize={{ minRows: 3.5, maxRows: 2.8 }}
              onChange={(e) => {
                const updatedFeatures = [...features]; // Create a copy of the features array
                updatedFeatures[index] = {
                  ...updatedFeatures[index], // Create a shallow copy of the target object
                  featureName: e.target.value, // Update the featureName property
                };
                dispatch(
                  updateFeatureCompetitor({
                    type: "feature",
                    data: updatedFeatures,
                  })
                );
              }}
              placeholder={`${caLocals.table.product.feature_cell.placeholder} ${index + 1}`}
              onBlur={() => {
                const data = features[index];
                setEnableFeatureTitleEditing(-1);
                dispatch(updateFeatureTextApi({ featureid: data?._id, text: data?.featureName }))
                // dispatch(postFeatureDataApi());
              }}
              ref={(element) => {
                if (index === enableFeatureTitleEditing && element) {
                  element.focus(); // Focus the input
                }
              }}
            />
          ) : (
            <span className="pl-[22px] flex justify-center items-center text-[15.5px] font-InterRegular tracking-wide">
              {text}
            </span>
          )}
          {typeof text === "object" && (
            <button
              disabled={!permission.edit}
              className="absolute top-5 right-0"
              onClick={() => {
                setOperationFeature(() => {
                  if (index === operationFeature) {
                    return -1;
                  } else {
                    return index;
                  }
                })
              }}
            >
              <MoreVertOutlinedIcon className="cursor-pointer" />
            </button>
          )}
          {typeof text === "object" && index === operationFeature && (
            <div
              style={{
                boxShadow:
                  "4px 4px 24px 0px rgba(33, 33, 33, 0.25), -4px -4px 18px 0px rgba(0, 0, 0, 0.16)",
                backgroundColor: "#fff",
              }}
              className="w-[239px] h-[184px] rounded-[8px] text-[#111827] p-[24px] absolute top-[3rem] -right-[15rem] border border-black-500 bg-white z-[1000]"
            >
              <div>
                <button
                  disabled={!permission.edit}
                  onClick={() => {
                    if (features.length >= 10) {
                      setContentForToolTip(caLocals.warning_tooltip_message.limit_feature);
                      setIsToolTipOpen((prev) => !prev);
                      setOperationFeature(-1);
                    } else {
                      setOperationFeature(-1);
                      handleAddFeature();
                    }
                  }}
                  className="flex items-center gap-x-[12px] mb-[24px]"
                >
                  <AddOutlinedIcon fontSize="small" className="text-baseGray500" />
                  <span>{caLocals.table.product.feature_cell.add_feature}</span>
                </button>
              </div>
              <div>
                <button
                  disabled={!permission.delete}
                  onClick={() => {
                    setOperationFeature(-1);
                    handleDeleteFeature(index);
                  }}
                  className="flex items-center gap-x-[12px] mb-[24px]"
                >
                  <DeleteOutlinedIcon fontSize="small" className="text-infoAlertError1" />
                  <span>{caLocals.table.product.feature_cell.delete_feature}</span>
                </button>
              </div>
              <div>
                <button
                  disabled={!permission.edit}
                  onClick={() => {
                    setOperationFeature(-1);
                    setEnableFeatureTitleEditing(index);
                  }}
                  className="flex items-center gap-x-[12px] mb-[24px]"
                >
                  <ModeEditOutlinedIcon fontSize="small" className="text-primary" />
                  <span>{caLocals.table.product.feature_cell.edit_feature}</span>
                </button>
              </div>
            </div>
          )}
        </div>
      ),
    },
    ...extractCompetitorHeading,
  ];

  const handleAddCompetitor = () => {
    dispatch(addCompetitorProductDataApi());
  };
  const handleAddFeature = () => {
    dispatch(addFeatureDataApi());
  };
  const handleSelectedColorInPaint = (color) => {
    dispatch(updateActivePalette(color));
    setIsPaintOpen(false);
  }
  const resetToolTipState = () => {
    setContentForToolTip(null);
    setIsToolTipOpen(false);
  }
  return (
    <div className={`${collapsed ? 'w-[81.5%]' : 'w-[92%]'} ml-[0rem] relative`}>
      {contextHolder}
      {true && <OopsPopUp description1={contentForToolTip} isOpen={isToolTipOpen} onClick={resetToolTipState} />}
      {productstatus === "loading" && <PageSpinner />}
      {status === "failed" && <MessageNotification status={status} type={"error"} content={error} />}
      <div className="flex justify-end mx-auto gap-x-5 relative -top-[0rem]">
        {selectedPalette && <div className='flex justify-center items-center gap-x-2'>
          <div style={{ backgroundColor: selectedPalette }} className='w-[24px] h-[24px] border rounded-full'></div>
          <span className='font-InterRegular text-[12px] text-[#9E9E9E] tracking-wide'>{caLocals.palette_selected}</span>
        </div>}
        <div ref={paintRef} className="paintMenu">
          <Button onClick={() => {
            setIsPaintOpen((prev) => !prev)
          }} disabled={!permission.paint} className="p-0 border-none">
            <BrushOutlinedIcon fontSize='medium' className='text-black' />
          </Button>
          <Paint isPaintOpen={isPaintOpen} handleSelectedColor={handleSelectedColorInPaint} />
        </div>
        <WarningLimitedResourceOopsPopup
          module_data={{ "module": "product_ca", "data": data }}
          description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].download.description1}
          description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].download.description2}
        >
          {({ handleTogglePopup, isFreeSubscription, accessDownload }) => (
            <div ref={downloadRef} className="downloadMenu">
            <Button onClick={() => (isFreeSubscription || accessDownload) ? handleTogglePopup() : setIsDownloadOpen((prev) => !prev)} disabled={!permission.download} className="p-0 border-none"><DownloadOutlinedIcon className='text-black' fontSize='medium' /></Button>
            {isDownloadOpen && <div className={`p-[12px] pb-[12px] absolute top-[2rem] shadow-xl border right-0 z-[10] w-[239px] bg-white rounded-[8px]`}>
              <CSVDownloadButton
                competitorNameKeyValue={competitorNameKeyValue}
                Columns={columns}
                data={data}
              />
              <Button disabled={!permission.download} onClick={() => {
                // convertJSONToPDF(data, competitorNameKeyValue, "Product", CompanyName)
                dispatch(postUserActivityDataApi({ ...notificationLocals.notification_message.competitor_analysis.download.pdf }))
              }} type="link" className="flex mt-[24px] w-full px-[12px] border-none hover:bg-p8">
                <img src={pdf} alt="" />
                <PDFDownloadLink document={<CustomColumnPDF jsondata={data} competitorNameKeyValue={competitorNameKeyValue} name={"Product"} CompanyName={CompanyName} />} fileName={`CompetitorAnalysis_Product_${fileName}.pdf`}>
                  {({ blob, url, loading, error }) =>
                    loading ? 'Loading document...' : <span className="ml-[12px] text-[#111827] font-InterRegular text-[14px] font-medium tracking-wide">{caLocals.download.pdf}</span>
                  }
                </PDFDownloadLink>
              </Button>
            </div>
            }
          </div>
          )}
        </WarningLimitedResourceOopsPopup>
      </div>
      <div className="table-container overflow-hidden relative border">
        <Table
          columns={columns}
          dataSource={data}
          scroll={{
            x: 1100, // Adjust this value to fit your content
            y: 450,
          }}
          pagination={false}
        />

      </div>
      <Tooltip placement="right" title={caLocalization.competitor_analysis.add_competitor}>
      <button
        disabled={!permission.edit}
        onClick={() => {
          if (products.length >= 6) {
            messageApi.success(caLocalization.competitor_analysis.message_notification.add, 2);
            setContentForToolTip(caLocals.warning_tooltip_message.limit_competitor);
            setIsToolTipOpen((prev) => !prev);
          } else {
            handleAddCompetitor()
            console.log("max*****")
          }
        }}
        className="p-[12px] flex bg-primary absolute top-[2.5rem] -right-[2.5rem] rounded-[8px]"
      >
        <img src={Plus} />
      </button>
      </Tooltip>
    </div>
  );
});

export default CompetitorTable;
