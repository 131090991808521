import React, { useState, useEffect } from 'react';
import { Button, Checkbox, message, Form, Input, Tooltip } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import checkcircle from '../../assets/Login/check-circle.svg';
import profile from '../../assets/SignUp/Profile.png';
import { Link } from 'react-router-dom';
import { createUser, resetStatus, updateCreateAccCred, userInfoStatus, updateErr, userInfoError, updateStatus } from '../../features/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import '../../index.css';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/Header/logotest.svg';
import brand from "../../assets/common/brand.svg";
import test3 from '../../assets/Login/createAccVector.svg';
import "../auth/auth.css";
import footerLocals from "../../localization/localization_en_footer.json"
import { passwordValidator, passwordValidatorForEnableBtn } from '../../utils/passwordValidator';
import infoIcon from "../../assets/common/tooltip.svg"
import TextMedium from '../../DesignSystem/TypographyDescriptions/TextMedium';
import VerifyEmailModal from '../../components/modals/VerifyEmailModal';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import ResponsiveHeadingThreeSix from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingThreeSix';
import TextExtraSmall from '../../DesignSystem/TypographyDescriptions/TextExtraSmall';
import TextSmall from '../../DesignSystem/TypographyDescriptions/TextSmall';
import DoneIcon from '@mui/icons-material/Done';
import GroupIcon from '@mui/icons-material/Group';
import { cn } from '../../utils/cn';

const SignUpTemplate = ({ locals, isDefaultTemplate = false, className='', partnership_icon, logowidth }) => {

    const WARNING_ERROR_MESSAGE = locals.form.warning_error_messages;
    const LOCALS = locals;
    const signup_cohorot_name = locals.form.cohorot_field_name;
    const USER_TYPE = locals.form.user_type;
    const currentPath = location.pathname;

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userStatus = useSelector(userInfoStatus);
    const credentialDetail = useSelector((state) => state.user.createacc);
    const userErr = useSelector((state) => state.user.error);
    const [checkAgreeTermsAndCond, setCheckAgreeTermsAndCond] = useState(false);
    const [isSignUpDisabled, setIsSignUpDisabled] = useState(false);
    const [passwordValidationErrMsg, setPasswordValidationErrMsg] = useState(null);
    const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    // const [isEmailValid, setEmailValid] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [passwordVisible, setPasswordVisible] = useState(false);
    // const [subscriptionList, setSubscriptionList] = useState([]);
    // const [subscriptionId,setSubscriptionId] = useState(null);
    const [placeholder, setplaceholder] = useState("");

    // useEffect(() => {
    //   const fetchAllSubcription = async () => {
    //     fetch('http://localhost:5000/stripe/all-subscription-plans')
    //       .then(response => {
    //         if (!response.ok) {
    //           throw new Error('Network response was not ok');
    //         }
    //         return response.json();
    //       })
    //       .then(data => {
    //         setplaceholder(data?.allSubscriptions[0]?.description)
    //         const items = data?.allSubscriptions?.map((subscription, index) => {
    //           return (
    //             {
    //               label: subscription?.currency?.toUpperCase()+" "+subscription?.amount_to_display+" for "+subscription?.interval_count+" "+subscription?.interval?.slice(0,1).toUpperCase()+subscription?.interval?.slice(1),
    //               value: subscription?.id
    //             }
    //           )
    //         })
    //         setSubscriptionList(items);
    //       })
    //       .catch(error => {
    //         console.error('There was a problem with the fetch operation:', error);
    //       });
    //   }
    //   fetchAllSubcription()
    // }, [])


    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        console.log(e.target.name, e.target.value)
        // dispatch(updateCreateAccCred());
        // setEmail(emailValue);
        // setEmailValid(validateEmail(emailValue));
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handlePasswordVisibilityChange = () => {
        setPasswordVisible((prev) => !prev);
    };

    // const validateEmail = (email) => {
    //   return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    // };
    const isLoginDisabled =
        !checkAgreeTermsAndCond ||
        !credentialDetail?.["isEmailValid"] ||
        passwordValidatorForEnableBtn(credentialDetail?.["password"]) ||
        userStatus === "loading" ||
        !credentialDetail?.["username"]?.trim()?.length >= 3 ||
        !credentialDetail?.[signup_cohorot_name]?.trim()?.length >= 1 ||
        !credentialDetail?.["companyName"]?.trim()?.length >= 3 ||
        !credentialDetail?.["countryCode"] ||
        (!!credentialDetail?.["phoneNumber"] ? !isValidPhoneNumber(String(credentialDetail?.["phoneNumber"])) : true)

    const handleFormInputs = (e, name) => {
        dispatch(updateCreateAccCred({ key: name, value: e.target.value }))
        // console.log(e.target.value,name);
    }
    // console.log(userStatus,isLoginDisabled);

    const formFields = [
        {
            name: 'username',
            className: 'resp_login_width',
            rules: [
                {
                    required: true,
                    message: WARNING_ERROR_MESSAGE.username_input,
                    validator: !(credentialDetail?.["username"]?.trim()?.length >= 3 && credentialDetail?.["username"]?.trim()?.length <= 20)
                },
            ],
            inputProps: {
                placeholder: locals.form.form.username.placeholder,
                className: 'md:h-[50px] h-[42px] md:rounded-xl',
                prefix: <PersonIcon className='text-baseGray500' />,
                onChange: (e) => handleFormInputs(e, 'username'),
            },
            maxChar: 20
        },
        {
            name: 'companyName',
            className: 'resp_login_width',
            rules: [
                {
                    required: true,
                    message: WARNING_ERROR_MESSAGE.company_input,
                    validator: !(credentialDetail?.["companyName"]?.trim()?.length >= 3 && credentialDetail?.["companyName"]?.trim()?.length <= 100)
                },
            ],
            inputProps: {
                placeholder: locals.form.form.companyName.placeholder,
                className: 'md:h-[50px] h-[42px] md:rounded-xl',
                prefix: <ApartmentIcon className='text-baseGray500' />,
                onChange: (e) => handleFormInputs(e, 'companyName'),
            },
            maxChar: 100
        },
        {
            name: 'phoneNumber',
            className: 'resp_login_width',
            rules: [
                {
                    required: true,
                    message: WARNING_ERROR_MESSAGE.phone_number,
                    validator: !(credentialDetail?.["phoneNumber"]?.length === 0 ? true : isValidPhoneNumber(String(credentialDetail?.["phoneNumber"])))
                },
            ],
            inputProps: {
                placeholder: locals.form.form.phoneNumber.placeholder,
                className: `md:h-[50px] h-[42px] md:rounded-xl border px-[1rem] outline-none ${!isValidPhoneNumber(String(credentialDetail?.["phoneNumber"])) && credentialDetail?.["phoneNumber"]?.length > 0 ? 'border-red-500' : 'border-[#d9d9d9]'}`,
                prefix: <img className='md:w-[18px] w-[14px]' src={profile} alt="" />,
                onChange: (val) => dispatch(updateCreateAccCred({ key: 'phoneNumber', value: val ?? '' })),
                onCountryChange: (countryCode) => dispatch(updateCreateAccCred({ key: 'countryCode', value: countryCode }))
            },
        },
        {
            name: 'email',
            className: `resp_login_width`,
            rules: [
                {
                    required: true,
                    message: WARNING_ERROR_MESSAGE.email,
                    validator: !credentialDetail?.["isEmailValid"]
                },
            ],
            inputProps: {
                placeholder: locals.form.form.email.placeholder,
                className: `md:h-[50px] h-[42px] md:rounded-xl ${credentialDetail?.["email"] && !credentialDetail?.["isEmailValid"] ? 'border-red-500' : ''}`,
                prefix: <EmailIcon className='text-baseGray500' style={{ fontSize: "1.4rem" }} />,
                suffix: credentialDetail?.["email"] && credentialDetail?.["isEmailValid"] && <img className='md:w-[20px] w-[14px]' src={checkcircle} alt='' />,
                onChange: (e) => handleFormInputs(e, 'email'),
            },
        },
        {
            name: 'password',
            className: 'resp_login_width',
            rules: [
                {
                    required: true,
                    message: passwordValidationErrMsg,
                    validator: passwordValidator
                }
            ],
            inputProps: {
                placeholder: locals.form.form.password.placeholder,
                className: 'md:h-[50px] h-[42px] md:rounded-xl',
                prefix: <LockIcon className='text-baseGray500' style={{ fontSize: "1.4rem" }} />,
                iconRender: (visible) => (
                    visible ? (
                        <EyeOutlined onClick={handlePasswordVisibilityChange} />
                    ) : (
                        <EyeInvisibleOutlined onClick={handlePasswordVisibilityChange} />
                    )
                ),
                onChange: (e) => {
                    const value = e.target.value;
                    passwordValidator(undefined, value, (msg) => {
                        setPasswordValidationErrMsg(msg);//msg
                    });
                    handleFormInputs(e, "password");
                },
                visibilityToggle: true,
                visible: passwordVisible.toString(),
            },
        },
        {
            name: signup_cohorot_name,
            className: 'resp_login_width',
            rules: [
                {
                    required: true,
                    message: WARNING_ERROR_MESSAGE.text_input,
                    validator: !(credentialDetail?.[signup_cohorot_name]?.trim()?.length >= 1 && credentialDetail?.["username"]?.trim()?.length <= 30)
                },
            ],
            inputProps: {
                placeholder: locals.form.form[signup_cohorot_name].placeholder,
                className: 'md:h-[50px] h-[42px] md:rounded-xl',
                prefix: <GroupIcon className='text-baseGray500' />,
                onChange: (e) => handleFormInputs(e, signup_cohorot_name),
            },
            maxChar: 20
        }
    ];

    const onFinish = (values) => {
        if (credentialDetail["subscriptionId"] === null) {
            message.open({
                type: "warning",
                content: locals.form.warning_message.subscription
            })
        } else {
            const { username, password, email, subscriptionId, companyName, countryCode, phoneNumber } = credentialDetail;
            dispatch(createUser({ username, password, email, subscriptionId, companyName, countryCode, phoneNumber, [signup_cohorot_name]: credentialDetail[signup_cohorot_name], "user_type": USER_TYPE }));
            // dispatch(createUser({...values,subscriptionId}));
        }
    };

    useEffect(() => {
        if (userStatus === 'failed') {
            messageApi.open({
                type: 'error',
                content: userErr,
            });
            setTimeout(() => {
                dispatch(updateErr());
            }, 1000);
        }
        if (userStatus === 'succeeded') {
            dispatch(updateStatus())
            navigate('/product/verifyemail');
        }
    }, [userStatus, dispatch]);

    // console.log(checkAgreeTermsAndCond)
    const handleCloseEmailVerificationModal = () => {
        setIsVerifyModalOpen((prev) => !prev);
    }

    return (
        <div className='md:min-h-[100vh] min-h-[100vh] flex flex-col justify-between'>
            {contextHolder}
            <VerifyEmailModal isModalOpen={isVerifyModalOpen} handleCloseEmailVerificationModal={handleCloseEmailVerificationModal} />
            <div className='absolute hidden top-0 left-0 right-0 md:flex -z-[10000] min-h-full'>
                <div className="left_shadow bg-white w-[60%]"></div>
                <div className="left_shadow lg:bg-white w-[45%] 2xl:w-[55%]"></div>
            </div>
            {/* <div onClick={() => navigate('/')} className="header top-0 lg:pl-[64px] md:pl-[10px]">
        <img src={logo} className="w-[138px] h-[21px] md:block hidden" alt="" />
        <img src={brand} className="w-[64px] h-[64px] block md:hidden" alt="" />
      </div> */}
            <div className={cn(`flex md:flex-row-reverse flex-col-reverse justify-between min-h-[100vh] ${className}`)}>
                <div className='md:w-[50%] w-full flex flex-col justify-center md:py-[40px] py-[1rem] lg:px-[52px] px-[20px]'>
                    <img src={partnership_icon ?? logo} onClick={() => navigate('/')} width={logowidth ?? 150} className=" mb-[24px]" alt="" />
                    <Form
                        initialValues={credentialDetail}
                        form={form}
                        onFinish={onFinish}
                        autoComplete="off"
                        className="xl:w-[80%] lg:w-[90%] md:pt-[2rem] md:pb-[4rem] pb-[1rem]"
                    >
                        <p style={{ marginTop: '0px', marginBottom: '0px' }} className="md:text-[24px] text-[24px] text-[#111827] font-DMSans">{locals.form.mainTitle}</p>
                        {/* <div style={{ marginTop: '0px', marginBottom: '12px' }} className='text-[#757575] font-InterRegular text-[14px] font-medium'>
                            <p className='md:text-[14px] text-[12px] font-InterRegular tracking-wide' style={{ marginTop: '0px', marginBottom: '0px' }}>{createAccountLocals.create_account.free_trial_decsription}</p>
                        </div> */}
                        <div>
                            {formFields.map((field) => {
                                return (
                                    <Form.Item
                                        key={field?.name}
                                        name={field.name}
                                        className={field?.className}
                                        rules={field?.rules}
                                        style={{ marginTop: '15px', marginBottom: '0px' }}
                                    >
                                        {
                                            field?.name === 'password'
                                                ? (
                                                    <Input.Password
                                                        disabled={userStatus === "loading"}
                                                        {...field.inputProps}
                                                    />
                                                ) : null
                                        }
                                        {
                                            ['username', 'email', 'companyName', signup_cohorot_name].includes(field?.name)
                                                ? (
                                                    <Input
                                                        maxLength={field?.maxChar}
                                                        disabled={userStatus === "loading"}
                                                        {...field.inputProps}
                                                    />
                                                ) : null
                                        }
                                        {
                                            field?.name === 'phoneNumber'
                                                ? (
                                                    <PhoneInput
                                                        style={{
                                                            borderRadius: "0.5rem"
                                                        }}
                                                        defaultCountry={credentialDetail["countryCode"]}
                                                        className='hide_outline'
                                                        disabled={userStatus === "loading"}
                                                        {...field.inputProps}
                                                    />
                                                ) : null
                                        }
                                    </Form.Item>
                                )
                            })}
                            <div className='flex gap-x-[0.2rem] mt-[0.5rem]'>
                                <Tooltip className='passValidationTooltip cursor-pointer' title={
                                    <div>
                                        {
                                            locals.form.password_validation_tooltip.map((msg, passIndex) => <div key={passIndex}>• {msg}</div>)
                                        }
                                    </div>
                                } placement="bottomRight">
                                    <img src={infoIcon} alt="" />
                                </Tooltip>
                                <TextMedium text={"Password Policy"} fontWeight={200} />
                            </div>
                        </div>
                        {/*<Form.Item style={{ marginTop: "15px"}}>
              <Select
                value={credentialDetail["subscriptionId"]}
                placeholder={placeholder}
                onChange={(value) => {
                  dispatch(updateCreateAccCred({key:"subscriptionId",value}))
                }}
                options={subscriptionList}
              />
              </Form.Item>*/}
                        <Form.Item style={{ marginBottom: '30px', marginTop: '8px' }}>
                            <Checkbox disabled={userStatus === "loading"} checked={checkAgreeTermsAndCond} onChange={(e) => setCheckAgreeTermsAndCond(e.target.checked)} />
                            <span className='font-InterRegular text-[13px] font-medium ml-[12px] tracking-wide text-[#616161]'>{locals.form.terms_and_condition_stmt.agree_stmt} <button className='text-primary' onClick={() => navigate('/terms-and-conditions')}>{locals.form.terms_and_condition_stmt.terms_and_condition_stmt}</button> {locals.form.terms_and_condition_stmt.query_word} <button onClick={() => navigate('/privacy-policy')} className='text-primary'>{locals.form.terms_and_condition_stmt.privacy_and_poilicy_stmt}</button></span>
                        </Form.Item>
                        <Form.Item style={{ marginTop: "0px", marginBottom: '12px' }}>
                            <Button
                                aria-disabled={isLoginDisabled}
                                className={`${!isLoginDisabled && 'btn-onboarding-grad'
                                    } text-[16px] rounded-xl font-semibold flex justify-center resp_login_width w-full h-[42px] md:h-[50px]`}
                                htmlType="submit"
                                loading={userStatus === "loading"}
                                disabled={isLoginDisabled}
                                style={{
                                    margin: '0rem 0rem',
                                    backgroundColor: true ? '#f5f8ff' : '',
                                    color: isLoginDisabled ? '#9693f2' : 'white'
                                }}
                            >
                                {locals.form.sign_up_button_text}
                            </Button>
                        </Form.Item>

                        <div className="resp_login_width md:block hidden text-[14px] font-medium tracking-wide">
                            {locals.form.account_exist}
                            <Link to={'/product'} className="text-[#372ee5] pl-2 font-semibold">{locals.form.login_button_text}</Link><br />
                            {locals.form.account_not_verified}
                            <button onClick={handleCloseEmailVerificationModal} className="text-[#372ee5] pl-2 font-semibold">{locals.form.account_not_verified_btn_cta}</button>
                        </div>
                    </Form>
                    <div className='md:hidden flex justify-center'>
                        {locals.form.account_exist}
                        <Link to={'/product'} className="text-[#372ee5] pl-2 font-semibold">{locals.form.login_button_text}</Link><br />
                    </div>
                    <div className='text-[#9E9E9E] md:flex md:justify-start justify-between text-[12px] hidden'>
                        <div className='md:hidden block'>
                            <span className='md:text-[12px] text-[10px]'>{footerLocals.footer.copyright_text}</span>
                        </div>
                        <div className='flex'>
                            <button onClick={() => navigate('/refund-policy')} className='mr-[24px] md:text-[12px] text-[10px]'>{footerLocals.footer.refund_policy}</button>
                            <button onClick={() => navigate('/terms-and-conditions')} className='mr-[24px] md:text-[12px] text-[10px]'>{footerLocals.footer.terms_and_condition_text}</button>
                            <button onClick={() => navigate('/privacy-policy')} className='md:text-[12px] text-[10px]'>{footerLocals.footer.privacy_policy_text}</button>
                        </div>
                    </div>

                </div>

                {
                    isDefaultTemplate
                        ? (
                            <div className="w-[50%] lg:bg-[#FAFAFA] md:flex justify-center hidden">
                                <img src={test3} className="my-auto" alt="" />
                            </div>
                        ) : null
                }

                {
                    !currentPath.includes('appsumo') && !isDefaultTemplate &&
                    <div className='md:w-[50%] bg-[#21208F] lg:px-[52px] px-[32px] py-[40px] flex flex-col justify-center gap-y-[24px]'>
                        <ResponsiveHeadingThreeSix color={'#FFFFFF'} text={LOCALS.greet} />
                        <div className='flex flex-col lg:gap-y-[16px] gap-y-[8px]'>
                            <TextMedium color={'#FFFFFF'} text={LOCALS.description1} fontWeight={400} display='block' />
                            <TextMedium color={'#FFFFFF'} text={LOCALS.description2} fontWeight={400} display='block' />
                            <div className='bg-white p-[8px] rounded-md'>
                                <div className='md:flex'>
                                    <div className='md:w-[60%]'>
                                        <div className='bg-[#BF3863] inline pt-[2px] pb-[4px] px-[4px] rounded-md'>
                                            <TextExtraSmall text={LOCALS.pass_deal.tag} fontWeight={400} className="uppercase" color='#FFFFFF' />
                                        </div>
                                        <div className='max-w-[180px] mt-[8px]'>
                                            <TextSmall color='#BF3863' text={LOCALS.pass_deal.detail_description.name} display='block' />
                                            <TextSmall color='#BF3863' text={LOCALS.pass_deal.detail_description.value} />
                                        </div>
                                    </div>
                                    <div className='bg-[#BF3863] md:w-[40%] py-[4px] md:mt-0 mt-2 px-[8px] rounded-md border-2 border-[#f2d7e0] border-dashed flex justify-center items-center'>
                                        <TextSmall color={"#FFFFFF"} isDisplay display='flex' style={{ textAlign: "center" }} className={'uppercase'} text={LOCALS.pass_deal.offer_description} />
                                    </div>
                                </div>
                                <div className='mt-[9px]'>
                                    <TextSmall color='#BF3863' text={LOCALS.pass_deal.validity} />
                                </div>
                            </div>
                            <TextMedium color={'#FFFFFF'} text={LOCALS.description3} display='block' fontWeight={400} />
                            <TextMedium color={'#FFFFFF'} text={LOCALS.key_features.title} display='block' fontWeight={400} />
                            <div className='grid grid-cols-2 gap-[8px]'>
                                {
                                    LOCALS.key_features.features.map((feature, index) => {
                                        return (
                                            <div key={index} className='py-[8px] px-[16px] bg-[#FFFFFF] flex gap-x-[8px]'>
                                                <DoneIcon className='text-[#21208F]' style={{ fontSize: '15px' }} />
                                                <TextSmall text={feature} fontWeight={500} color='#21208F' />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <TextSmall color='#FFFFFF' text={LOCALS.info} className={'italic'} fontWeight={500} />
                        <div className="md:block hidden">
                            <TextSmall text={footerLocals.footer.copyright_text} fontWeight={400} color='#9E9E9E' />
                        </div>
                    </div>
                }
            </div>
            {/* <div className="sticky top-[100%]">
        <div className='flex justify-between items-center text-[#9E9E9E] text-[13px] md:pb-[32px] lg:px-[64px] px-[8px]'>
          <div className='font-InterRegular tracking-wide hidden md:block'>{footerLocals.footer.copyright_text}</div>
          <div className="resp_login_width flex md:hidden justify-center text-[14px] font-medium tracking-wide">
            {createAccountLocals.create_account.account_exist}
            <Link to={'/product'} className="text-[#372ee5] pl-2 font-semibold">{createAccountLocals.create_account.login_button_text}</Link>
          </div>
          <div className='respFooter'>
            <button className='mr-[24px]'><a href={TNC}>{footerLocals.footer.terms_and_condition_text}</a></button>
            <button><a href={PP}>{footerLocals.footer.privacy_policy_text}</a></button>
          </div>
        </div>
      </div> */}
        </div>
    );
};

export default SignUpTemplate;
