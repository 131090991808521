import React from 'react'
import closeactive from "../assets/closeactive.svg"

const ClientTag = ({ data, handleOnClick, isDisabled=false, selectedTag }) => {
    return (
        <div className='flex flex-wrap gap-[8px] bg-white py-[29px] px-[24px] rounded-md'>
            {
                data.map((d, index) => {
                    const isTagSelected = selectedTag?.includes(d);
                    return (
                        <button key={index} disabled={isDisabled} onClick={() => handleOnClick(d)} className={`border ${isTagSelected ? 'text-primary border-primary' : ''} border-[#616161] px-[16px] py-[5px] bg-white rounded-full flex items-center`}>
                            {d}
                            {isTagSelected ? <img className='ml-[8px]' src={closeactive}/> : null}
                        </button>
                    )
                })
            }
        </div>
    )
}

export default ClientTag