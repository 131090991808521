import locals from "../../../localization/localization_en_stepper.json"

const module_title = locals.stepper.module_title;

export const stepperConfig = [
    {
        title: module_title[1],
        key: '1',
        locked: false,
        cardBgColor: "#E5F4FF",
        cardBorder:"#BCDFF6",
        children: [
            {
                title: module_title["1.1"],
                key: '1.1',
                locked: false,
                children: [
                    { title: module_title["1.11"], key: '1.11', locked: false, cardBgColor: "#2A60C6", cardBorder: "#6D7FFF", btnTextColor: "#2A60C6", buttonUrl: "/product/home/problemsolutionmatrix", },
                    { title: module_title["1.12"], key: '1.12', locked: false, cardBgColor: "#BA0296", cardBorder: "", btnTextColor: "#BA0296", buttonUrl: "/product/home/businessideationbingo", },
                ],
            },
            {
                title: module_title["1.2"],
                key: '1.2',
                locked: false,
                children: [
                    { title: module_title["1.21"], key: '1.21', locked: false, cardBgColor: "#9300B8", cardBorder: "#F0B4FF", btnTextColor: "#9300B8",buttonUrl: "/product/home/targetmarketanalysis", },
                    { title: module_title["1.22"], key: '1.22', locked: false, cardBgColor: "#A4C816", cardBorder: "#F4FFC9", btnTextColor: "#A4C816", buttonUrl: "/product/home/business-canvas/build-market-analysis/competitor-analysis", },
                    { title: module_title["1.23"], key: '1.23', locked: false, cardBgColor: "#6C51E5", cardBorder: "#CABEFF", btnTextColor: "#6C51E5", buttonUrl: "/product/home/buildmarketanalysis/idealcustomerprofile", },
                ],
            },
            {
                title: module_title["1.3"][1],
                key: '1.3',
                locked: false,
                children: [
                    { title: module_title["1.31"], key: '1.31', locked: false, cardBgColor: "#FA0", cardBorder: "#FFE5B2", btnTextColor: "#FA0", buttonUrl: "/product/home/businesscanvas/buildbusinesscanvas"},
                    { title: module_title["1.32"], key: '1.32', locked: false, cardBgColor: "#FA0", cardBorder: "#FFE5B2", btnTextColor: "#FA0", buttonUrl: "/product/home/businesscanvas/leanstartupcanvas"},
                ]
            },
            { 
                title: module_title["1.4"],
                key: '1.4',
                locked: false,
                cardBgColor: "#34946D",
                cardBorder: "#DAFFF0",
                btnTextColor: "#34946D",
                children: [
                    { title: module_title["1.41"], key: '1.41', locked: false, cardBgColor: "#FA0", cardBorder: "#FFE5B2", btnTextColor: "#FA0", buttonUrl: "/product/home/go-to-market-strategy/gtm-plan", },
                    { title: module_title["1.42"], key: '1.42', locked: false, cardBgColor: "#FA0", cardBorder: "#FFE5B2", btnTextColor: "#FA0", buttonUrl: "/product/home/go-to-market-strategy/marketing-funnel", }
                ]
            },
            { 
                title: module_title["1.5"],
                key: '1.5',
                locked: false,
                cardBgColor: "#34946D",
                cardBorder: "#DAFFF0",
                btnTextColor: "#34946D",
                children: [
                    { title: module_title["1.51"], key: '1.5', locked: false, cardBgColor: "#FA0", cardBorder: "#FFE5B2", btnTextColor: "#FA0", buttonText: "View", buttonUrl: "/product/home/business-planning/my-dashboard", },
                ]
            },
        ],
    },
    {
        title: module_title[2],
        key: '2',
        locked: true,
        children: [],
        sibling: [
            { title: module_title["2.1"], key: '2.1', locked: false, cardBgColor: "#9300B8", cardBorder: "#F0B4FF", btnTextColor: "#9300B8", buttonText: "Start Creating", buttonUrl: "/product/home/business-modal", },
            { title: module_title["2.2"], key: '2.2', locked: false, cardBgColor: "#A4C816", cardBorder: "#F4FFC9", btnTextColor: "#A4C816", buttonText: "View Reports", buttonUrl: "/product/home/business-modal/my-report", },
            { title: module_title["2.3"], key: '2.3', locked: false, cardBgColor: "#6C51E5", cardBorder: "#CABEFF", btnTextColor: "#6C51E5", buttonText: "View", buttonUrl: "/product/home/business-modal/my-dashboard", },
        ],
        cardBgColor: "#D8F2E7", cardBorder: "#81E6BD"
    },
    {
        title: module_title[3],
        key: '3',
        locked: true,
        children: [],
        cardBgColor: "#8E43CE", cardBorder: "#DAFFF0"
    },
    {
        title: module_title[4],
        key: '4',
        locked: true,
        children: [],
        cardBgColor: "#FC0", cardBorder: "#DAFFF0"
    },
];

export const stepperToolTip = locals.stepper.tooltip;