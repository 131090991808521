import React, { useEffect, useRef } from 'react'
import { createRoot } from 'react-dom/client';
import { Menu } from "antd";
import LockSVG from './LockSVG';
import menuLocals from "../../localization/localization_en_menu.json";
import useLockedFeature from '../../hooks/useLockedFeature';

const OldMenu = ({ linkInfo, keys, onOpenChange, collapsedMenuItems, items, onClick, activeItem }) => {
  const firstElement = useRef(null);
  const { LOCKED_MODULES } = useLockedFeature();
  useEffect(() => {
    const elements = document.querySelectorAll('.ant-menu-submenu-arrow');
    const element = document.querySelectorAll('.ant-menu-item')
    for(let i=0 ;i<element.length; i++){
      const targetElement = element[i];
      if(!LOCKED_MODULES?.includes(targetElement?.childNodes[1]?.innerHTML)) continue;
      const lockDivElement = document.createElement('div');
      lockDivElement.style.width = '20px';
      lockDivElement.style.height = '20px';
      const root = createRoot(lockDivElement);
      root.render(<LockSVG color={"white"} />);
      if(targetElement.childNodes.length<3){
        targetElement.appendChild(lockDivElement);
      }
    }
    for(let i=0 ;i<elements.length; i++){
      const targetElement = elements[i];
      const TARGET_ELEMENT_NAME = targetElement?.parentNode?.querySelector('span')?.innerText
      if(Boolean(LOCKED_MODULES?.includes(TARGET_ELEMENT_NAME))===false){
        const children = targetElement?.parentNode?.children;
        for(let i=0;i<children?.length;i++){
          if(children[i]?.classList?.contains('custom_lock_svg')){
            targetElement.parentNode.removeChild(children[i]);
            i--;
          }
          if(children[i].tagName.toLowerCase() === "i"){
            children[i].style.visibility = "visible";
          }
        }
        continue;
      }
      const lockDivElement = document.createElement('div');
      lockDivElement.style.width = '20px';
      lockDivElement.style.height = '20px';
      lockDivElement.style.position = 'absolute';
      lockDivElement.style.top = '50%';
      lockDivElement.style.insetInlineEnd = '16px';
      lockDivElement.style.color = 'currentcolor';
      lockDivElement.style.transform = 'translateY(-50%)';
      lockDivElement.style.transition = 'transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s';
      lockDivElement.classList.add('custom_lock_svg')
      const root = createRoot(lockDivElement);
      root.render(<LockSVG color={"white"} />);
      targetElement.parentNode.insertBefore(lockDivElement, targetElement);
      // targetElement.remove();
      targetElement.parentNode.lastElementChild.style.visibility = 'hidden'
    }
    if(elements.length>0){
      firstElement.current = elements[0];
      firstElement.current.style.display='none';
    }
  },[LOCKED_MODULES.length])
  return (
    <React.Fragment>
      {/* {!linkInfo.collapsed && <div className='hideFirstDownArrow absolute top-[12rem] right-[1rem] bg-[#21208F] z-10 w-5 h-5'></div>} */}
      <Menu
        selectedKeys={linkInfo.collapsed ? '0' : activeItem}
        defaultOpenKeys={!linkInfo.collapsed && ['0']}
        openKeys={!linkInfo.collapsed && keys.dupOpenKeys}
        mode="inline"
        theme="dark"
        inlineIndent={12}
        style={{
          width: linkInfo.collapsed ? 62 : 276,
          fontSize:"15px"
        }}
        triggerSubMenuAction="click"
        inlineCollapsed={linkInfo.collapsed}
        onOpenChange={onOpenChange}
        items={linkInfo.collapsed ? collapsedMenuItems : items}
        onClick={onClick}
      />
    </React.Fragment>
  )
}

export default OldMenu
