import React, { useState, useEffect, useRef } from 'react'
import { colorPalette } from './data';
import { useDispatch, useSelector } from "react-redux";
import { fetchBuildBusinessCanvasDataApi, resetbibErr } from './buildbusinesscanvasSlice'
import StickyNoteCompo from './StickyNoteCompo';
import Download from "./asset/Download.svg"
import { Button } from "antd";
import DescribeFeature from '../reuseablecomponent/DescribeFeature';
import { updateSlideNumber } from './buildbusinesscanvasSlice';
import { useNavigate } from "react-router-dom"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from "moment";
import MessageNotification from '../reuseablecomponent/MessageNotification';
import { fileNameSuffix } from '../../utils/fileNameSuffix';
import { postUserActivityDataApi } from '../UserActivity/userActivitySlice';
import bbc_locals from "../../localization/localization_en_build_business_Canvas.json";
import notification_locas from "../../localization/localization.en_notification_message.json"
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import WarningFreeTrialAccessResource from '../reuseablecomponent/PopUpVarients/WarningFreeTrialAccessResource';
import BlurContainer from '../../DesignSystem/Container/BlurContainer';
import WarningLimitedResourceOopsPopup from '../reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup';
import { paywall_locals } from '../../localization';
import useApp from '../../hooks/useApp';

const PAYWALL_LOCALS = paywall_locals.business_model_canvas;

const locals = bbc_locals.build_business_canvas;

const StickyNotes = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const permission = useSelector((state) => state.user.permission);
    const pdfRef = useRef();
    const [data, setData] = useState([]);
    const Metrics = useSelector((state) => state.buildbusinesscanvas.Metrics);
    const status = useSelector((state) => state.buildbusinesscanvas.status);
    const error = useSelector((state) => state.buildbusinesscanvas.error);
    const [showEditIcon, setShowEditIcon] = useState(true);
    const { upgradeRenewPaywallPopup, isFreeSubscription } = useApp();
    useEffect(() => {
        dispatch(fetchBuildBusinessCanvasDataApi());
    }, [])
    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(resetbibErr());
        }, 3000)
        return () => clearTimeout(timer);
    }, [error])
    useEffect(() => {
        const keys = Object.keys(Metrics); // Get all the keys of the object
        const slicedObjects = [];

        for (let i = 0; i < keys.length; i += 3) {
            const sliceKeys = keys.slice(i, i + 3);
            const slicedObject = {};

            for (const key of sliceKeys) {
                slicedObject[key] = Metrics[key];
            }

            slicedObjects.push(slicedObject);
        }
        setData(slicedObjects);
    }, [Metrics])

    if (status == "failed") {
        return <p>{error}</p>
    }

    const downloadPDF = () => {
        setShowEditIcon((prev) => !prev);
        setTimeout(() => {
            const input = pdfRef.current;
            html2canvas(input).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                const imgWidth = canvas.width;
                const imgHeight = canvas.height;
                const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
                const imgX = (pdfWidth - imgWidth * ratio) / 2;
                const imgY = 0;
                pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
                const currentDate = moment();
                const fileName = fileNameSuffix();
                pdf.save(`BuildBusinessCanvas_${fileName}.pdf`);
                setShowEditIcon((prev) => !prev);
            });
        }, 1000);
        dispatch(postUserActivityDataApi({ ...notification_locas.notification_message.build_business_canvas.download }))
    };
    return (
        <div className='pt-[6.5rem] w-[95%] mx-auto'>
            <DescribeFeature text={locals.module_title} description={locals.module_description} />
            <div className="w-full flex justify-end mb-[1rem]">
                <WarningLimitedResourceOopsPopup
                    module_data={{ "module": "bbc_lsc", "data": data }}
                    description1={PAYWALL_LOCALS[upgradeRenewPaywallPopup].download.description1}
                    description2={PAYWALL_LOCALS[upgradeRenewPaywallPopup].download.description2}
                >
                    {({ handleTogglePopup, isFreeSubscription, accessDownload }) => (
                        <button disabled={!permission.download} className='cursor-pointer' style={{ margin: '0' }} onClick={() => (isFreeSubscription || accessDownload) ? handleTogglePopup() : downloadPDF()}><DownloadOutlinedIcon fontSize='medium' /></button>
                    )}
                </WarningLimitedResourceOopsPopup>
            </div>
            <BlurContainer
                isShowBlurEffect={isFreeSubscription}
            >
                <section ref={pdfRef}>
                    {
                        data?.map((item, i) => {
                            const subItem = Object.entries(item);
                            return <StickyNoteCompo showEditIcon={showEditIcon} SlideNumber={i} bgColor={colorPalette[i]} data={subItem} />
                        })
                    }
                </section>
            </BlurContainer>
            <div className="w-full flex justify-between my-[48px]">
                <Button onClick={() => {
                    dispatch(updateSlideNumber({ type: "independent", SlideNumber: 2 }));
                    navigate('/product/home/businesscanvas/buildbusinesscanvas')
                }} className="border-primary text-primary px-[32px] py-[12px] text-[16px] font-InterRegular font-medium tracking-wide h-auto rounded-[8px]">{locals.button_controls[1]}</Button>
                <Button onClick={() => {
                    navigate('/product/home');
                }} className=" bg-primary text-[#EBF1FF] px-[32px] py-[12px] text-[16px] font-InterRegular font-medium tracking-wide h-auto rounded-[8px]">{locals.button_controls[3]}</Button>
            </div>
        </div>
    )
}

export default StickyNotes