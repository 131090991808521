export const validator = ({ validator, value }) => {

    try {
        switch (validator) {
            case 'email': {
                return (
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) || false
                )
            }
            case 'non_null_string': {
                return !!value && value?.length>0;
            }
            case 'number': {
                return typeof value === 'number'
            }
            case 'non_null': {
                return !!value
            }
            default: {
                return false;
            }
        }
    } catch (error) {
        return false;
    }

}