import React from 'react'
import TextInput from './InputVarients/TextInput'
import PrimaryButton from '../Buttons/PrimaryButton'

const AddListOfItem = ({ value, handleBlurEvent, handleInputEventChange, handleAddItem, name, placeholder, isDisabled, isRequired, isReadonly }) => {
    return (
        <React.Fragment>
            <div className='flex gap-x-5'>
                <TextInput isDisabled={isDisabled} isReadyOnly={isReadonly} isRequired={isRequired} size='large' borderColor={"#757575"} value={value} onBlurEvent={handleBlurEvent} onChangeEvent={handleInputEventChange} name={name} placeholder={placeholder} />
                <PrimaryButton isDisabled={isReadonly || isDisabled} text={"Add"} onClick={handleAddItem} />
            </div>
        </React.Fragment>
    )
}

export default AddListOfItem