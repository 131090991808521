import React from 'react'
import { Input } from "antd";
const { TextArea } = Input;

const AutoSizeTextArea = ({ placeHolder, value, name, maxLength, isRequired, isDisabled, isReadyOnly, onChangeEvent, onBlurEvent,minRows, maxRows, borderColor, isPaddingNone=false, styles }) => {
    return (
        <TextArea
            autoSize={!maxRows ? {minRows} : {minRows, maxRows}}
            style={{ borderRadius: '6px', border: `1px solid ${borderColor ? borderColor : '#E0E0E0'}`, padding: isPaddingNone && 0, ...styles  }}
            name={name}
            value={value}
            placeholder={placeHolder}
            required={isRequired}
            disabled={isDisabled}
            readOnly={isReadyOnly}
            onChange={onChangeEvent}
            onBlur={onBlurEvent}
            maxLength={maxLength}
        />
    )
}

export default AutoSizeTextArea